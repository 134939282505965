import { useCallback, useEffect, useRef, useState } from 'react'
import { icoPost, useIcoPut } from '../_utils/fetchUtils'
import { useAppSelector } from '../_utils/reactReduxHooks'
import { selectIssueOrgID, selectSendNotificationsSite } from './_selectors'
import { Spacer } from '../Shared/Spacer'
import { CircularProgress } from '@material-ui/core'
import { cx } from '../_utils/objectUtils'
import { useIsMounted } from '../_utils/hooks'
import Rollbar from 'rollbar'
import { useParams } from 'react-router'
interface Props {
  IssueID: number
  id: string
}

export const IssueSendNotifications = ({ IssueID, id }: Props) => {
  const [sendNotifyLoading, setSendNotifyLoading] = useState(false)
  const [sendNotifyGUID, setSendNotifyGUID] = useState('')
  const isMounted = useIsMounted()
  const { domainId, domainItem } = useParams<{
    domainId?: string | undefined
    domainItem?: string | undefined
  }>()

  let rollbar = useRef<Rollbar | undefined>(undefined)
  const OrgID = useAppSelector((state) => selectIssueOrgID(state, IssueID))
  const sendNotificationsSite = useAppSelector(selectSendNotificationsSite)
  const handleOpenSendNotify = useCallback(() => {
    icoPost(`/api/SendNotifications/IssuesLogin`, {
      OrgID: OrgID,
      IssueID: IssueID,
      StakeholderID: domainItem,
      Page: domainId,
    })
      .then((response) => response.json())
      .then((guid) => {
        if (isMounted()) {
          if (!guid) {
            rollbar.current?.error('SendNotifications requires a guid to open')
          }
          setSendNotifyLoading(true)
          setSendNotifyGUID(guid)
          rollbar.current?.info('Opening Send Notifications')
        }
      })
      .catch((ex) => {
        if (isMounted()) {
          rollbar.current?.error(ex)
          console.log(ex)
        }
      })
  }, [isMounted, OrgID, IssueID, domainId, domainItem])

  useEffect(() => {
    handleOpenSendNotify()
  }, [handleOpenSendNotify])

  return sendNotifyGUID && sendNotifyGUID.length > 0 ? (
    <>
      <div id={id} className={cx('h-100', 'w-100')}>
        <iframe
          title="Send Notifications"
          name="sendNotifications-frame"
          id="notificationIframe"
          style={{ border: 'none', width: '100%', height: '100%' }}
          src={`${sendNotificationsSite}/Home/Login?guid=${sendNotifyGUID}`}
          onLoad={() => setSendNotifyLoading(false)}
        />
        <Spacer spaceParam={'large'} />
      </div>
      {sendNotifyLoading && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '200px',
          }}
        >
          <CircularProgress />
        </div>
      )}
    </>
  ) : (
    <></>
  )
}

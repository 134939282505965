import React, { useState, useEffect, useRef } from 'react'
import { StretchLayout, StretchFooter } from '../Layouts/StretchLayout'
import { cx } from '../_utils/objectUtils'
import { ContentViewer } from '../Shared/ContentViewer'
import { StakeholderDetail } from '../Stakeholder/StakeholderDetail'
import { Button } from '../Shared/Buttons'
import { StakeholderList } from '../Stakeholder/StakeholderList'
import { LoadingBackDrop } from '../Shared/LoadingBackdrop'
import { selectIssueAllStakeholderIds, selectPbAppSite } from './_selectors'
import { ResponsiveDialog } from '../Shared/ResponsiveDialog'
import { SlideLeft } from '../Shared/Transition'
import { DialogContent, Toolbar } from '@material-ui/core'
import { HeaderNavigation } from '../Shared/HeaderNavigation'
import Rollbar from 'rollbar'
import { Context, getRollbarFromContext } from '@rollbar/react'
import { useAppSelector, useAppDispatch } from '../_utils/reactReduxHooks'
import { addNotification } from '../_actions'
import { icoPost } from '../_utils/fetchUtils'
import { selectCurrentStakeholderID } from '../Stakeholder/_selectors'
import {
  isCreatingStakeholder,
  isUpdatingStakeholder,
} from '../Shared/_requestStatusSelectors'
import { useIsMounted } from '../_utils/hooks'
import { StakeholderActions } from '../Stakeholder/_actions'
import { StakeholderCreateAndEditDialog } from '../Stakeholder/StakeholderCreateAndEditDialog'
import { selectStakeholderById } from '../_rootConfigs/rootSelectors'
import { Spacer } from '../Shared/Spacer'
import { useHistory } from 'react-router'

const AdvisoryContentstyle = {
  padding: 0,
  overflow: 'hidden',
}

interface Props {
  id: string
  IssueID: number
  stakeholderIds: number[]
  disabled: boolean
  mobile: boolean
  workspaceID: number
}

export const IssueStakeholderViewer = ({
  id,
  IssueID,
  stakeholderIds,
  disabled,
  mobile = false,
  workspaceID,
}: Props) => {
  let rollbar = useRef<Rollbar | undefined>(undefined)

  const [advisoryGUID, setAdvisoryGUID] = useState('')
  const [isStakeholderCreateOpen, setIsStakeholderCreateOpen] = useState(false)

  const [stakeholderToEdit, setStakeholderToEdit] = useState(undefined)
  const stakeholderIDs = useAppSelector((state) =>
    selectIssueAllStakeholderIds(state, IssueID)
  )
  const dispatch = useAppDispatch()

  const history = useHistory()

  const currentStakeholderID = useAppSelector(selectCurrentStakeholderID)
  const currentStakeholderDetails = useAppSelector((state) =>
    selectStakeholderById(state, currentStakeholderID)
  )

  const pbAppSite = useAppSelector(selectPbAppSite)
  const isCreatingStakeholderStatus = useAppSelector(isCreatingStakeholder)
  const isUpdatingStakeholderStatus = useAppSelector(isUpdatingStakeholder)
  const isSavingStakeholder =
    isCreatingStakeholderStatus || isUpdatingStakeholderStatus
  const isMounted = useIsMounted()

  useEffect(() => {
    rollbar.current = getRollbarFromContext(Context)
    rollbar.current?.info('IssueStakeholderViewer mounted')
  }, [])

  const createStakeholderCancel = () => {
    setIsStakeholderCreateOpen(false)
    setStakeholderToEdit(undefined)
  }
  useEffect(() => {
    return () => {
      dispatch(StakeholderActions.setCurrentStakeholderID(null))
    }
  }, [dispatch])

  const handleOpenAdvisory = () => {
    icoPost(`/api/Stakeholders/${currentStakeholderID}/AdvisoryLogin`, {})
      .then((response) => response.json())
      .then((guid) => {
        if (isMounted()) {
          if (!guid) {
            rollbar.current?.error('Advisory requires a guid to open')
          }
          setAdvisoryGUID(guid)
          rollbar.current?.info('Opening Advisory')
        }
      })
      .catch((ex) => {
        if (isMounted()) {
          rollbar.current?.error(ex)
          dispatch(
            addNotification({
              message: 'Cannot open advisory. Try to close and reopen app.',
              type: 'error',
            })
          )
        }
      })
  }

  return (
    <div id={id} className={'h-100'}>
      <LoadingBackDrop
        text="Saving Stakeholder..."
        open={isSavingStakeholder} //will have to check if any action causes this backdrop
        className="position-absolute h-100 w-100"
      />
      {!currentStakeholderID ? (
        <StretchLayout className={cx('overflow-visible')}>
          <StakeholderList
            stakeholderIds={stakeholderIds}
            mobile={mobile}
            workspaceID={workspaceID}
            IssueID={IssueID}
          />

          <StretchFooter
            className="p-3"
            style={{ display: 'flex', justifyContent: 'flex-start' }}
          >
            <Button
              onClick={() => {
                setIsStakeholderCreateOpen(true)
              }}
              color="primary"
              disabled={disabled}
            >
              Add
            </Button>
            <Spacer spaceParam={'large'} />
            {stakeholderIDs && stakeholderIDs.length > 0 && (
              <Button
                onClick={() => {
                  history.push('./sendNotifications/notificationResults')
                }}
                color="standard"
              >
                Notification Results
              </Button>
            )}
          </StretchFooter>
        </StretchLayout>
      ) : (
        <ContentViewer
          headerTitle={currentStakeholderDetails?.Audience}
          open={!!currentStakeholderID}
          onClose={() => {
            dispatch(StakeholderActions.setCurrentStakeholderID(null))
          }}
          isDialog={mobile}
          canGoBack={true}
        >
          <StakeholderDetail
            mobile={mobile}
            IssueID={IssueID}
            handleOpenAdvisory={handleOpenAdvisory}
            workspaceID={workspaceID}
          />
        </ContentViewer>
      )}

      {isStakeholderCreateOpen && (
        <StakeholderCreateAndEditDialog
          isOpen={isStakeholderCreateOpen}
          IssueID={IssueID}
          onCancel={createStakeholderCancel}
          StakeholderID={stakeholderToEdit}
          workspaceID={workspaceID}
        />
      )}

      <ResponsiveDialog
        open={advisoryGUID.length > 0 ? true : false}
        onClose={() => setAdvisoryGUID('')}
        fullScreen={true}
        TransitionComponent={SlideLeft}
      >
        <Toolbar>
          <HeaderNavigation
            title="Playbook Advisory"
            canGoBack={false}
            onNavClick={() => setAdvisoryGUID('')}
          />
        </Toolbar>
        <DialogContent style={AdvisoryContentstyle}>
          {advisoryGUID.length > 0 && (
            <iframe
              title="Advisory"
              className="h-100 w-100"
              width={1000}
              height={500}
              name="icoIssue-advisory-frame"
              src={`${pbAppSite}/Home/LoginGUID?AdvisoryGUID=${advisoryGUID}`}
            />
          )}
        </DialogContent>
      </ResponsiveDialog>
    </div>
  )
}

import { useCallback, useEffect, useRef, useState } from 'react'
import { icoPost } from '../_utils/fetchUtils'
import { useAppSelector } from '../_utils/reactReduxHooks'
import { Spacer } from '../Shared/Spacer'
import { CircularProgress } from '@material-ui/core'
import { cx } from '../_utils/objectUtils'
import { useIsMounted } from '../_utils/hooks'
import Rollbar from 'rollbar'
import { selectSendNotificationsSite } from '../Issue/_selectors'
interface Props {
  OrgID: string
}

export const AdminSendNotifications = ({ OrgID }: Props) => {
  const [sendNotifyLoading, setSendNotifyLoading] = useState(false)
  const [sendNotifyGUID, setSendNotifyGUID] = useState('')
  const isMounted = useIsMounted()
  let rollbar = useRef<Rollbar | undefined>(undefined)
  const sendNotificationsSite = useAppSelector(selectSendNotificationsSite)
  const handleOpenSendNotify = useCallback(() => {
    icoPost(`/api/SendNotifications/AdminLogin`, {
      OrgID: OrgID,
    })
      .then((response) => response.json())
      .then((guid) => {
        if (isMounted()) {
          if (!guid) {
            rollbar.current?.error('SendNotifications requires a guid to open')
          }
          setSendNotifyLoading(true)
          setSendNotifyGUID(guid)
          rollbar.current?.info('Opening Send Notifications')
        }
      })
      .catch((ex) => {
        if (isMounted()) {
          rollbar.current?.error(ex)
          console.log(ex)
        }
      })
  }, [isMounted, OrgID])

  useEffect(() => {
    handleOpenSendNotify()
  }, [handleOpenSendNotify])

  return sendNotifyGUID && sendNotifyGUID.length > 0 ? (
    <div className={cx('h-100', 'w-100')}>
      <iframe
        title="Send Notifications"
        name="sendNotifications-frame"
        id="notificationIframe"
        style={{ border: 'none', width: '100%', height: '100%' }}
        src={`${sendNotificationsSite}/Home/Login?guid=${sendNotifyGUID}`}
        onLoad={() => setSendNotifyLoading(false)}
      />
      <Spacer spaceParam={'large'} />
    </div>
  ) : sendNotifyGUID && sendNotifyGUID.length > 0 && sendNotifyLoading ? (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '200px',
      }}
    >
      <CircularProgress />
    </div>
  ) : (
    <></>
  )
}

import * as React from 'react'
import { useHistory } from 'react-router'
import { Layout, Layouts, Responsive, WidthProvider } from 'react-grid-layout'
import { DashboardSection } from '../Shared/DashboardSection'

import { deepEqual } from 'fast-equals'

import { http } from '../../_utils/http'
import { DashboardIssuesSection } from '../Shared/DashboardIssuesSection'
import {
  DashboardUserInfoModel,
  DashboardView,
  SelectionOption,
} from '../Shared/DashboardModels'
import { CircularProgress } from '../../Shared/LoadingBackdrop'
import { DashboardResolvedIssuesSection } from '../Shared/DashboardResolvedIssuesSection'
import { DashboardIssueModes } from '../Shared/DashboardIssueModes'
import '../Shared/DashboardPage.css'
import { DashboardPlaybooksSection } from '../Shared/DashboardPlaybooksSection'
import {
  checkUserFooter,
  convertToSmallLayout,
  generateStateLayout,
  IssueTaskList,
  navigateToSection,
} from '../Shared/dashboardUtils'
import { DashboardPlaybookCollaborationSection } from '../Shared/DashboardPlaybookCollaborationSection'
import { DashboardAlertsSection } from '../Shared/DashboardAlertsSection'
import { DashboardOrganizationSection } from '../Shared/DashboardOrganizationSection'
import { DashboardReportsAndActivity } from '../Shared/DashboardReportsAndActivity'
import { UserDashboardIssueTasks } from './UserDashboardIssueTasks'
import {
  saveUserDashboardLayout,
  userDashboardLayout,
} from '../../_utils/apiUtils'
import { useIsMounted } from '../../_utils/hooks'
import { UserDashboardMeetings } from './UserDashboardMeetings'
import { DashboardIssueLocationSection } from '../Shared/DashboardIssueLocationSection'

export const UserDashboard = ({
  sectionList,
  reorderMode,
  headerAction,
  setCurrentView,
}: // dashboardNavigation,
{
  sectionList: SelectionOption[]
  reorderMode: boolean
  headerAction: string
  setCurrentView: (view: DashboardView) => void
  // dashboardNavigation: (title: string, history: any) => void
}) => {
  const ReactGridLayout = WidthProvider(Responsive)
  const isInitialSmall = window.innerWidth <= 996

  const [largeLayout, setLargeLayout] = React.useState<Layout[]>(
    isInitialSmall ? [] : generateStateLayout('lg', sectionList, 'User')
  )

  const [smallLayout, setSmallLayout] = React.useState<Layout[]>(
    isInitialSmall ? generateStateLayout('sm', sectionList, 'User') : []
  )

  const [width, setWidth] = React.useState<number>(window.innerWidth)

  function handleWindowSizeChange() {
    setWidth(window.innerWidth)
  }

  const [snapshotLargeLayout, setSnapshotLargeLayout] = React.useState<
    Layout[]
  >(generateStateLayout('lg', sectionList, 'User'))

  const [snapshotSmallLayout, setSnapshotSmallLayout] = React.useState<
    Layout[]
  >(generateStateLayout('sm', sectionList, 'User'))

  const [isFetchingUserData, setIsFetchingUserData] = React.useState(false)

  const [userData, setUserData] = React.useState<
    DashboardUserInfoModel | undefined
  >()

  const isMounted = useIsMounted()

  const taskList = React.useMemo(() => {
    const issueTaskList: IssueTaskList = []
    userData?.Issues?.forEach((issue) => {
      const taskList: any = []
      if (issue.Tasks) {
        issue.Tasks.forEach((task) => {
          task?.TaskItems?.forEach((taskItem) => {
            taskList.push(taskItem)
          })
        })
      }
      if (taskList.length > 1) {
        issueTaskList.push({
          issueName: issue.IssueName!,
          taskItems: taskList,
          taskOrgName: issue.OrgName,
        })
      }
    })
    return issueTaskList
  }, [userData])

  const history = useHistory()

  React.useEffect(() => {
    if (reorderMode === true) {
      setSnapshotLargeLayout(largeLayout)
      setSnapshotSmallLayout(smallLayout)
    }
  }, [reorderMode])

  React.useEffect(() => {
    if (headerAction === 'Cancel') {
      setLargeLayout(snapshotLargeLayout)
      setSmallLayout(snapshotSmallLayout)
    }
  }, [headerAction])

  React.useEffect(() => {
    const getPickListValues = async (): Promise<any> => {
      setIsFetchingUserData(true)

      const picklistResult = await http<any>({
        path: '/Issue/IssueQueryPanelPicklist',
      })

      if (!isMounted() || !picklistResult.ok) return

      if (picklistResult.body) {
        setUserData(picklistResult.body)
      } else {
        setUserData(undefined)
      }
      setIsFetchingUserData(false)
    }
    getPickListValues()
  }, [isMounted])

  React.useEffect(() => {
    if (headerAction !== 'Save') return
    saveUserDashboardLayout(
      JSON.stringify({
        largeLayout: largeLayout,
        smallLayout: smallLayout,
      })
    )
  }, [headerAction])

  React.useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange)
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange)
    }
  }, [])

  const isSmall = width <= 996

  React.useEffect(() => {
    const getDashboardLayouts = () => {
      userDashboardLayout()
        .then((data) => {
          if (!isMounted()) return
          if (data?.largeLayout && !isSmall) setLargeLayout(data.largeLayout)
          if (data?.smallLayout && isSmall) setSmallLayout(data.smallLayout)
        })
        .catch((e) => e)
    }

    getDashboardLayouts()
  }, [isMounted, isSmall])

  const resolvedIssues = userData?.Issues?.filter((issue: any) => {
    return issue?.IssueResolved
  })

  React.useEffect(() => {
    const getDashboardLayouts = () => {
      userDashboardLayout()
        .then((data) => {
          if (!isMounted()) return
          if (data?.largeLayout && !isSmall) setLargeLayout(data.largeLayout)
          if (data?.smallLayout && isSmall) setSmallLayout(data.smallLayout)
        })
        .catch((e) => e)
    }

    getDashboardLayouts()
  }, [isMounted, isSmall])

  const handleLayoutChange = (currentLayout: Layout[], layouts: Layouts) => {
    if (isSmall) {
      if (!deepEqual(currentLayout, smallLayout)) {
        setSmallLayout(currentLayout)
      }
    } else {
      if (!deepEqual(currentLayout, largeLayout)) {
        setSmallLayout(convertToSmallLayout(currentLayout))
        setLargeLayout(currentLayout)
      }
    }
  }

  if (!userData || isFetchingUserData) return <CircularProgress zIndex={0} />

  const getLayout = (title: string) => {
    if (title === 'Marketing' || title === 'Tools and Resources') {
      return (
        <div
          style={{
            display: 'flex',
            border: '1px solid black',
            justifyContent: 'center',
            alignItems: 'center',
            height: '50%',
          }}
        >
          {title}
        </div>
      )
    } else {
      return (
        <DashboardSection
          title={title}
          reorderMode={reorderMode}
          dashboardType={'User'}
          hasFooter={checkUserFooter(title)}
          setCurrentView={() => {
            if (title === 'My Issue Tasks') {
              setCurrentView('UserTaskPanel')
            } else {
              navigateToSection(title, history)
            }
          }}
        >
          {title === 'All Active Issues' && (
            <DashboardIssuesSection
              issues={userData.Issues}
              severityPicklist={userData.pickSeverity}
            />
          )}
          {title === 'My Resolved Issues' && (
            <DashboardResolvedIssuesSection
              resolvedIssues={resolvedIssues}
              severityPicklist={userData.pickSeverity}
            />
          )}
          {title === 'Issues by Mode' && (
            <DashboardIssueModes
              issues={userData.Issues}
              modesPicklist={userData.pickMode}
            />
          )}
          {title === 'My Playbooks' && (
            <DashboardPlaybooksSection
              playbooks={userData.Playbooks}
              isOrgView={false}
            />
          )}
          {title === 'Playbook Collaboration' && (
            <DashboardPlaybookCollaborationSection
              playbooks={userData.Playbooks}
            />
          )}
          {title === 'Team Inboxes' && (
            <DashboardAlertsSection alertInboxes={userData.Inboxes} />
          )}
          {title === 'My Teams' && (
            <DashboardOrganizationSection orgTeams={userData.OrgTeams} />
          )}
          {title === 'Reports' && <DashboardReportsAndActivity />}
          {title === 'My Issue Tasks' && (
            <UserDashboardIssueTasks tasks={taskList} />
          )}
          {title === 'Upcoming Meetings' && (
            <UserDashboardMeetings issues={userData.Issues} />
          )}
          {title === 'Issues By Location' && (
            <DashboardIssueLocationSection
              assetList={userData.Assets}
              issues={userData.Issues}
              userView
            />
          )}
        </DashboardSection>
      )
    }
  }

  return (
    <div
      style={{
        backgroundColor: 'white',
        margin: '0px',
      }}
    >
      <ReactGridLayout
        layouts={{ sm: smallLayout, lg: largeLayout }}
        onLayoutChange={handleLayoutChange}
        rowHeight={275}
        width={6}
        breakpoints={{ lg: 960, sm: 0 }}
        cols={{ lg: 3, sm: 1 }}
        style={{ position: 'relative' }}
        margin={[20, 20]}
        isDraggable={reorderMode}
        isResizable={reorderMode}
      >
        {sectionList.map((section, index) => {
          return (
            <div key={section.title} style={{ position: 'relative' }}>
              {getLayout(section.title)}
            </div>
          )
        })}
      </ReactGridLayout>
    </div>
  )
}

import React from 'react'
import { CSSProperties, MouseEvent } from 'react'
import { Typography } from '@material-ui/core'
import Moment from 'react-moment'
import { cx } from '../_utils/objectUtils'
import { MultiLineText } from '../Shared/MultiLineText'
import { appDateTimeFormat } from '../_constants'
import { sanitizeHTML } from '../_utils/domUtils'
import { getLocalDate } from '../_utils/dateUtils'
import classes from './IssueSummaryHeader.module.scss'
import OrgTagContainer from '../Org/OrgTagContainer'
import { Spacer } from '../Shared/Spacer'
import { useAppSelector } from '../_utils/reactReduxHooks'
import { selectUInamesMapping } from './_selectors'

const noRestrictionLineNameStyle: CSSProperties = { wordBreak: 'break-word' }
const multiLineNameStyle = { display: 'block' }

interface Props {
  IssueName: string
  issueNameLines: number
  showTags?: boolean
  tagIds?: number[]
  compact?: boolean
  IssueTypeDescr?: string
  IssueTypeRGB?: string
  showIssueLevel?: boolean
  titleSize?: number
  titleNoWrap?: boolean
  titleColor?: string
  Description?: string
  descriptionLines: number
  LevelName?: string
  LevelRGB?: string
  IssueCreated?: Date
  onClick?(event: MouseEvent): void
  className?: string
  lastActivityDate?: Date
  IssueTrendName?: string
  IssueTrendColor?: string
  showDescription?: boolean
  titleWeight?: string
  ResponsibleName?: string
  showResponsibleName?: boolean
}

export const IssueSummaryHeader = ({
  IssueName = '',
  issueNameLines = 0,
  showTags = false,
  tagIds = [],
  compact,
  IssueTypeDescr,
  IssueTypeRGB,
  showIssueLevel = true,
  titleSize,
  titleNoWrap,
  titleColor,
  Description = '',
  descriptionLines = 3,
  LevelName,
  LevelRGB,
  IssueCreated,
  onClick,
  className,
  lastActivityDate,
  IssueTrendName = '',
  IssueTrendColor,
  showDescription = true,
  titleWeight,
  ResponsibleName = '',
  showResponsibleName = false,
}: Props) => {
  const responsibleDisplayName =
    useAppSelector(selectUInamesMapping)?.find(
      (mapping) => mapping.nameBackend === 'Responsible'
    )?.nameFrontend || 'Leader'

  const renderIssueTitle = () => {
    if (IssueName.trim().length === 0) return <br />

    if (!issueNameLines)
      return (
        <span className={classes.issueName} style={noRestrictionLineNameStyle}>
          {IssueName}
        </span>
      )

    return (
      <MultiLineText
        className={classes.issueName}
        style={multiLineNameStyle}
        lines={issueNameLines}
      >
        {IssueName}
      </MultiLineText>
    )
  }

  const titleStyles: any = {
    fontSize: titleSize,
    fontWeight: titleWeight || 'bold',
    color: titleColor,
  }
  const noteStyles: any = {
    fontSize: '.8rem',
    fontWeight: titleWeight || 'bold',
    color: titleColor,
  }

  if (titleNoWrap) titleStyles.whiteSpace = 'nowrap'

  if (compact) {
    titleStyles.whiteSpace = 'nowrap'
    titleStyles.textOverflow = 'ellipsis'
  }

  return (
    <div onClick={onClick} className={className}>
      <Typography component="div" className={classes.header}>
        <div className={cx(classes.title)} style={titleStyles}>
          {renderIssueTitle()}
          <Spacer spaceParam={'small'} />
          {showIssueLevel ? (
            <span
              className={cx(classes.level, 'single-line-text')}
              style={{
                borderColor: LevelRGB,
              }}
            >
              {LevelName || ''}
            </span>
          ) : null}
        </div>
        <Typography
          className={cx(classes.dateTime, 'd-flex flex-wrap')}
          variant="body2"
          color="textSecondary"
          component="p"
        >
          <Moment
            local
            format={appDateTimeFormat}
            date={getLocalDate(IssueCreated)}
            style={{ marginRight: 15 }}
          />
          <Moment local fromNow>
            {getLocalDate(IssueCreated)}
          </Moment>
        </Typography>
        {!Description || !showDescription ? null : (
          <MultiLineText
            className={classes.description}
            style={{ display: 'block' }}
            lines={descriptionLines}
            dangerouslySetInnerHTML={sanitizeHTML(Description, {
              allowedTags: ['p', 'br', 'u', 's', 'ol', 'ul', 'li'],
            })}
          />
        )}
        <div className="single-line-text" style={{ color: IssueTrendColor }}>
          {IssueTrendName}
        </div>
        <div className="clearfix">
          <div className={classes.lastActivity}>
            <span style={noteStyles}>Activity: </span>
            <Moment className={classes.dateTime} local fromNow>
              {getLocalDate(lastActivityDate)}
            </Moment>
          </div>
          <span className={classes.typeDescr} style={{ color: IssueTypeRGB }}>
            {IssueTypeDescr || ''}
          </span>
        </div>
        {showResponsibleName && (
          <div className={classes.responsible}>
            <label className="mr-1">{responsibleDisplayName}:</label>
            <span>{ResponsibleName}</span>
          </div>
        )}
        {showTags && <OrgTagContainer className="mt-2" tagIds={tagIds} />}
      </Typography>
    </div>
  )
}

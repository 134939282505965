import React from 'react'
import { HPanel, HPanelLayout } from '../Layouts/HPanelLayout'
import { InboxList } from '../Alert/InboxList'
import { AlertList } from '../Alert/AlertList'
import { AlertDetails } from '../Alert/AlertDetails'
import { DesktopLayout, MobileLayout } from '../Layouts/ResponsiveHidden'
import MobileViewLayout from '../Layouts/MobileViewLayout'
import {
  Switch,
  useHistory,
  Route,
  useRouteMatch,
  useLocation,
} from 'react-router'
import { cx } from '../_utils/objectUtils'
import { MainPageBackBtn } from '../Shared/Buttons'
import classes from '../Alert/Alerts.module.scss'
import {
  StretchLayout,
  StretchHeader,
  StretchBody,
} from '../Layouts/StretchLayout'
import AppHeader from '../AppHeader'
import { AllRouteParams } from '../Routing/RouteProps'
import { useAppSelector } from '../_utils/reactReduxHooks'
import { isMobileApp } from '../_selectors'

export const AlertPage = () => {
  const history = useHistory()
  const location = useLocation()
  const match = useRouteMatch<AllRouteParams>()
  const isMobile = useAppSelector(isMobileApp)
  return (
    <StretchLayout>
      <StretchHeader>
        <AppHeader
          routeProps={{ history: history, location: location, match: match }}
        />
      </StretchHeader>
      <StretchBody>
        <DesktopLayout>
          <Switch>
            <Route path={`${match.path}/Inbox/:inboxGUID`}>
              <HPanelLayout>
                <HPanel
                  hasPaper={false}
                  contentHeader={
                    isMobile ? (
                      <MainPageBackBtn onClick={() => history.push('/Alert')} />
                    ) : (
                      false
                    )
                  }
                  overflowY="hidden"
                >
                  <InboxList />
                </HPanel>
                <HPanel>
                  <AlertList />
                </HPanel>
                <HPanel fillWidth={true}>
                  <Switch>
                    <Route path={`${match.path}/Inbox/:inboxGUID`} exact>
                      <p
                        style={{
                          fontStyle: 'italic',
                          marginTop: '1em',
                          position: 'relative',
                          padding: '1rem',
                          marginLeft: '45%',
                          marginRight: '50%',
                          width: '200px',
                        }}
                      >
                        Choose an Alert...
                      </p>
                    </Route>
                    <Route
                      path={`${match.path}/Inbox/:inboxGUID/alerts/:alertID`}
                    >
                      <AlertDetails />
                    </Route>
                  </Switch>
                </HPanel>
              </HPanelLayout>
            </Route>
            <Route path={`${match.path}/Inbox`} exact>
              <div className="h-100 p-2">
                <InboxList />
              </div>
            </Route>
            <Route path={`${match.path}`} exact>
              <div className={cx('h-100', 'p-2', classes.pbList)}>
                <InboxList />
              </div>
            </Route>
          </Switch>
        </DesktopLayout>
      </StretchBody>
      <MobileLayout>
        <Switch>
          <Route path={`${match.path}/Inbox/:inboxGUID`}>
            <MobileViewLayout
              title="Alerts"
              onNavClick={() => history.push('/Alert')}
            >
              <Switch>
                <Route path={`${match.path}/Inbox/:inboxGUID/alerts/:alertID`}>
                  <AlertDetails />
                </Route>
                <Route path={`${match.path}/Inbox/:inboxGUID`}>
                  <AlertList />
                </Route>
              </Switch>
            </MobileViewLayout>
          </Route>
          <Route path={`${match.path}`} exact>
            <div className={cx('h-100', classes.pbList, classes.mobile)}>
              <InboxList />
            </div>
          </Route>
        </Switch>
      </MobileLayout>
    </StretchLayout>
  )
}

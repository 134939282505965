import { Location } from 'history'
import { RouteComponentProps } from 'react-router'
import { AppDomain } from '../_models'
import { AllRouteParams } from './RouteProps'

export type LocationFunc = (location: Location) => Location | string
export interface Matcher {
  to: Location | string | LocationFunc
  exact?: boolean
}

export function isSelected(matcher: Matcher | Matcher[], location?: Location) {
  if (matcher instanceof Array) {
    let arr = matcher as Matcher[]
    for (let i = 0; i < arr.length; i++) {
      if (isSelected(arr[i], location)) return true
    }

    return false
  }

  const { to, exact } = matcher
  let curPath = location?.pathname?.toLocaleLowerCase() || ''
  let pathLoc = getPathname(to, location)?.toLocaleLowerCase() || ''

  if (curPath === '/') curPath = ''

  if (pathLoc === '/') pathLoc = ''

  pathLoc = pathLoc.endsWith('/') ? pathLoc.slice(0, -1) : pathLoc
  curPath = curPath.endsWith('/') ? curPath.slice(0, -1) : curPath

  if (
    pathLoc === curPath ||
    (!exact && curPath.startsWith(pathLoc + '/') && pathLoc !== '')
  )
    return true

  return false
}

function getPathname(
  to: Location | string | LocationFunc,
  location?: Location
): string {
  if (isPathObject(to))
    return (to as Location)?.pathname?.toLocaleLowerCase() || ''
  else if (isPathFunction(to)) {
    let pathFunc = to as unknown as LocationFunc
    if (location === undefined) return ''
    const result = pathFunc(location)
    if (isPathObject(result)) return getPathname(result, location)
  }

  return (to as string)?.toLocaleLowerCase() || ''
}

function isPathObject(to: Location | string | LocationFunc): to is Location {
  return typeof to === 'object'
}

function isPathFunction(to: Location | string | LocationFunc): to is string {
  return typeof to === 'function'
}

const issuePathMatchers: Matcher[] = [
  { to: '/' },
  { to: '/IssueDetail' },
  { to: '/Issues' },
]

const playbookPathMatchers: Matcher[] = [{ to: '/Playbook' }]
const dashboardPathMatchers: Matcher[] = [{ to: '/Dashboard' }]
const userReportsPathMatchers: Matcher[] = [{ to: '/UserReports' }]
const addPlaybookPathMatchers: Matcher[] = [{ to: '/AddPlaybook' }]
const alertPathMatchers: Matcher[] = [{ to: '/Alert' }]
const pbChatPathMatchers: Matcher[] = [{ to: '/PbChat' }]
const pbTaskPathMatchers: Matcher[] = [{ to: '/PbTask' }]

const inboxPathMatchers: Matcher[] = [{ to: '/Alert' }]
const sendNotificationsPathMatchers: Matcher[] = [{ to: '/sendNotifications' }]

const reportPathMatcher: Matcher[] = [{ to: '/Reports' }]

const adminPathMatcher: Matcher[] = [{ to: '/Admin' }]

const activePollsMatcher: Matcher[] = [{ to: '/ActivePolls' }]

const userTasksQueryPanelMatcher: Matcher[] = [{ to: '/UserTasksQueryPanel' }]

export function isIssuePath(location?: Location) {
  return isSelected(issuePathMatchers, location)
}

export function isDashboardPath(location?: Location) {
  return isSelected(dashboardPathMatchers, location)
}

export function isPlaybookPath(location?: Location) {
  return isSelected(playbookPathMatchers, location)
}

export function isUserReportsPath(location?: Location) {
  return isSelected(userReportsPathMatchers, location)
}

export function isAddPlaybookPath(location?: Location) {
  return isSelected(addPlaybookPathMatchers, location)
}

export function isAlertPath(location?: Location) {
  return isSelected(alertPathMatchers, location)
}

export function isInboxPath(location?: Location) {
  return isSelected(inboxPathMatchers, location)
}
export function isSendNotificationsPath(location?: Location) {
  return isSelected(sendNotificationsPathMatchers, location)
}

export function isReportPath(location?: Location) {
  return isSelected(reportPathMatcher, location)
}

export function isPbChatPath(location?: Location) {
  return isSelected(pbChatPathMatchers, location)
}

export function isPbTaskPath(location?: Location) {
  return isSelected(pbTaskPathMatchers, location)
}

export function isAdminPath(location?: Location) {
  return isSelected(adminPathMatcher, location)
}
export function isActivePolls(location?: Location) {
  return isSelected(activePollsMatcher, location)
}

export function isUserTasksQueryPanel(location?: Location) {
  return isSelected(userTasksQueryPanelMatcher, location)
}

export function getLocationFromRouteProps(
  routeProps?: RouteComponentProps<AllRouteParams>
) {
  return routeProps?.history?.location
}

export interface PathAppSection {
  id: string
  title: string
  domain: AppDomain
}

export function getPathAppSection(location?: Location): PathAppSection {
  if (isDashboardPath(location))
    return { domain: 'dashboard', id: 'dashboard', title: 'Dashboard' }

  if (isUserTasksQueryPanel(location))
    return {
      domain: 'usertasksquerypanel',
      id: 'usertasksquerypanel',
      title: 'User Tasks Query Panel',
    }

  if (isIssuePath(location))
    return { domain: 'issues', id: 'issues', title: 'Issues' }
  if (isPlaybookPath(location))
    return { domain: 'playbooks', id: 'playbooks', title: 'Playbooks' }
  if (isUserReportsPath(location))
    return {
      domain: 'userReports',
      id: 'userReports',
      title: 'User Reports',
    }
  if (isAddPlaybookPath(location))
    return {
      domain: 'addplaybook',
      id: 'addplaybook',
      title: 'Include Playbooks',
    }
  if (isInboxPath(location))
    return { domain: 'inbox', id: 'inbox', title: 'Alerts' }
  if (isSendNotificationsPath(location))
    return {
      domain: 'sendNotifications',
      id: 'sendNotify',
      title: 'Send Notifications',
    }
  if (isPbChatPath(location))
    return { domain: 'pbchat', id: 'pbchat', title: 'Secure Chats' }
  if (isPbTaskPath(location))
    return {
      domain: 'pbtask',
      id: 'pbtask',
      title: 'Shared Checklists',
    }
  if (isReportPath(location))
    return { domain: 'issues', id: 'reports', title: 'Reports' }
  if (isAdminPath(location))
    return {
      domain: 'admin',
      id: 'adminConsole',
      title: 'Issues Admin Console',
    }
  if (isActivePolls(location))
    return {
      domain: 'activePolls',
      id: 'activePolls',
      title: 'User Reports',
    }
  return { domain: 'issues', id: 'issues', title: 'Issues' }
}

const basePathStateEnabled = [
  'issuedetail',
  'playbook',
  'addplaybook',
  'dashboard',
  'usertasksquerypanel',
  'pbchat',
  'pbtask',
  'alert',
  'reports/issuestracker',
  'reports/stakeholderinfluencer',
  'reports/afteraction',
  'userReports/pushnotificationsreceived',
  'userReports/pollnotificationsreceived',
  'userReports/reportsformssent',
  'activePolls',
  'admin/adminConsole',
]

/**
 *  @description Gets the key that should be used to save the state of the path.
 *  This should be the base path of the page being navigated to
 */
export function getPathSaveStateKey(path: string) {
  const formattedPath = path.trim().toLocaleLowerCase()
  for (let i = 0; i < basePathStateEnabled.length; i++) {
    if (formattedPath.startsWith('/' + basePathStateEnabled[i]))
      return basePathStateEnabled[i]
  }
}

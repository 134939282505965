import * as React from 'react'
import AppDialog, { AppDialogSection } from '../AppDialog'
import { Link } from './Link'
import { Location } from 'history'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faArrowAltRight,
  faCheckCircle,
  faCog,
  faCommentAlt,
  faFileSearch,
  faPlusCircle,
  faStar,
  faUser,
  faInboxIn,
  faPoll,
  faList,
  faPollPeople,
  faClipboardList,
} from '@fortawesome/pro-solid-svg-icons'
import { faExclamationTriangle } from '@fortawesome/pro-regular-svg-icons'
import { alertIcon } from '../Org/_constants'
import { prefixSelectorWithApp } from '../_utils/domUtils'
import { useAppSelector } from '../_utils/reactReduxHooks'
import { selectBasePathState } from './_selectors'
import { Spacer } from '../Shared/Spacer'
import { AccountOrganization, Organization } from '../Issue/_reducer'
import { selectUserOrgInfo } from '../Issue/_selectors'
import { ReactComponent as SendNotificationsIcon } from '../_images/sendNotifications.svg'

import { useStore } from 'react-redux'
import { RootState } from '../_store'
import { InboxModel } from '../Alert/_models'
//import classes from "./AppNav.module";

interface Props {
  onCloseClicked?: () => void
  initialSelection?: string
  location?: Location
}

const issueMatchers = [{ to: '/IssueDetail', exact: false }]

function createDomId(selector: string) {
  return prefixSelectorWithApp('appNav-') + selector
}

function createDomNavId(selector: string) {
  return createDomId('link-' + selector)
}

export const AppNav = ({ onCloseClicked = () => {}, location }: Props) => {
  const state: RootState = useStore().getState()
  const issueLink = useAppSelector((state) =>
    selectBasePathState(state, 'IssueDetail')
  )
  const playbookLink = useAppSelector((state) =>
    selectBasePathState(state, 'Playbook')
  )
  const addPlaybookLink = useAppSelector((state) =>
    selectBasePathState(state, 'AddPlaybook')
  )
  const chatLink = useAppSelector((state) =>
    selectBasePathState(state, 'PbChat')
  )
  const taskLink = useAppSelector((state) =>
    selectBasePathState(state, 'PbTask')
  )
  let currentLatestInbox: InboxModel | null = null
  let inboxGUIDParam = ''
  let currentLatestInboxLastAlertCreated: number | null = null
  Object.keys(state.inbox).forEach((key) => {
    let inbox: InboxModel = state.inbox[key]
    if (!(inbox.IsActiveYN === 'Y')) return
    if (!(currentLatestInbox && currentLatestInboxLastAlertCreated)) {
      currentLatestInbox = inbox
      inboxGUIDParam = `/${inbox.inboxGUID}`
      currentLatestInboxLastAlertCreated =
        inbox.lastAlertCreated?.getTime() || null
      return
    }
    const inboxLastAlertCreated = inbox.lastAlertCreated?.getTime() || null
    if (
      inboxLastAlertCreated &&
      inboxLastAlertCreated > currentLatestInboxLastAlertCreated
    ) {
      currentLatestInbox = inbox
      inboxGUIDParam = `/${inbox.inboxGUID}`
      currentLatestInboxLastAlertCreated = inboxLastAlertCreated
      return
    }
  })
  const alertLink = '/Alert/Inbox' + inboxGUIDParam
  const sendNotifyLink = '/sendNotifications'
  const dashboardLink = useAppSelector((state) =>
    selectBasePathState(state, 'Dashboard')
  )

  const executiveTrackerLink = useAppSelector((state) =>
    selectBasePathState(state, 'Reports/IssuesTracker')
  )

  const userTasksQueryPanelLink = useAppSelector((state) =>
    selectBasePathState(state, 'UserTasksQueryPanel')
  )
  const stakeholderLink = useAppSelector((state) =>
    selectBasePathState(state, 'Reports/StakeholderInfluencer')
  )
  const afterActionLink = useAppSelector((state) =>
    selectBasePathState(state, 'Reports/AfterAction')
  )
  const pushNotificationsReceivedLink = useAppSelector((state) =>
    selectBasePathState(state, 'UserReports/PushNotificationsReceived')
  )
  const pollNotificationsReceivedLink = useAppSelector((state) =>
    selectBasePathState(state, 'UserReports/PollNotificationsReceived')
  )
  const reportsFormsSentLink = useAppSelector((state) =>
    selectBasePathState(state, 'UserReports/ReportsFormsSent')
  )
  const adminEditIssueTagsLink = useAppSelector((state) =>
    selectBasePathState(state, 'Admin/AdminConsole')
  )
  const activePollsLink = useAppSelector((state) =>
    selectBasePathState(state, 'ActivePolls')
  )
  const userOrgInfo: AccountOrganization =
    useAppSelector((state) => selectUserOrgInfo(state)) || []

  const adminOrgs = userOrgInfo.Orgs?.filter((org: Organization) => {
    const administratorTeams = org.IssueTeams?.filter(
      (team) => team.TeamRole === 'Administrator'
    )
    return administratorTeams?.length > 0
  })

  return (
    <AppDialog
      id={createDomId('appDialog')}
      showHeader={true}
      onCloseClicked={onCloseClicked}
      hasPadding={false}
    >
      <AppDialogSection header="DASHBOARD">
        <Link
          id={createDomNavId('dashboard')}
          to={dashboardLink}
          location={location}
        >
          <div>
            <FontAwesomeIcon icon={alertIcon} />
          </div>
          <Spacer />
          Dashboard
        </Link>
        {/*<p><Link id={createDomNavId("incidentReports")} to="/IncidentReports" location={location}><FontAwesomeIcon icon={faExclamationTriangle} /> Incident Reports</Link></p>
                <p><Link id={createDomNavId("issueNoty")} to="/IssueNoty" location={location}><FontAwesomeIcon icon={faBell} /> Issue Notifications</Link></p>
                <p><Link id={createDomNavId("pushMsg")} to="/PushMessages" location={location}><FontAwesomeIcon icon={faEnvelope} /> Push Messages</Link></p>
                <p><Link id={createDomNavId("polls")} to="/Polls" location={location}><FontAwesomeIcon icon={faPoll} /> Polls</Link></p>
                <p><Link id={createDomNavId("inboxSettings")} to="/InboxSettings" location={location}><FontAwesomeIcon icon={faCog} /> Inbox Settings</Link></p>*/}
      </AppDialogSection>
      <AppDialogSection header="COMMUNICATIONS">
        <Link id={createDomNavId('alerts')} to={alertLink} location={location}>
          <div>
            <FontAwesomeIcon icon={alertIcon} />
          </div>
          <Spacer />
          Alerts
        </Link>
        <Link
          id={createDomNavId('sendNotifications')}
          to={sendNotifyLink}
          location={location}
        >
          <div>
            <SendNotificationsIcon height="20px" width="20px" />
          </div>
          <Spacer />
          Send Notifications
        </Link>
        {/*<p><Link id={createDomNavId("incidentReports")} to="/IncidentReports" location={location}><FontAwesomeIcon icon={faExclamationTriangle} /> Incident Reports</Link></p>
                <p><Link id={createDomNavId("issueNoty")} to="/IssueNoty" location={location}><FontAwesomeIcon icon={faBell} /> Issue Notifications</Link></p>
                <p><Link id={createDomNavId("pushMsg")} to="/PushMessages" location={location}><FontAwesomeIcon icon={faEnvelope} /> Push Messages</Link></p>
                <p><Link id={createDomNavId("polls")} to="/Polls" location={location}><FontAwesomeIcon icon={faPoll} /> Polls</Link></p>
                <p><Link id={createDomNavId("inboxSettings")} to="/InboxSettings" location={location}><FontAwesomeIcon icon={faCog} /> Inbox Settings</Link></p>*/}
      </AppDialogSection>
      <AppDialogSection header="ISSUES">
        <Link
          id={createDomNavId('issues')}
          to={issueLink}
          matchers={issueMatchers}
          location={location}
          onClick={onCloseClicked}
        >
          <div>
            <FontAwesomeIcon icon={faFileSearch} flip="horizontal" size="2x" />
          </div>
          <Spacer />
          Issues
        </Link>
        <Link
          id={createDomNavId('userTasksQueryPanel')}
          to={userTasksQueryPanelLink}
          location={location}
          onClick={onCloseClicked}
        >
          <div>
            <FontAwesomeIcon icon={faClipboardList} />
          </div>
          <Spacer />
          User Tasks Query Panel
        </Link>

        <Link
          id={createDomNavId('executiveTracker')}
          to={executiveTrackerLink}
          location={location}
          onClick={onCloseClicked}
        >
          <div>
            <FontAwesomeIcon icon={faStar} />
          </div>
          <Spacer />
          Executive Tracker
        </Link>
        <Link
          id={createDomNavId('stakeholder')}
          to={stakeholderLink}
          location={location}
          onClick={onCloseClicked}
        >
          <div>
            <FontAwesomeIcon icon={faUser} />
          </div>
          <Spacer /> Stakeholder Report
        </Link>
        <Link
          id={createDomNavId('afterAction')}
          to={afterActionLink}
          location={location}
          onClick={onCloseClicked}
        >
          <div>
            <FontAwesomeIcon icon={faArrowAltRight} />
          </div>
          <Spacer /> After Action Report
        </Link>
        {adminOrgs?.length > 0 ? (
          <Link
            id={createDomNavId('issuesAdminConsole')}
            to={adminEditIssueTagsLink}
            location={location}
            onClick={onCloseClicked}
          >
            <div>
              <FontAwesomeIcon icon={faCog} />
            </div>
            <Spacer />
            Issues Admin Console
          </Link>
        ) : null}
      </AppDialogSection>
      <AppDialogSection header="PLAYBOOKS">
        <Link
          id={createDomNavId('playbooks')}
          to={playbookLink}
          location={location}
          onClick={onCloseClicked}
        >
          <div>
            <FontAwesomeIcon icon={faList} />
          </div>
          <Spacer /> Playbooks
        </Link>
        <Link id={createDomNavId('chats')} to={chatLink} location={location}>
          <div>
            <FontAwesomeIcon icon={faCommentAlt} flip="horizontal" />
          </div>
          <Spacer />
          Secure Chats
        </Link>
        <Link
          id={createDomNavId('checkLists')}
          to={taskLink}
          location={location}
        >
          <div>
            <FontAwesomeIcon icon={faCheckCircle} />
          </div>
          <Spacer />
          Shared Checklists
        </Link>
        <Link
          id={createDomNavId('addPlaybook')}
          to={addPlaybookLink}
          location={location}
          onClick={onCloseClicked}
        >
          <div>
            <FontAwesomeIcon icon={faPlusCircle} />{' '}
          </div>
          <Spacer />
          Include Public Playbooks
        </Link>
      </AppDialogSection>
      <AppDialogSection header="USER REPORTS">
        <Link
          id={createDomNavId('PushNotificationsReceived')}
          to={pushNotificationsReceivedLink}
          location={location}
          onClick={onCloseClicked}
        >
          <div>
            <FontAwesomeIcon icon={faInboxIn} />
          </div>
          <Spacer /> Push Notifications Received
        </Link>
        <Link
          id={createDomNavId('PollNotificationsReceived')}
          to={pollNotificationsReceivedLink}
          location={location}
          onClick={onCloseClicked}
        >
          <div>
            <FontAwesomeIcon icon={faPoll} />
          </div>
          <Spacer /> Poll Notifications Received
        </Link>
        <Link
          id={createDomNavId('ActivePolls')}
          to={activePollsLink}
          location={location}
          onClick={onCloseClicked}
        >
          <div>
            <FontAwesomeIcon icon={faPollPeople} />
          </div>
          <Spacer /> Active Polls
        </Link>
        <Link
          id={createDomNavId('ReportsFormsSent')}
          to={reportsFormsSentLink}
          location={location}
          onClick={onCloseClicked}
        >
          <div>
            <FontAwesomeIcon icon={faExclamationTriangle} />
          </div>
          <Spacer />
          Reports &amp; Forms Sent
        </Link>
      </AppDialogSection>
    </AppDialog>
  )
}

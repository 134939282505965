// @ts-nocheck
// TODO: Typescript
import { generateCrudActions, generateActions } from '../_utils/reduxUtils'
import {
  IssueConstants,
  FETCH_ORG_ISSUE_LEVELS,
  FETCH_APP_USER_ORGS,
  FETCH_ORG_ISSUE_TYPES,
  FETCH_APP_USER_INFO,
  FETCH_APP_UInamesMapping,
  RESOLVE_ISSUE,
  FilterConstants,
  IssueTagConstants,
  CHANGE_ISSUE_STAGE_TAB,
  FilterSaveConstants,
  FETCH_ISSUE_ACTIVITY_LOG,
  ADD_ISSUE_ACTIVITY_LOG,
  SET_DEFAULT_ISSUE,
  MARK_READ_ISSUE_ACTIVITY_LOG,
  FETCH_LATEST_ISSUE_ACTIVIY_DATES,
  FETCH_ISSUE_DETAILS,
  CHANGE_ISSUE_ACTIVITY_LOG_FILTER,
  EvidenceLockerLinkConstants,
  GeolocationConstants,
} from './_constants'
import { EvidenceLockerLink } from './_reducer'
import { IssueGeolocation } from './_reducer'

export const IssueActions = generateCrudActions('ISSUE')
export const IssueLevelActions = generateCrudActions('ISSUE_LEVEL')
export const IssueTagActions = generateCrudActions(IssueTagConstants.PREFIX)
export const fetchIssueActivityLog = generateActions(FETCH_ISSUE_ACTIVITY_LOG)
export const addIssueActivityLog = generateActions(ADD_ISSUE_ACTIVITY_LOG)
export const fetchAppUserOrgs = generateActions(FETCH_APP_USER_ORGS)
export const markReadIssueActivityLog = generateActions(
  MARK_READ_ISSUE_ACTIVITY_LOG
)
export const fetchLatestActivityLogDates = generateActions(
  FETCH_LATEST_ISSUE_ACTIVIY_DATES
)
export const fetchIssueDetails = generateActions(FETCH_ISSUE_DETAILS)
export const changeIssueActivityLogFilter = generateActions(
  CHANGE_ISSUE_ACTIVITY_LOG_FILTER
)

export function setDefaultIssue(issue: number) {
  return {
    type: SET_DEFAULT_ISSUE,
    payload: { issue },
  }
}

export function fetchOrgIssueLevelsSuccess(entities) {
  return {
    type: FETCH_ORG_ISSUE_LEVELS.SUCCESS,
    payload: {
      result: entities,
    },
  }
}

export function fetchOrgIssueLevelsRequest(OrgId) {
  return { type: FETCH_ORG_ISSUE_LEVELS.REQUEST, OrgId }
}

export function fetchOrgIssueTypesSuccess(entities) {
  return {
    type: FETCH_ORG_ISSUE_TYPES.SUCCESS,
    payload: {
      result: entities,
    },
  }
}

export function fetchOrgIssueTypesRequest() {
  return { type: FETCH_ORG_ISSUE_TYPES.REQUEST }
}

export const applyIssueFilter = function (filter) {
  return { type: IssueConstants.APPLY_FILTER, filter }
}

export function fetchAppUserInfoSuccess(result) {
  return {
    type: FETCH_APP_USER_INFO.SUCCESS,
    payload: {
      result,
    },
  }
}
export function fetchAppUInamesMappingSuccess(result) {
  return {
    type: FETCH_APP_UInamesMapping.SUCCESS,
    payload: {
      result,
    },
  }
}

export function resolveIssue(entity) {
  const { IssueID } = entity || {}
  let action = IssueActions.update(entity, {
    action: 'set resolved',
    icoRequestId: IssueID,
  })
  action.type = RESOLVE_ISSUE.LOADING
  return action
}

export function resolveIssueSuccess(entity) {
  const { IssueID } = entity || {}
  let action = IssueActions.updateSuccess(entity, { icoRequestId: IssueID })
  action.type = RESOLVE_ISSUE.SUCCESS
  return action
}

export function resolveIssueFailure(errorMessage, result, source) {
  const { IssueID } = result || source || {}
  let action = IssueActions.updateFailure(errorMessage, result, {
    icoRequestId: IssueID,
  })
  action.type = RESOLVE_ISSUE.FAILURE
  return action
}

export function issueFilterSave(filter) {
  return { type: FilterSaveConstants.REQUEST, data: filter }
}

export function issueFilterSaving() {
  return { type: FilterSaveConstants.LOADING }
}

export function issueFilterSaveSuccess(filter) {
  return { type: FilterSaveConstants.SUCCESS, data: filter }
}

export function issueFilterSaveFailure(error) {
  return { type: FilterSaveConstants.FAILURE, data: error }
}

export function issueFilterSuccess(entry) {
  return { type: FilterConstants.SUCCESSFUL, data: entry }
}

export function issueFilterFailure(entry) {
  return { type: FilterConstants.FAILURE, data: entry }
}

export function issueFilterFetch() {
  return { type: FilterConstants.FETCH }
}

export function changeIssueStageTab(tab) {
  return {
    type: CHANGE_ISSUE_STAGE_TAB,
    payload: {
      tab,
    },
  }
}

export const EvidenceLockerLinkActions =
  generateCrudActions<EvidenceLockerLink>(EvidenceLockerLinkConstants.PREFIX)

export function toggleSMSPromptOff() {
  return { type: 'issueApp/toggleSMSPromptNeeded' }
}

export const GeolocationActions = generateCrudActions<IssueGeolocation>(
  GeolocationConstants.PREFIX
)

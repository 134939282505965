import { generateTypes } from '../_utils/reduxUtils'

// Alerts
export const ALERT_FILTER = {
  LOADING: 'ALERT_FILTER_LOADING',
  SUCCESSFUL: 'ALERT_FILTER_SUCCESSFUL',
  FAILURE: 'ALERT_FILTER_FAILURE',
  FETCH: 'ALERT_FILTER_FETCH',
}
export const ALERT_SEVERITY_COLORS = {
  LOADING: 'ALERT_SEVERITY_COLORS_LOADING',
  SUCCESSFUL: 'ALERT_SEVERITY_COLORS_SUCCESSFUL',
  FAILURE: 'ALERT_SEVERITY_COLORS_FAILURE',
  FETCH: 'ALERT_SEVERITY_COLORS_FETCH',
}
export const ALERT_FILTER_SAVE = generateTypes('ALERT_FILTER', 'SAVE')

export const FETCH_ALERTS = generateTypes('FETCH_ALERTS')
export const MARK_REVIEWED_ALERT = generateTypes('MARK_REVIEWED_ALERT')
export const MARK_NOT_REVIEWED_ALERT = generateTypes('MARK_NOT_REVIEWED_ALERT')
export const PIN_ALERT = generateTypes('PIN_ALERT')
export const UNPIN_ALERT = generateTypes('UNPIN_ALERT')
export const SHARE_ALERT = generateTypes('SHARE_ALERT')
export const ALERT_ASSESSMENT = generateTypes('ALERT_ASSESSMENT')
export const CREATE_NEW_ALERT_ISSUE = generateTypes('CREATE_NEW_ALERT_ISSUE')
export const CREATE_CLONED_ALERT_ISSUE = generateTypes(
  'CREATE_CLONED_ALERT_ISSUE'
)
export const ADD_ALERT_TO_ISSUE = generateTypes('ADD_ALERT_TO_ISSUE')

// Inbox
export const FETCH_INBOXES = generateTypes('FETCH_INBOXES')
export const ADD_INBOX = generateTypes('ADD_INBOX')
export const DELETE_INBOX = generateTypes('DELETE_INBOX')
export const UPDATE_INBOX = generateTypes('UPDATE_INBOX')
export const SET_INBOX_ACTIVE = generateTypes('SET_INBOX_ACTIVE')

// Feed
export const FETCH_INBOX_FEEDS = generateTypes('FETCH_INBOX_FEEDS')
export const UPDATE_INBOX_FEEDS = generateTypes('UPDATE_INBOX_FEEDS')
export const DELETE_INBOX_FEEDS = generateTypes('DELETE_INBOX_FEEDS')

//other constants
export const combinedInboxName = 'CombinedAlerts'
export type AlertUpdateType =
  | 'markReviewed'
  | 'markNotReviewed'
  | 'pin'
  | 'unPin'
  | 'addToIssue'
  | 'newIssue'
  | 'share'
  | string

export const ALERT_SEARCH_VALUE = 'SAVE_ALERT_SEARCH'

import React, { useCallback, useMemo } from 'react'
import { MouseEvent } from 'react'
import { useAppDispatch, useAppSelector } from '../_utils/reactReduxHooks'
import { useHistory, useLocation, useParams } from 'react-router'
import { AlertsRouteParams } from '../Routing/RouteProps'
import {
  selectAlertByID,
  selectInboxByID,
  selectInboxIDsWithDefaultSeverity,
} from './_selectors'
import classes from './Alerts.module.scss'
import { cx } from '../_utils/objectUtils'
import { Spacer } from '../Shared/Spacer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { combinedInboxName } from './_constants'
import {
  faThumbtack as faSolidPinned,
  faCheckSquare,
} from '@fortawesome/pro-solid-svg-icons'
import { faSquare } from '@fortawesome/pro-regular-svg-icons'
import { markReviewedAlert, markNotReviewedAlert } from './_actions'

import {
  faThumbtack as faOutlinedPinned,
  faReply,
} from '@fortawesome/pro-light-svg-icons'

import { Tooltip } from '@material-ui/core'
import { pinAlert, unPinAlert } from './_actions'
import { AlertToDBModel, SeverityColor } from './_models'
import Moment from 'react-moment'
import { appDateTimeFormat } from '../_constants'
import { getLocalDate } from '../_utils/dateUtils'
import { selectAlertSeverityColors } from '../Issue/_selectors'

// import dayjsPluginUTC from 'dayjs/plugin/utc'

// dayjs.extend(dayjsPluginUTC)

export interface AlertItemProps {
  externalID: string
  index: number
}

export const AlertItem = ({ externalID, index }: AlertItemProps) => {
  const history = useHistory()
  const { inboxGUID } = useParams<AlertsRouteParams>()
  const location = useLocation()
  const dispatch = useAppDispatch()
  const severityColors = useAppSelector(selectAlertSeverityColors)
  const alert = useAppSelector((state) => selectAlertByID(state, externalID))
  const inboxInfo = useAppSelector((state) =>
    selectInboxByID(state, alert.inboxID)
  )
  const inboxIDtoSeverityMap = useAppSelector(selectInboxIDsWithDefaultSeverity)
  const inboxDefaultSeverity = useMemo(
    () => inboxIDtoSeverityMap[alert.inboxID],
    [alert.inboxID, inboxIDtoSeverityMap]
  )
  const alertSeverity = useMemo(
    () => alert.alertSeverity || inboxDefaultSeverity,
    [alert.alertSeverity, inboxDefaultSeverity]
  )

  const showPinReviewed = useMemo(
    () =>
      alert?.alertStatusID < 4 &&
      !alert?.issueID &&
      inboxInfo.process_type === 'I' &&
      !alert?.incidentID,
    [
      alert?.alertStatusID,
      alert?.issueID,
      inboxInfo.process_type,
      alert?.incidentID,
    ]
  )

  const severityColor = useMemo(() => {
    return (
      getColorFromSeverity(
        getSeverityNameForColor(alertSeverity),
        severityColors
      ) || ''
    )
  }, [severityColors, alertSeverity])

  const severityStyle = useMemo(
    () =>
      getSeverityNameForColor(alertSeverity)
        ? {
            backgroundColor: severityColor,
            color: 'white',
          }
        : {},
    [alertSeverity, severityColor]
  )

  const alertToDB: AlertToDBModel = useMemo(
    () => ({
      alert_ExtId: alert.Id,
      alert_Updated: getLocalDate(alert.updated),
      alert_URL: alert.url,
      alert_Title: alert.title,
      alert_Content: alert.summary,
      alert_Location: alert.location,
      alert_UrlImage: alert.urlImage,
      alertID: alert.alertID,
      inboxGUID: alert.inboxGUID,
      inboxID: alert.inboxID,
      issueID: alert.issueID,
      rssID: alert.rssID,
      severityLevel: alert.severityLevel,
      alertSeverity: alertSeverity,
      action: alert.alertStatusID === 3 ? 'unPin' : 'pin',
      issueTitle: '',
      issueDescription: '',
      alert_WKT: alert.alert_WKT,
    }),
    [
      alert.Id,
      alert.alertID,
      alert.alertStatusID,
      alert.inboxGUID,
      alert.inboxID,
      alert.issueID,
      alert.location,
      alert.rssID,
      alert.severityLevel,
      alert.summary,
      alert.title,
      alert.updated,
      alert.url,
      alert.urlImage,
      alertSeverity,
      alert.alert_WKT,
    ]
  )

  const handleReviewClick = useCallback(
    (evt: MouseEvent, action) => {
      evt.preventDefault()
      evt.stopPropagation()
      evt.nativeEvent.stopImmediatePropagation()
      if (!showPinReviewed) {
        return
      }
      if (action === 'markReviewed') {
        dispatch(
          markReviewedAlert.request({
            ...alertToDB,
            action,
            alert_Updated: getLocalDate(alertToDB.alert_Updated),
          })
        )
      } else if (action === 'markNotReviewed') {
        dispatch(
          markNotReviewedAlert.request({
            ...alertToDB,
            action,
            alert_Updated: getLocalDate(alertToDB.alert_Updated),
          })
        )
      }
    },
    [alertToDB, dispatch, showPinReviewed]
  )

  const handleClick = React.useCallback(
    (evt: MouseEvent<HTMLDivElement>) => {
      evt.preventDefault()
      evt.stopPropagation()
      evt.nativeEvent.stopImmediatePropagation()

      if (
        externalID !== getAlertIDFromURL(location.pathname, location.search)
      ) {
        history.push(`/Alert/Inbox/${inboxGUID}/alerts/${externalID}`)
      }
    },
    [inboxGUID, externalID, history, location.pathname, location.search]
  )

  const handlePin = React.useCallback(
    (evt: MouseEvent) => {
      evt.preventDefault()
      evt.stopPropagation()
      evt.nativeEvent.stopImmediatePropagation()

      if (alert.alertStatusID === 3) {
        dispatch(unPinAlert.request(alertToDB))
      } else if (alert.alertStatusID <= 3) {
        dispatch(pinAlert.request(alertToDB))
      }
    },
    [alert, dispatch, alertToDB]
  )

  const handleNavigateToIssue = useCallback(
    (evt: MouseEvent) => {
      evt.preventDefault()
      evt.stopPropagation()
      evt.nativeEvent.stopImmediatePropagation()

      if (alert?.issueID && alert?.issueID > 0) {
        history.push({ pathname: '/IssueDetail/' + alert?.issueID })
      }
    },
    [history, alert?.issueID]
  )
  const renderReviewedButton = useCallback(() => {
    if (alert?.alertStatusID === 2 && showPinReviewed) {
      return (
        <Tooltip
          key={'notReviewed'}
          placement="top"
          title={'Mark as new alert'}
        >
          <span
            className={
              getSeverityNameForColor(alertSeverity)
                ? classes.iconWrapperSelected
                : classes.iconWrapper
            }
          >
            <FontAwesomeIcon
              icon={faCheckSquare}
              name="notReviewed"
              onClick={(e) => handleReviewClick(e, 'markNotReviewed')}
            />
          </span>
        </Tooltip>
      )
    } else if (
      (alert?.alertStatusID < 2 || alert?.alertStatusID === 3) &&
      showPinReviewed
    ) {
      return (
        <Tooltip
          key={'reviewed'}
          placement="top"
          title="Mark as Reviewed, no actions taken"
        >
          <span
            className={
              getSeverityNameForColor(alertSeverity)
                ? classes.iconWrapperSelected
                : classes.iconWrapper
            }
          >
            <FontAwesomeIcon
              icon={faSquare}
              name="reviewed"
              onClick={(e) => handleReviewClick(e, 'markReviewed')}
            />
          </span>
        </Tooltip>
      )
    }
  }, [handleReviewClick, alert?.alertStatusID, showPinReviewed, alertSeverity])

  const renderActionIcon = useCallback(() => {
    if (alert.issueID) {
      return (
        <Tooltip title={'Go to issue'} key="go2issue" placement="top">
          <span
            className={
              getSeverityNameForColor(alertSeverity)
                ? classes.iconWrapperSelected
                : classes.iconWrapper
            }
          >
            <FontAwesomeIcon icon={faReply} onClick={handleNavigateToIssue} />
          </span>
        </Tooltip>
      )
    } else if (alert.alertStatusID === 3 && showPinReviewed) {
      return (
        <Tooltip key="unPin" placement="top" title={'Unpin this Alert'}>
          <span
            className={
              getSeverityNameForColor(alertSeverity)
                ? classes.iconWrapperSelected
                : classes.iconWrapper
            }
          >
            <FontAwesomeIcon
              onClick={handlePin}
              icon={faSolidPinned}
              className={classes.iconColor}
            />
          </span>
        </Tooltip>
      )
    } else if (alert.alertStatusID < 3 && showPinReviewed) {
      return (
        <Tooltip key="Pin" placement="top" title={'Pin this Alert'}>
          <span
            className={
              getSeverityNameForColor(alertSeverity)
                ? classes.iconWrapperSelected
                : classes.iconWrapper
            }
          >
            <FontAwesomeIcon onClick={handlePin} icon={faOutlinedPinned} />
          </span>
        </Tooltip>
      )
    }
  }, [
    alert.alertStatusID,
    alert.issueID,
    handleNavigateToIssue,
    handlePin,
    showPinReviewed,
    alertSeverity,
  ])

  return (
    <div
      onClick={handleClick}
      className={cx(
        classes.alertItem,
        classes.iconWrapper,
        alert.Id === getAlertIDFromURL(location.pathname, location.search)
          ? classes.alertSelected
          : ''
      )}
      style={severityStyle}
    >
      <div
        style={{ display: 'flex', flexDirection: 'column', padding: '.5rem' }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
          }}
        >
          <span
            className={cx(
              classes.alertTitle,
              classes.truncate,
              alert.alertStatusID === 2 ? classes.reviewedAlert : ''
            )}
          >
            {alert.title}
          </span>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'flex-start',
            }}
          >
            <Spacer spaceParam={'large'} />
            <Spacer spaceParam={'medium'} />

            {renderReviewedButton()}
            <Spacer spaceParam={'large'} />
            {renderActionIcon()}
            <Spacer spaceParam={'small'} />
          </div>
        </div>
        <Spacer spaceParam={'small'} />
        <div
          className={cx(
            classes.truncate,
            classes.alertItemDetails,
            alert.alertStatusID === 2 ? classes.reviewedAlert : ''
          )}
          dangerouslySetInnerHTML={{ __html: alert.summary }}
        />
        <Spacer spaceParam={'small'} />
        <div
          style={{
            display: 'flex',
            flexDirection: 'row-reverse',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <span
            className={cx(
              classes.alertItemExtra,
              alert.alertStatusID === 2 ? classes.reviewedAlert : ''
            )}
            style={{ fontSize: '.7rem' }}
          >
            <Moment format={appDateTimeFormat} local>
              {getLocalDate(alert?.updated)}
            </Moment>
          </span>
          {combinedInboxName === (inboxGUID ? inboxGUID : '') && (
            <span
              className={cx(
                classes.inboxInlineButton,
                classes.alertItemExtra,
                alert.alertStatusID === 2 ? classes.reviewedAlert : ''
              )}
              style={{ cursor: 'pointer', fontSize: '.7rem' }}
              onClick={(e) => {
                e.stopPropagation()
                e.preventDefault()
                history.push(
                  `/Alert/Inbox/${inboxInfo.inboxGUID}/alerts/${externalID}`
                )
              }}
            >
              Inbox: {inboxInfo.inboxName}
            </span>
          )}
        </div>
      </div>
    </div>
  )
}

export function getAlertIDFromURL(pathname: string, search: string) {
  let alertID = ''
  if (pathname.split('/alerts/').length > 1) {
    alertID = pathname.split('/alerts/').slice(1).join('/alerts/')
    if (search) {
      alertID = alertID + search
    }
  }
  return alertID
}

export const getStandardizedSeverity = (severityName: string | undefined) => {
  switch (severityName?.toLowerCase()) {
    case 'low':
      return 'Low'
    case 'medium':
    case 'moderate':
      return 'Medium'
    case 'high':
    case 'critical':
    case 'severe':
      return 'High'
    default:
      return severityName
  }
}
export const getSeverityNameForColor = (severityName: string | undefined) => {
  if (
    ['Low', 'Medium', 'High'].includes(
      getStandardizedSeverity(severityName) || ''
    )
  )
    return severityName
  else return ''
}
export const getColorFromSeverity = (
  severityName: string | undefined,
  severityLevels: Array<SeverityColor>
) => {
  switch (getStandardizedSeverity(severityName)) {
    case 'Low':
      return severityLevels[0].LevelRGB
    case 'Medium':
      return severityLevels[1].LevelRGB
    case 'High':
      return severityLevels[2].LevelRGB
    default:
      return false
  }
}

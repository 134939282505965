// @ts-nocheck
// TODO: Typescript
import React, { Component, Fragment } from 'react'
import { Card, CardContent, CardActions, IconButton } from '@material-ui/core'
import 'moment-timezone'
import { IssueSummaryHeader } from './IssueSummaryHeader'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faClipboardList,
  faComment,
  faUsers,
  faArrowUp,
  faArrowDown,
  faMinus,
  faListUl,
} from '@fortawesome/free-solid-svg-icons'
import { faFilePlus } from '@fortawesome/pro-solid-svg-icons'
import Moment from 'react-moment'
import PropTypes from 'prop-types'
import classes from './IssueSummaryItem.module.scss'
import { isTarget } from '../_utils/domUtils'
import { appDateTimeFormat } from '../_constants'
import { cx } from '../_utils/objectUtils'
import { getLocalDate } from '../_utils/dateUtils'
import Badge from '../Shared/Badge'
import {
  hasNewIssueActivityLog,
  selectIssueById,
  selectIssueTagIds,
  selectIssueTrendId,
  selectOrgIssueTypeById,
} from './_selectors'
import {
  selectIssueChatCount,
  selectIssueCurrentIssueLevel,
  selectIssueDefaultWorkspaceId,
  selectIssueParticipantCount,
  selectIssueTaskCount,
  selectIssueTrendColor,
  selectWorkspaceDirectMessages,
} from '../_rootConfigs/rootSelectors'
import {
  selectWorkspaceChatIds,
  selectWorkspaceTaskIds,
} from '../Workspace/_selectors'
import { selectChatById, selectIsChatUnread } from '../Chat/_selectors'
import { selectIsTaskUnread } from '../Task/_selectors'
import { selectAppAccountDisplayName } from '../AppAccount/_selectors'
import { ampersandCleanup } from '../_utils/stringUtils'
import { isFetchDirectMessagesRequired } from '../Shared/_requestStatusSelectors'
import { fetchDirectMessages } from '../Chat/_actions'

/**
 * @extends {Component<Props, {}>}}
 */

export class IssueSummaryItem extends Component {
  constructor(props) {
    super(props)
    this.goToDetail = this.goToDetail.bind(this)
  }
  componentDidMount() {
    const {
      IssueTeamWorkspaceID,
      isFetchDirectMessagesRequired,
      fetchDirectMessages,
    } = this.props

    if (
      IssueTeamWorkspaceID &&
      isFetchDirectMessagesRequired(IssueTeamWorkspaceID)
    ) {
      fetchDirectMessages({ IssueTeamWorkspaceID })
    }
  }

  renderIssueTrending() {
    const { IssueTrendID, IssueTrendColor } = this.props
    const iconStyle = { color: IssueTrendColor }

    switch (IssueTrendID) {
      case 1: // Getting Better
        return (
          <FontAwesomeIcon
            className={classes.trendingIcon}
            icon={faArrowUp}
            style={iconStyle}
          />
        )
      case 2: // No Change
        return (
          <FontAwesomeIcon
            className={classes.trendingIcon}
            icon={faArrowDown}
            style={iconStyle}
          />
        )
      default:
        return (
          <FontAwesomeIcon
            className={classes.trendingIcon}
            icon={faMinus}
            style={iconStyle}
          />
        )
    }
  }

  render() {
    //https://stackoverflow.com/questions/48936467/material-ui-the-key-provided-to-the-classes-property-is-not-implemented
    const props = this.props
    const {
      className,
      LevelRGB,
      LevelName,
      Description,
      IssueName,
      IssueType,
      IssueTypeRGB,
      IssueTypeMutedRGB,
      IssueTypeDescr,
      IssueCreated,
      IssueResolved,
      compact,
      style,
      showIssueLevel,
      itemRef,
      lastActivityDate,
      showDescription,
      showActions,
      showResolved,
      showResolvedLevelColor,
      showTags,
      tagIds,
      issueNameLines,
      hasNewActivityLog,
      publicChatUnread,
      directChatUnread,
      isTaskUnread,
      ResponsibleName,
      issueOrgName,
      TemplateFlagYN,
    } = props
    const rootStyle = {
      ...style,
      borderLeftColor: LevelRGB,
      backgroundColor: IssueTypeMutedRGB,
    }

    let issueTypeDescrStyle

    if (IssueType !== 'A') issueTypeDescrStyle = { color: IssueTypeRGB }

    if (IssueResolved && showResolved) {
      rootStyle.color = 'lightgray'
      rootStyle.opacity = 0.7

      if (showResolvedLevelColor) rootStyle.borderLeftColor = undefined
    }

    let rootClasses = classes.root

    if (props.selected) {
      rootClasses += ' ' + classes.selected
    }

    return (
      <Card
        onClick={this.goToDetail}
        ref={itemRef}
        className={cx(className, rootClasses)}
        style={rootStyle}
        raised={false}
      >
        <CardContent className={classes.content}>
          <IssueSummaryHeader
            issueNameLines={issueNameLines}
            showIssueLevel={showIssueLevel}
            showDescription={showDescription}
            ResponsibleName={ResponsibleName}
            showResponsibleName={true}
            showTags={showTags}
            tagIds={tagIds}
            {...{
              Description,
              IssueName,
              IssueCreated,
              LevelRGB,
              LevelName,
              compact,
              lastActivityDate,
            }}
          />
        </CardContent>
        {!compact && showActions ? (
          <div className="clearfix">
            <CardActions className={classes.actions} disableSpacing>
              <IconButton
                className={classes.iconButton}
                ref={(el) => (this._chatBtn = el)}
              >
                {
                  <Badge
                    color={directChatUnread ? 'secondary' : 'primary'}
                    overlap="rectangular"
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    invisible={!publicChatUnread && !directChatUnread}
                    variant="dot"
                  >
                    <FontAwesomeIcon
                      icon={faComment}
                      className={classes.icon}
                    />
                  </Badge>
                }
                {/*<span style={countStyle}>{chatCount > countLimit ? countLimit + '+' : chatCount}</span>*/}
              </IconButton>
              <IconButton
                className={classes.iconButton}
                ref={(el) => (this._taskBtn = el)}
              >
                {
                  <Badge
                    color="primary"
                    overlap="rectangular"
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    invisible={!isTaskUnread}
                    variant="dot"
                  >
                    <FontAwesomeIcon
                      className={classes.icon}
                      icon={faClipboardList}
                    />
                  </Badge>
                }
                {/*<span style={countStyle}>{taskCount > countLimit ? countLimit + '+' : taskCount}</span>*/}
              </IconButton>

              <IconButton
                ref={(el) => (this._participantBtn = el)}
                className={classes.iconButton}
              >
                <FontAwesomeIcon className={classes.icon} icon={faUsers} />
                {/*<span style={countStyle}>{participantCount > countLimit ? countLimit + '+' : participantCount}</span>*/}
              </IconButton>
              <IconButton
                className={classes.iconButton}
                ref={(el) => (this._activityLogBtn = el)}
              >
                {
                  <Badge
                    color="primary"
                    overlap="rectangular"
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    invisible={!hasNewActivityLog}
                    variant="dot"
                    classes={{ badge: classes.badge }}
                  >
                    <FontAwesomeIcon className={classes.icon} icon={faListUl} />
                  </Badge>
                }
              </IconButton>
              {this.renderIssueTrending()}
            </CardActions>
            <CardContent className={cx('clearfix', classes.issueStatus)}>
              {IssueResolved && !compact ? (
                <span className={classes.resolvedMessage}>
                  Resolved{' '}
                  <Moment
                    local
                    format={appDateTimeFormat}
                    date={getLocalDate(IssueResolved)}
                  />
                </span>
              ) : (
                <span className={classes.orgName}>{issueOrgName}</span>
              )}
              {IssueType !== 'A' ? (
                <span
                  style={issueTypeDescrStyle}
                  className={cx(classes.typeDescr, classes.iconParent)}
                >
                  {TemplateFlagYN === 'Y' && (
                    <>
                      <FontAwesomeIcon
                        icon={faFilePlus}
                        style={{ filter: 'none' }}
                        className={classes.blueIcon}
                      />
                      &nbsp;&nbsp;
                    </>
                  )}
                  {IssueTypeDescr}
                </span>
              ) : null}
            </CardContent>
          </div>
        ) : (
          <Fragment>
            {IssueResolved && !compact && showResolved ? (
              <CardContent>
                <span className={classes.resolvedMessage}>
                  Resolved on{' '}
                  <Moment
                    local
                    format={appDateTimeFormat}
                    date={getLocalDate(IssueResolved)}
                  />
                </span>
              </CardContent>
            ) : (
              <span className={classes.orgName}>{issueOrgName}</span>
            )}
          </Fragment>
        )}
        {IssueResolved && (
          <CardContent className={classes.orgCard}>
            <span className={classes.orgName}>{issueOrgName}</span>
          </CardContent>
        )}
      </Card>
    )
  }

  goToDetail(evt) {
    const { IssueID, IssueTeamWorkspaceID, IssueName, onClick } = this.props
    const { _chatBtn, _taskBtn, _participantBtn, _activityLogBtn } = this

    if (onClick) {
      var clickResult = { IssueID, IssueTeamWorkspaceID, IssueName }
      if (isTarget(evt, _chatBtn)) clickResult.domain = 'chats'
      else if (isTarget(evt, _taskBtn)) clickResult.domain = 'tasks'
      else if (isTarget(evt, _participantBtn))
        clickResult.domain = 'participants'
      else if (isTarget(evt, _activityLogBtn))
        clickResult.domain = 'activitylog'

      onClick(clickResult)
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  const { IssueID } = ownProps
  let {
    IssueCreated,
    IssueResolved,
    IssueType,
    Description,
    IssueName,
    lastActivityDate,
    Responsible,
    issueOrgName,
    TemplateFlagYN,
  } = selectIssueById(state, IssueID) || {}
  //7.4 REMOVE
  IssueName = ampersandCleanup(IssueName)
  const { LevelName, LevelRGB } =
    selectIssueCurrentIssueLevel(state, IssueID) || {}
  const { IssueTypeMutedRGB, IssueTypeRGB, Descr } =
    selectOrgIssueTypeById(state, IssueType) || {}
  const hasNewActivityLog = hasNewIssueActivityLog(state, IssueID)

  const IssueTeamWorkspaceID = selectIssueDefaultWorkspaceId(state, IssueID)
  const chatIDs = selectWorkspaceChatIds(state, IssueTeamWorkspaceID) || []
  const taskIDs = selectWorkspaceTaskIds(state, IssueTeamWorkspaceID) || []

  const directChatList =
    selectWorkspaceDirectMessages(state, IssueTeamWorkspaceID) || []

  let directChatUnread = false
  for (let i = 0; i < directChatList.length; i++) {
    let isChatUnread = selectIsChatUnread(state, directChatList[i]?.ChatID)
    if (isChatUnread) {
      directChatUnread = true
    }
  }

  const chats = chatIDs.filter((id) => selectChatById(state, id) !== undefined)

  //Public chat will always be lowest number
  if (chats.length > 1) {
    chats.sort()
  }

  const publicChatUnread = selectIsChatUnread(
    state,
    chats.length > 0 ? chats[0] : {}
  )

  return {
    IssueCreated,
    IssueResolved,
    Description,
    lastActivityDate,
    IssueName,
    LevelName,
    LevelRGB,
    IssueType,
    IssueTypeMutedRGB,
    IssueTypeRGB,
    IssueTypeDescr: Descr,
    IssueTrendID: selectIssueTrendId(state, IssueID),
    IssueTrendColor: selectIssueTrendColor(state, IssueID),
    IssueTeamWorkspaceID,
    chatCount: selectIssueChatCount(state, IssueID),
    taskCount: selectIssueTaskCount(state, IssueID),
    publicChatUnread: publicChatUnread,
    directChatUnread: directChatUnread,
    isTaskUnread: selectIsTaskUnread(state, taskIDs[0]),
    participantCount: selectIssueParticipantCount(state, IssueID),
    tagIds: selectIssueTagIds(state, IssueID),
    hasNewActivityLog,
    ResponsibleName: selectAppAccountDisplayName(state, Responsible),
    issueOrgName,
    TemplateFlagYN,
    isFetchDirectMessagesRequired: (IssueTeamWorkspaceID) =>
      isFetchDirectMessagesRequired(state, IssueTeamWorkspaceID),
  }
}

const mapDispatchToProps = {
  fetchDirectMessages: (params: any) =>
    fetchDirectMessages.request(params, {
      icoRequestId: params.IssueTeamWorkspaceID,
    }),
}

IssueSummaryItem.propTypes = {
  LevelRGB: PropTypes.string,
  LevelName: PropTypes.string,
  Description: PropTypes.string,
  showTags: PropTypes.bool,
  showDescription: PropTypes.bool,
  IssueName: PropTypes.string,
  IssueTrendID: PropTypes.number,
  IssueTrendColor: PropTypes.string,
  IssueCreated: PropTypes.instanceOf(Date),
  IssueResolved: PropTypes.instanceOf(Date),
  IssueTypeMutedRGB: PropTypes.string,
  chatCount: PropTypes.number,
  participantCount: PropTypes.number,
  taskCount: PropTypes.number,
  IssueID: PropTypes.number.isRequired,
  IssueTeamWorkspaceID: PropTypes.number,
  onClick: PropTypes.func,
  showActions: PropTypes.bool,
  showResolved: PropTypes.bool,
  showResolvedLevelColor: PropTypes.bool,
  compact: PropTypes.bool,
  tagIds: PropTypes.arrayOf(PropTypes.number),
  issueNameLines: PropTypes.number,
  fetchDirectMessages: PropTypes.func,
}

IssueSummaryItem.defaultProps = {
  style: {},
  showTags: false,
  showIssueLevel: true,
  showDescription: true,
  showActions: true,
  showResolved: true,
  showResolvedLevelColor: false,
  compact: false,
  tagIds: [],
  issueNameLines: 2,
}

export default connect(mapStateToProps, mapDispatchToProps)(IssueSummaryItem)

import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { ResponsiveDialog } from '../Shared/ResponsiveDialog'
import { SlideLeft } from '../Shared/Transition'
import { DialogContent, Toolbar } from '@material-ui/core'
import { HeaderNavigation } from '../Shared/HeaderNavigation'
import { Spacer } from '../Shared/Spacer'
import Checkbox from '../CustomMaterial/Checkbox'
import { Button } from '../Shared/Buttons'
import { useAppDispatch, useAppSelector } from '../_utils/reactReduxHooks'
import { selectAlertFilters } from './_selectors'
import { Statuses, AlertFilter as AlertFilterModel } from './_models'
import { alertFilterSave } from './_actions'
import { selectInboxNamesWithGUIDs } from './_selectors'
import { useParams } from 'react-router'
import { AlertsRouteParams } from '../Routing/RouteProps'

interface Props {
  isOpen: boolean
  onCancel: () => void
}

export interface FilterStatus {
  [Status: string]: boolean
}
export const initialStatuses: FilterStatus = {
  New: true,
  'Reviewed, No Action Taken': true,
  Pinned: true,
  Shared: true,
  'Connected To Issue': false,
}

export const AlertFilter = ({ isOpen, onCancel }: Props) => {
  const [statuses, setStatuses] = useState<FilterStatus>(initialStatuses)
  const [selectedInboxes, setSelectedInboxes] = useState<Array<string>>([])
  const dispatch = useAppDispatch()
  const alertFilters = useAppSelector(selectAlertFilters)
  const inboxList = useAppSelector(selectInboxNamesWithGUIDs)
  const { inboxGUID } = useParams<AlertsRouteParams>()
  const isCompiledInbox = useMemo(
    () => inboxGUID === 'CombinedAlerts',
    [inboxGUID]
  )
  useEffect(() => {
    let newSelectedStatuses = { ...initialStatuses }
    Object.entries(initialStatuses).forEach(([status, _]) => {
      newSelectedStatuses[status] = alertFilters.statuses.includes(status)
    })
    setStatuses(newSelectedStatuses)
    setSelectedInboxes(
      alertFilters.selectedInboxes || alertFilters.InboxesHidden || []
    )
  }, [alertFilters])

  const handleStatusClick = useCallback((status: Statuses) => {
    setStatuses((prev) => ({
      ...prev,
      [status]: !prev[status],
    }))
  }, [])

  const handleSave = useCallback(() => {
    let newFilter: AlertFilterModel = {
      ...alertFilters,
      statuses: Object.entries(statuses)
        .filter(([_, checked]) => checked)
        .map(([status, _]) => status),
      selectedInboxes: selectedInboxes,
    }
    dispatch(alertFilterSave(newFilter))
    onCancel()
  }, [statuses, dispatch, selectedInboxes, onCancel, alertFilters])

  const handleCancel = useCallback(() => {
    onCancel()
  }, [onCancel])
  const handleInboxClick = useCallback(
    (inboxGUID: string) => {
      let newSelectedInboxes = [...selectedInboxes]
      if (selectedInboxes?.includes(inboxGUID)) {
        newSelectedInboxes.splice(selectedInboxes?.indexOf(inboxGUID), 1)
      } else {
        newSelectedInboxes.push(inboxGUID)
      }
      setSelectedInboxes(newSelectedInboxes)
    },
    [selectedInboxes]
  )

  const renderContent = useCallback(() => {
    return (
      <div style={{ position: 'relative' }}>
        <h4>Include the Following Alerts</h4>
        <Spacer spaceParam={'large'} />
        {Object.entries(statuses).map(([status, checked], index) => {
          return (
            <div
              onClick={() => handleStatusClick(status)}
              key={index}
              style={{
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'row',
              }}
            >
              <Checkbox checked={checked} />
              &nbsp;&nbsp;
              {status}
            </div>
          )
        })}
        <Spacer spaceParam={'large'} />
        {isCompiledInbox && (
          <>
            <h4>Include the Following Inboxes</h4>
            {inboxList.map((item, index) => {
              return (
                <div
                  onClick={() => handleInboxClick(item.inboxGUID)}
                  style={{
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'row',
                  }}
                  key={index}
                >
                  <Checkbox
                    checked={selectedInboxes?.includes(item.inboxGUID)}
                  />
                  &nbsp;&nbsp;
                  {item.inboxName}
                </div>
              )
            })}
          </>
        )}
      </div>
    )
  }, [
    handleStatusClick,
    handleInboxClick,
    selectedInboxes,
    inboxList,
    statuses,
    isCompiledInbox,
  ])

  return (
    <ResponsiveDialog
      open={isOpen}
      onClose={handleCancel}
      TransitionComponent={SlideLeft as any}
    >
      <Toolbar>
        <HeaderNavigation
          title={'Display Options'}
          onNavClick={handleCancel as any}
          canGoBack={false}
        />
      </Toolbar>
      <DialogContent>{renderContent()}</DialogContent>
      <div
        style={{
          position: 'sticky',
          left: '0',
          bottom: '0',
          width: '100%',
          backgroundColor: 'white',
          display: 'flex',
          justifyContent: 'flex-end',
          padding: '0.5rem',
        }}
      >
        <Button color="standard" outline onClick={handleCancel}>
          Cancel
        </Button>
        <Spacer />
        <Button color="primary" onClick={handleSave}>
          Save
        </Button>
      </div>
    </ResponsiveDialog>
  )
}

import React, { useState, PropsWithChildren } from 'react'
import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core'
import classes from './StakeholderDetail.module.scss'
import { useIcoFetch, useIcoPut } from '../_utils/fetchUtils'
import { StakeholderPlaybooks } from './StakeholderPlaybooks'
import { StakeholderMembers } from './StakeholderMembers'
import { faClipboard } from '@fortawesome/pro-light-svg-icons'
import { faCheck } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { selectStakeholderById } from '../_rootConfigs/rootSelectors'
import { AdvisoryMUI } from './Icons'
import { useAppSelector } from '../_utils/reactReduxHooks'
import { useIsMounted } from '../_utils/hooks'
import { StretchLayout, StretchBody } from '../Layouts/StretchLayout'
import { Spacer } from '../Shared/Spacer'
import { cx } from '../_utils/objectUtils'
import dayjs from 'dayjs'
import { selectCurrentStakeholderID } from './_selectors'
import { Stakeholder } from './_reducer'
import { selectIssueOrgName } from '../_rootConfigs/rootSelectors'
import dayjsPluginUTC from 'dayjs/plugin/utc'
import relativeTime from 'dayjs/plugin/relativeTime'
import { LoadingBackDrop } from '../Shared/LoadingBackdrop'
import { Button } from '../Shared/Buttons'
import { useHistory } from 'react-router'
import { selectIssueOrgID } from '../Issue/_selectors'
import { ReactComponent as SendNotificationsIcon } from '../_images/sendNotifications.svg'

dayjs.extend(dayjsPluginUTC)
dayjs.extend(relativeTime)

interface IStakeholderDetailProps {
  mobile: boolean //
  IssueID: number
  handleOpenAdvisory: () => void
  workspaceID: number
}

export const StakeholderDetail = ({
  mobile = false,
  IssueID,
  handleOpenAdvisory,
  workspaceID,
}: IStakeholderDetailProps) => {
  const [isPlaybooksOpen, setIsPlaybooksOpen] = useState<boolean>(false)

  const [playbooksSelectedList, setPlaybooksSelectedList] = useState<string[]>(
    []
  )
  const [editMode, setEditMode] = useState<boolean>(false)
  const [communicationType, setCommunicationType] = useState<
    'notification' | 'playbooks' | 'poll' | string
  >('')
  const currentStakeholderID = useAppSelector(selectCurrentStakeholderID)
  const stakeholderDetails: Stakeholder = useAppSelector((state) =>
    selectStakeholderById(
      state,
      currentStakeholderID ? currentStakeholderID : null
    )
  )
  const isMounted = useIsMounted()

  const history = useHistory()

  const OrgName = useAppSelector((state) => selectIssueOrgName(state, IssueID))
  const OrgID = useAppSelector((state) => selectIssueOrgID(state, IssueID))

  useIcoFetch(`/api/Stakeholders/${currentStakeholderID}/Playbooks`, {
    required: [currentStakeholderID],
    defaultData: [],
    onData: (data) => {
      if (
        isMounted() &&
        data.data &&
        data.data.PubTree &&
        data.data.PubTree[0] &&
        data.data.PubTree[0].plans.length > 0
      ) {
        const selectedPlaybooks = data.data.PubTree[0].plans
          .filter((item: any) => item.isSelected && item.isSelected === 'Y')
          .map((plan: any) => plan.PubTitle)
        setPlaybooksSelectedList(selectedPlaybooks)
      }
    },
    tracking: [isPlaybooksOpen],
  })

  const membersCountAndPhoneCheck = useIcoPut(
    `/api/Stakeholders/membersCountAndPhoneCheck`,
    { StakeholderID: currentStakeholderID, OrgID: OrgID },
    {
      required: [currentStakeholderID, OrgID],
      defaultData: [],
      tracking: [communicationType],
    }
  )

  const handleCommunicationChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCommunicationType((event.target as HTMLInputElement).value)
  }
  const CommunicationTypeSelection = (
    <RadioGroup
      aria-labelledby="lblNetworkService"
      name="rbgNetworkService"
      id="rbgNetworkService"
      value={communicationType}
      onChange={handleCommunicationChange}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
        }}
      >
        <FormControlLabel
          value="playbooks"
          style={{ marginLeft: '0', marginBottom: '0' }}
          control={
            <Radio color="primary" style={{ padding: '4px 4px 4px 0px' }} />
          }
          label="Assign Playbooks"
        />
        <FormControlLabel
          value="notification"
          style={{ marginLeft: '0', marginBottom: '0' }}
          control={
            <Radio color="primary" style={{ padding: '4px 4px 4px 0px' }} />
          }
          label="Send a Notification"
        />
      </div>
    </RadioGroup>
  )

  const AdvisoryButton = (
    <Button
      icon={<AdvisoryMUI className={classes.mediumIcon} />}
      onClick={handleOpenAdvisory}
    >
      Advisory
    </Button>
  )

  const PlaybooksButton = (
    <Button
      icon={<FontAwesomeIcon icon={faClipboard} />}
      onClick={() => setIsPlaybooksOpen(true)}
    >
      Playbooks
    </Button>
  )

  const AdvisoryPublished = stakeholderDetails?.PubID ? (
    <span style={{ fontSize: '0.8rem' }}>Advisory Published</span>
  ) : null
  const AdvisoryStarted = stakeholderDetails?.advisory_started ? (
    <span className={classes.colorGold} style={{ fontSize: '0.8rem' }}>
      Advisory Unfinished
    </span>
  ) : null
  const AdvisoryNotCreated = (
    <span className={classes.colorRed} style={{ fontSize: '0.8rem' }}>
      No Advisory
    </span>
  )

  return (
    <StretchLayout>
      <Spacer spaceParam={'large'} />
      {currentStakeholderID ? (
        <>
          <StretchBody style={{ paddingRight: '1rem' }}>
            <SectionHeading number={1}>
              Who are the stakeholders?
            </SectionHeading>
            <SectionBody>
              <StakeholderMembers
                StakeholderID={currentStakeholderID}
                mobile={mobile}
                IssueID={IssueID}
                editMode={editMode}
                workspaceID={workspaceID}
                setEditMode={setEditMode}
              />
            </SectionBody>
            {!editMode && (
              <>
                <SectionHeading number={2}>
                  What type of communication?
                </SectionHeading>
                <SectionBody>{CommunicationTypeSelection}</SectionBody>

                {communicationType === 'playbooks' && (
                  <>
                    <SectionHeading number={3}>
                      Create an Advisory and/or assign Playbooks
                    </SectionHeading>
                    <SectionBody>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'flex-start',
                        }}
                      >
                        {AdvisoryButton}
                        <Spacer spaceParam={'large'} />
                        {PlaybooksButton}
                      </div>
                    </SectionBody>
                  </>
                )}
                {communicationType === 'notification' && (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      flexGrow: '1',
                      padding: '0 1rem',
                    }}
                  >
                    <Button
                      style={{ width: 'fit-content' }}
                      color="standard"
                      outline
                      onClick={() =>
                        history.push(
                          `./sendNotifications/sendNotification/${currentStakeholderID}`
                        )
                      }
                    >
                      <SendNotificationsIcon height="20px" width="20px" /> Send
                      Notifications
                    </Button>
                    {membersCountAndPhoneCheck.data.membersCount === 0 &&
                      !membersCountAndPhoneCheck.data
                        .hasMSTeamsChannelSelected && (
                        <span
                          style={{ fontStyle: 'italic', fontSize: '0.8rem' }}
                        >
                          Must add members before sending to this Stakeholder
                        </span>
                      )}
                  </div>
                )}

                {communicationType === 'playbooks' && (
                  <>
                    <SectionHeading number={4}>Review</SectionHeading>
                    <SectionBody>
                      {communicationType === 'playbooks' && (
                        <div
                          className={cx(
                            classes.flexAlignCenter,
                            'd-flex flex-column justify-content-start align-items-start'
                          )}
                          style={{ width: '100%' }}
                        >
                          {stakeholderDetails?.PubID !== undefined ? (
                            <div
                              className={cx(
                                'd-flex flex-row justify-content-start align-items-center'
                              )}
                            >
                              <span style={{ fontSize: '0.8rem' }}>
                                Advisory Published
                              </span>
                            </div>
                          ) : (
                            <div
                              className={cx(
                                'd-flex flex-row justify-content-start align-items-center'
                              )}
                            >
                              {AdvisoryPublished
                                ? AdvisoryPublished
                                : AdvisoryStarted
                                ? AdvisoryStarted
                                : AdvisoryNotCreated}
                            </div>
                          )}
                          {stakeholderDetails?.PubID && (
                            <ul style={{ listStyleType: 'none' }}>
                              {stakeholderDetails?.PubID && (
                                <li
                                  className={classes.blueColor}
                                  style={{ fontSize: '0.8rem' }}
                                >
                                  Published:{' '}
                                  {dayjs
                                    .utc(
                                      stakeholderDetails?.advisory_published_date
                                    )
                                    .local()
                                    .format('MMM DD, YYYY')}
                                </li>
                              )}

                              {stakeholderDetails?.PubID && (
                                <li
                                  className={classes.blueColor}
                                  style={{ fontSize: '0.8rem' }}
                                >
                                  Valid Until:{' '}
                                  {dayjs(
                                    stakeholderDetails?.advisory_valid_until
                                  ).format('MMM DD, YYYY')}
                                </li>
                              )}
                            </ul>
                          )}

                          {playbooksSelectedList.length > 0 ? (
                            <>
                              <div
                                className={cx(
                                  'd-flex flex-row justify-content-start align-items-center'
                                )}
                              >
                                <span style={{ fontSize: '0.8rem' }}>
                                  Playbooks Assigned
                                </span>
                                <Spacer />
                                <FontAwesomeIcon icon={faCheck} />
                              </div>
                              <ul className={classes.blueColor}>
                                {playbooksSelectedList.length > 0 &&
                                  playbooksSelectedList.map(
                                    (planName, index) => {
                                      return (
                                        <li
                                          key={index}
                                          style={{ fontSize: '0.8rem' }}
                                        >
                                          {planName}
                                        </li>
                                      )
                                    }
                                  )}
                              </ul>
                            </>
                          ) : (
                            <div style={{ fontSize: '0.8rem' }}>
                              No Playbooks Assigned
                            </div>
                          )}
                        </div>
                      )}
                    </SectionBody>
                  </>
                )}
              </>
            )}

            <StakeholderPlaybooks
              IssueStakeholderID={currentStakeholderID}
              Audience={stakeholderDetails?.Audience}
              className={classes.membersDialog}
              open={isPlaybooksOpen}
              onClose={() => setIsPlaybooksOpen(false)}
              classes={{
                content: classes.membersDialogContent,
              }}
              mobile={mobile}
              OrgName={OrgName}
              IssueID={IssueID}
            />
          </StretchBody>
        </>
      ) : (
        <LoadingBackDrop />
      )}
    </StretchLayout>
  )
}

interface SectionHeadingProps {
  number: number
}

const SectionHeading = ({
  children,
  number,
}: PropsWithChildren<SectionHeadingProps>) => {
  return (
    <div
      style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
    >
      <span className={classes.sectionNumber}>{number}</span>
      <Spacer />
      <span className={classes.sectionTitle}>{children}</span>
    </div>
  )
}

const SectionBody = ({ children }: { children: React.ReactNode }) => {
  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          width: '100%',
        }}
      >
        <Spacer spaceParam={'large'} />
        <div style={{ width: '100%', paddingTop: '8px', paddingBottom: '8px' }}>
          {children}
        </div>
      </div>
      <Spacer spaceParam={'large'} />
    </>
  )
}

import { DialogContent, Radio, TextField } from '@material-ui/core'
import { Toolbar } from '@material-ui/core'
import { CircularProgress } from '@material-ui/core'
import { RadioGroup } from '@material-ui/core'
import { FormControlLabel } from '@material-ui/core'
import { Dialog, withMobileDialog } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import moment from 'moment'
import React, { useEffect, useRef } from 'react'
import { ChangeEvent } from 'react'
import { HeaderNavigation } from './Shared/HeaderNavigation'
import { SlideLeft } from './Shared/Transition'
import { getLocalDate } from './_utils/dateUtils'
import { http } from './_utils/http'
import classes from './AppUserSettings.module.scss'
import { cx } from './_utils/objectUtils'
import { Spacer } from './Shared/Spacer'
import { useAppDispatch } from './_utils/reactReduxHooks'
import { addNotification } from './_actions'
import { Button } from './Shared/Buttons'
import DOMPurify from 'dompurify'

interface Props {
  open: boolean
  onClose: (
    event: {},
    reason: 'backdropClick' | 'escapeKeyDown' | 'userSaved'
  ) => void
}

interface DisplayPrefs {
  Code: string
  Value: string
}

interface CountryEntry {
  CCDescr: string
  CountryAbbr: string
  CountryCode: string
}

const ResponsiveDialog = withMobileDialog()(Dialog)
export const AppUserSettings = ({ open, onClose }: Props) => {
  const [settings, setSettings] = React.useState<any>('')
  const [settingsLoading, setSettingsLoading] = React.useState(true)

  const [settingsChanged, setSettingsChanged] = React.useState(false) // let us know if the user has changed anything

  const [prefsPlaybooks, setPrefsPlaybooks] = React.useState('')
  const [prefsDefaultView, setPrefsDefaultView] = React.useState('')
  const [fullName, setFullName] = React.useState('')
  const [mobilePhone, setMobilePhone] = React.useState('')
  const [mobilePhoneValError, setMobilePhoneValError] = React.useState(false)
  const [countryAbbrev, setCountryAbbrev] = React.useState('')
  const [countryAbbrevValError, setCountryAbbrevValError] =
    React.useState(false)
  const [countryCode, setCountryCode] = React.useState('')
  const [privacyAgree, setPrivacyAgree] = React.useState('')
  const isMount = useRef(true)

  const dispatch = useAppDispatch()

  useEffect(() => {
    if (prefsDefaultView === 'Issues') {
      const objDiv = document.getElementById('defaultIssuesView')
      if (objDiv) objDiv.scrollIntoView()
    }
  }, [prefsDefaultView])

  const getSettings = async (): Promise<any> => {
    const result = await http<any>({
      path: '/user/settings',
    })
    if (result.ok && result.body) {
      return result.body
    } else {
      return ''
    }
  }

  const saveSettings = async (): Promise<any> => {
    var customHeaders: any = []
    if (fullName) {
      customHeaders.push({
        name: 'fullname',
        value: `${encodeURIComponent(fullName)}`,
      })
    }
    if (prefsPlaybooks) {
      customHeaders.push({
        name: 'prefsplaybooks',
        value: `${prefsPlaybooks}`,
      })
    }
    if (prefsDefaultView) {
      customHeaders.push({
        name: 'prefsdefaultview',
        value: `${prefsDefaultView}`,
      })
    }
    if (mobilePhone) {
      customHeaders.push({
        name: 'mobilephone',
        value: `${mobilePhone}`,
      })
    }
    if (countryAbbrev) {
      customHeaders.push({
        name: 'mobilecountrycode',
        value: `${countryAbbrev}`,
      })
    }
    const result = await http<any>({
      path: '/user/settings',
      headers: customHeaders,
    })
    if (result.ok && result.body) {
      return result.body
    } else {
      return ''
    }
  }

  function updatesettings(s: any) {
    if (s && isMount.current) {
      if (s.AppAccount?.PrefsPlaybooks) {
        setPrefsPlaybooks(s.AppAccount?.PrefsPlaybooks)
      }
      if (s.AppAccount?.PrefsDefaultView) {
        setPrefsDefaultView(s.AppAccount?.PrefsDefaultView)
      }
      if (s.AppAccount?.FullName) {
        setFullName(s.AppAccount?.FullName)
      }
      if (s.AppAccount?.MobilePhone) {
        setMobilePhone(s.AppAccount?.MobilePhone)
      }
      if (s.AppAccount?.MobileCountryCode) {
        setCountryAbbrev(s.AppAccount?.MobileCountryCode)
      }
      if (s.AppAccount?.CountryCode) {
        setCountryCode(s.AppAccount?.CountryCode)
      }
      if (s.AppAccount?.PrivacyAgreedGMT) {
        var spa = s.AppAccount?.PrivacyAgreedGMT
        var spf = spa
          ? moment(getLocalDate(spa)).format('d MMM YYYY  h:mm A').toString()
          : ''
        setPrivacyAgree(spf)
      }
      setSettingsChanged(false)
    }
  }

  React.useEffect(() => {
    const doGetSettings = async () => {
      if (isMount.current) {
        const settings = await getSettings()
        if (isMount.current) {
          setSettings(settings)
          updatesettings(settings)
          setSettingsLoading(false)
        }
      }
    }
    doGetSettings()

    return () => {
      isMount.current = false
    }
  }, [prefsDefaultView, setSettingsLoading, settingsLoading])

  function statusColor() {
    // You should only return the value of the color
    //var retval = 'color: #ffff00;';
    var retval = '#ffff00'
    if (settings) {
      if (settings.AppAccount) {
        var saa = settings.AppAccount
        if (!saa.SMSSubscrbSentGMT) {
          retval = '#ff0000'
        } else if (!saa.SMSAgreedGMT) {
          retval = '#0000ff'
        } else {
          retval = '#008200'
        }
      }
    }
    return retval
  }

  function handlePhoneChange(evt: ChangeEvent<HTMLInputElement>) {
    setMobilePhone(evt.target.value)
    let mobilePhoneRegex =
      /^\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/
    let hasInput = evt.target.value.length > 0
    let isValidNumber = mobilePhoneRegex.test(evt.target.value) || !hasInput
    if (hasInput && countryAbbrev.length === 0) {
      setCountryAbbrevValError(true)
    } else {
      setCountryAbbrevValError(false)
    }
    if (!isValidNumber) {
      setMobilePhoneValError(true)
    } else {
      setMobilePhoneValError(false)
    }
    setSettingsChanged(true)
  }

  function handlePrefsPlaybookChange(evt: any) {
    setPrefsPlaybooks(evt.target.value)
    setSettingsChanged(true)
  }

  function handlePrefsDefaultViewChange(evt: any) {
    setPrefsDefaultView(evt.target.value)
    setSettingsChanged(true)
  }

  function handleFullNameChange(evt: any) {
    let cleanVal = DOMPurify.sanitize(evt.target.value)
    if (DOMPurify.removed.length > 0) {
      dispatch(
        addNotification({
          message: 'Your name cannot be a security risk',
          type: 'error',
        })
      )
      return
    }
    setFullName(cleanVal)
    setSettingsChanged(true)
  }

  function handleCountryCodeChange(evt: any, ent: any) {
    var selCountry: CountryEntry = ent
    if (ent) {
      setCountryAbbrev(selCountry.CountryAbbr)
      setCountryCode(selCountry.CountryCode)
      setSettingsChanged(true)
    } else {
      setCountryAbbrev('')
      setCountryCode('')
      setSettingsChanged(true)
    }
    setCountryAbbrevValError(false)
  }

  function handleSaveButton() {
    if (settingsChanged) {
      if (mobilePhone.length > 0 && countryAbbrev.length === 0) {
        setCountryAbbrevValError(true)
        dispatch(
          addNotification({
            type: 'error',
            message: 'Must add a country if adding a phone number',
          })
        )
      } else if (mobilePhoneValError) {
        dispatch(
          addNotification({
            type: 'error',
            message: 'Phone number must be valid',
          })
        )
      } else {
        const doSaveSettings = async () => {
          setSettingsLoading(true)
          const settings = await saveSettings()

          onClose({}, 'userSaved')
          setSettingsLoading(false)
          if (settings) {
            setSettings(settings)
            updatesettings(settings)
          }
        }
        doSaveSettings()
      }
    }
  }

  function render() {
    if (settingsLoading) {
      return (
        <>
          <CircularProgress />
        </>
      )
    }

    return (
      <div>
        <span className={classes.title}>User Settings</span>
        <span className={classes.titlePrompt}>
          Please fill out the fields below to recieve important text
          communications from your organization.
        </span>
        <Spacer spaceParam={'large'} />
        <div>
          <label className={classes.inputLabel}>Email Address</label>
          <input
            readOnly
            className={classes.email}
            disabled
            type="text"
            name="display_email_address"
            id="display_email_address"
            value={settings.AppAccount?.email_address}
          />
        </div>
        <Spacer spaceParam={'large'} />
        <div>
          <label className={classes.inputLabel}>Name</label>
          <input
            className={classes.enabledInputs}
            style={{ marginBottom: 'none' }}
            type="text"
            name="FullName"
            id="FullName"
            value={fullName}
            onChange={handleFullNameChange}
          />
          <span className={classes.helpText}>
            This name will be displayed when connected to Secure Chats, Shared
            Task Lists, and Issues within the app.
          </span>
        </div>
        <Spacer spaceParam={'large'} />
        <div>
          <div id="Country-Code-Select">
            <label className={classes.inputLabel}>Country</label>
            <div
              style={
                countryAbbrevValError
                  ? {
                      boxShadow: '0 0 1px 1px red',
                      border: '1px transparent solid',
                    }
                  : {}
              }
            >
              <Autocomplete
                id="combo-box-country"
                options={settings.CountryCodes}
                getOptionLabel={(option: CountryEntry) =>
                  option?.CCDescr ? option.CCDescr : ''
                }
                getOptionSelected={(option) => !!option}
                onChange={handleCountryCodeChange}
                renderInput={(params) => <TextField {...params} />}
                value={
                  settings.CountryCodes?.find(
                    (v: CountryEntry) => v.CountryAbbr === countryAbbrev
                  ) || {}
                } // since we have objects in our options array, this needs to be a object as well
              />
            </div>
          </div>
        </div>
        <Spacer spaceParam={'large'} />
        <div>
          <label className={classes.inputLabel}>Mobile Phone #</label>
          <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
            <span
              className={classes.countryBox}
              style={{ color: statusColor() }}
              id="spanCountryCode"
            >
              {'+' + countryCode}
            </span>

            {/*Style takes an object literal (key value pair) that includes the css properties)*/}
            {/*statusColor is a function and should be called so you get the return value*/}
            {/*All input fields should have an onChange event that updates the value. Without an onChange the user won't be able to change the value of the field */}
            <input
              type="tel"
              name="MobilePhone"
              id="MobilePhone"
              className={classes.enabledInputs}
              style={
                mobilePhoneValError
                  ? {
                      boxShadow: '0 0 1px 1px red',
                      border: '1px transparent solid',
                      color: statusColor(),
                    }
                  : { color: statusColor() }
              }
              value={mobilePhone}
              onChange={handlePhoneChange}
              color="blue"
            />
          </div>
          <div className={classes.helpText}>
            Enter your phone number and reply to the opt in text from In Case of
            Crisis to receive text messages from your organization.
          </div>
        </div>
        <Spacer spaceParam={'large'} />
        <div>
          <label className={classes.inputLabel}>
            Privacy Agreement Accepted
          </label>
          <input
            type="text"
            readOnly
            className={classes.FormControlPlaintext}
            name="display_PrivacyAgreedGMT"
            id="display_PrivacyAgreedGMT"
            value={privacyAgree}
          />
        </div>
        <br />
        <h4 className={classes.title} style={{ fontSize: '20px' }}>
          User Preferences
        </h4>
        <br />
        <div>
          <label className="form-label">Playbook Display Preferences</label>

          <RadioGroup defaultValue="d" aria-label="sortby" name="sortby">
            {/*
             * v is implicit type any. Explicitly declare it as any.
             * A better solution is to define an interface for v
             */}
            {settings.pick_PrefsPlaybooks?.map((v: DisplayPrefs) => {
              return (
                <FormControlLabel
                  id={'ppb' + v.Code}
                  key={'ppb' + v.Code}
                  value={v.Code}
                  control={
                    <Radio
                      className={classes.MuiIconButtonColorSecondary}
                      style={{
                        paddingTop: 0,
                        paddingBottom: 0,
                        paddingLeft: '20px',
                      }}
                    />
                  }
                  label={v.Value}
                  checked={prefsPlaybooks === v.Code}
                  onChange={handlePrefsPlaybookChange}
                />
              )
            })}
          </RadioGroup>
        </div>
        <br />
        <div>
          <label className="form-label">Default View Preferences</label>
          <RadioGroup defaultValue="d" aria-label="sortby" name="sortby">
            {/*
             * v is implicit type any. Explicitly declare it as any.
             * A better solution is to define an interface for v
             */}
            {settings.pick_PrefsDefaultView?.map((v: DisplayPrefs) => {
              return (
                <FormControlLabel
                  id={'pdv' + v.Code}
                  key={'pdv' + v.Code}
                  value={v.Code}
                  control={
                    <Radio
                      className={classes.MuiIconButtonColorSecondary}
                      style={{
                        paddingTop: 0,
                        paddingBottom: 0,
                        paddingLeft: '20px',
                      }}
                    />
                  }
                  label={v.Value}
                  checked={prefsDefaultView === v.Code}
                  onChange={handlePrefsDefaultViewChange}
                />
              )
            })}
          </RadioGroup>
        </div>
        <div>
          <Button
            className={cx('p-2', classes.btnIcc)}
            disabled={!settingsChanged}
            variant={'primary' as any}
            onClick={handleSaveButton}
          >
            Save
          </Button>
        </div>
      </div>

      //	<pre>{JSON.stringify(settings, null, 2)}</pre>
    )
  }

  return (
    <ResponsiveDialog
      open={open}
      onClose={onClose}
      TransitionComponent={SlideLeft as any}
    >
      <Toolbar>
        <HeaderNavigation
          title=""
          onNavClick={onClose as any}
          canGoBack={false}
        />
      </Toolbar>
      <DialogContent id="settingsContainter">{render()}</DialogContent>
    </ResponsiveDialog>
  )
}

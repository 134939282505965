// @ts-nocheck
// TODO: Typescript
import React, { useState } from 'react'
import { login, loginClearStatus } from './_actions'
import { prefixSelectorWithApp } from '../_utils/domUtils'
import { AuthMasterLayout } from './AuthMasterLayout'
import { Redirect } from 'react-router-dom'
import { Button } from 'reactstrap'
import { TextField } from '../Shared/TextField'
import { icoPost } from '../_utils/fetchUtils'
import { cx, isEmail } from '../_utils/objectUtils'
import classes from './LoginForm.module.scss'
import { Spacer } from '../Shared/Spacer'
import { useAppDispatch, useAppSelector } from '../_utils/reactReduxHooks'
import { useHistory } from 'react-router'
import { selectInitialView } from '../_rootConfigs/rootSelectors'
import { selectIsAppInitialDataLoaded } from '../Shared/_requestStatusSelectors'
import { isUserAuthenticated } from './_selectors'

/**
 * @extends {Component<Props, {}>}}
 */
const TryAnotherWayPageExport = (props) => {
  const { userEmailAddress } = (props.location || {}).state || {}
  const [email, setEmail] = useState(userEmailAddress || '')
  const [authCode, setAuthCode] = useState('')
  const [error, setError] = useState('')
  const [isEmailSubmitted, setIsEmailSubmitted] = useState(false)
  const [isCodeSent, setIsCodeSent] = useState(false)
  const dispatch = useAppDispatch()
  //const [backFromCookieAuth, setBackFromCookieAuth] = useState(false)
  //const [signinReq, setSigninReq] = useState()
  const history = useHistory()

  const isAppInitialDataLoaded = useAppSelector(selectIsAppInitialDataLoaded)

  const handleCancelClick = () => {
    setError('')
    history.push({
      pathname: '/login',
    })
  }

  const handleSubmitPwResetClick = () => {
    setError('')
    const cleanedEmail = (email || '').trim()
    if (cleanedEmail && !isEmail(cleanedEmail)) {
      setError('Invalid email entered')
    } else if (cleanedEmail) {
      icoPost('/api/Password/NewRequest', { email: cleanedEmail })
        .then((response) => response.json())
        .then((data) => {
          if (!data.ErrorMessage) setIsEmailSubmitted(true)
          else setError(data.ErrorMessage)
        })
        .catch(() => {
          setError('Password reset email not sent.')
        })
    } else setError("User's email is required")
  }

  const handleSubmitSendViaText = () => {
    setError('')
    const cleanedEmail = (email || '').trim()
    if (cleanedEmail && !isEmail(cleanedEmail)) {
      setError('Invalid email entered')
    } else if (cleanedEmail) {
      icoPost('/api/Password/SendCode', {
        emailAddress: cleanedEmail,
        sendSMS: 'Y',
        sendPush: 'N',
        sendEmail: 'N',
      })
        .then((response) => response.json())
        .then((data) => {
          if (!data.ErrorMessage) setIsCodeSent(true)
          else setError(data.ErrorMessage)
        })
        .catch(() => {
          setError('Text message not sent.')
        })
    } else setError("User's email is required")
  }

  const handleSubmitSendViaPush = () => {
    setError('')
    const cleanedEmail = (email || '').trim()
    if (cleanedEmail && !isEmail(cleanedEmail)) {
      setError('Invalid email entered')
    } else if (cleanedEmail) {
      icoPost('/api/Password/SendCode', {
        emailAddress: cleanedEmail,
        sendSMS: 'N',
        sendPush: 'Y',
        sendEmail: 'N',
      })
        .then((response) => response.json())
        .then((data) => {
          if (!data.ErrorMessage) setIsCodeSent(true)
          else setError(data.ErrorMessage)
        })
        .catch(() => {
          setError('Push message not sent.')
        })
    } else setError("User's email is required")
  }

  const handleSubmitSendViaEmail = () => {
    setError('')
    const cleanedEmail = (email || '').trim()
    if (cleanedEmail && !isEmail(cleanedEmail)) {
      setError('Invalid email entered')
    } else if (cleanedEmail) {
      icoPost('/api/Password/SendCode', {
        emailAddress: cleanedEmail,
        sendSMS: 'N',
        sendPush: 'N',
        sendEmail: 'Y',
      })
        .then((response) => response.json())
        .then((data) => {
          if (!data.ErrorMessage) setIsCodeSent(true)
          else setError(data.ErrorMessage)
        })
        .catch(() => {
          setError('Email message not sent.')
        })
    } else setError("User's email is required")
  }

  const homePath = '/'
  const initialView = useAppSelector(selectInitialView)
  const isAuthenticated = useAppSelector(isUserAuthenticated)

  // React.useEffect(() => {
  //   //if (isAuthenticated) {
  //   console.log('DRH DEBUG ----------------------')
  //   console.log('DRH DEBUG isAuthenticated', isAuthenticated)
  //   console.log('DRH DEBUG isAppInitialDataLoaded', isAppInitialDataLoaded)
  //   console.log('DRH DEBUG initialView', initialView)
  //   console.log('DRH DEBUG backFromCookieAuth', backFromCookieAuth)
  //   //}
  // }, [isAuthenticated, isAppInitialDataLoaded, initialView, backFromCookieAuth])

  if (isAppInitialDataLoaded && isAuthenticated) {
    //console.log('DRH DEBUG redirecting to ', initialView, homePath)
    return <Redirect to={initialView || homePath} />
  }
  const altLoginUser = (em: string, adg: string) => {
    // console.log('DRH DEBUG starting altLoginUser')
    // const req = { Em: em, Pw: '', Adg: adg }
    // console.log('DRH DEBUG req', JSON.stringify(req, null, 2))
    // setSigninReq(req)
    /*
    dispatch(
      icoPost('/api/CookieAuth/Signin', signinReq).then((res) => {
        console.log(
          'DRH DEBUG alt Login response',

          JSON.stringify(res, null, 2)
        )
        setBackFromCookieAuth(true)
        setTimeout(() => {
          console.log('DRH DEBUG redirecting to Dashboard')
          history.push('/Dashboard/')
        }, 5000)
        //history.push('/')
      })
    )
*/

    dispatch(loginClearStatus())
    //console.log('DRH DEBUG middle altLoginUser')
    dispatch(
      login({
        Em: (em || '').trim(),
        Pw: '',
        Adg: (adg || '').trim(),
      })
    )

    //console.log('DRH DEBUG ending altLoginUser')
  }

  const handleSubmitAuthCode = () => {
    setError('')
    const cleanedEmail = (email || '').trim()
    if (cleanedEmail && !isEmail(cleanedEmail)) {
      setError('Invalid email entered')
    } else if (cleanedEmail) {
      icoPost('/api/Password/AuthAltCode', {
        emailAddress: cleanedEmail,
        authCode: authCode,
      })
        .then((response) => response.json())
        .then((data) => {
          if (!data.ErrorMessage) {
            // this was a successful authorization, set this user up with a session
            // alert('Successful Alt Login')
            //console.log('DRH DEBUG successful alt login')
            //console.log('DRH DEBUG', JSON.stringify(data, null, 2))
            altLoginUser(cleanedEmail, data.AppDevGUID)
          } else setError(data.ErrorMessage)
        })
        .catch(() => {
          setError('Authorization Code was not validated.')
        })
    } else setError("User's email is required")
  }

  const handleEmailChange = (evt) => setEmail(evt.target.value)
  const handleAuthCodeChange = (evt) => setAuthCode(evt.target.value)

  if (isEmailSubmitted)
    return (
      <Redirect
        to={{
          pathname: '/ForgotPasswordEmailSent',
          state: { userEmailAddress: email },
        }}
      />
    )

  /*
  if (isCodeSent)
    return (
      <Redirect
        to={{
          pathname: '/TryAnotherWaySent',
          state: { userEmailAddress: email },
        }}
      />
    )
    */

  function formBeforeCodeSent() {
    return (
      <div className={classes.formContent}>
        {' '}
        <TextField
          label="Email Address"
          type="text"
          name="em"
          id={prefixSelectorWithApp('client-em')}
          autoFocus={true}
          value={email}
          onChange={handleEmailChange}
          fullWidth
        />
        <Spacer spaceParam={'large'} />
        <Button
          color="darker"
          style={{ width: '267px' }}
          onClick={handleSubmitSendViaText}
        >
          Send Code via Text Message
        </Button>
        <Spacer spaceParam={'large'} />
        <Button
          color="darker"
          style={{ width: '267px' }}
          outline
          onClick={handleSubmitSendViaPush}
        >
          Send Code to Mobile App
        </Button>
        <Spacer spaceParam={'large'} />
        <Button
          color="darker"
          style={{ width: '267px' }}
          outline
          onClick={handleSubmitSendViaEmail}
        >
          Send Code via Email
        </Button>
        <Spacer spaceParam={'large'} />
        <Button color="link" onClick={handleSubmitPwResetClick}>
          <i className={classes.forgotPassword}>Send Password Reset Email</i>
        </Button>
        <Spacer spaceParam={'large'} />
        <Button color="link" onClick={handleCancelClick}>
          <i className={classes.forgotPassword}>Cancel</i>
        </Button>
      </div>
    )
  }

  function formAfterCodeSent() {
    return (
      <div className={classes.formContent}>
        Email: <b>{email}</b>
        <br />
        If the email address given refers to an active account, you will receive
        a code.
        <br />
        Please enter that code here:
        <br />
        <TextField
          label="Authorization Code"
          type="text"
          name="authCode"
          autoFocus={true}
          value={authCode}
          onChange={handleAuthCodeChange}
        />
        <Spacer spaceParam={'large'} />
        <Button color="darker" onClick={handleSubmitAuthCode}>
          Use Code
        </Button>
        <Spacer spaceParam={'large'} />
        <Button color="link" onClick={handleCancelClick}>
          <i className={classes.forgotPassword}>Cancel</i>
        </Button>
      </div>
    )
  }

  return (
    <AuthMasterLayout
      {...props}
      id={prefixSelectorWithApp('TryAnotherWayPage')}
      showAlert={error ? true : false}
      alertText={error}
      alertColor="danger"
    >
      <div
        className={cx(
          classes.form,
          classes.formBackground,
          classes.formContent
        )}
      >
        <h5>Try Another Way</h5>
        <Spacer spaceParam={'large'} />
        <Spacer spaceParam={'large'} />
        {isCodeSent ? formAfterCodeSent() : formBeforeCodeSent()}
      </div>
    </AuthMasterLayout>
  )
}

export default TryAnotherWayPageExport

import React from 'react'
import { faGreaterThan } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Organization } from '../Issue/_reducer'
import classes from './AdminConsolePage.module.scss'

const AdminConsoleOrgSelection = ({
  onSelection,
  adminOrgs,
  actionText = 'administer:',
}: {
  onSelection: (org: Organization) => void
  adminOrgs: Organization[]
  actionText?: 'administer:' | 'Send Notification'
}) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <div className={classes.OrganizationHeader}>Organization</div>
      <div className={classes.OrganizationSelectionDescription}>
        You are an Issues Administrator on a Team in the following
        Organizations. Please Select which organization to {actionText}
      </div>
      <div className={classes.OrganizationSelectionContainer}>
        {adminOrgs.map((org: Organization) => {
          return (
            <div
              key={org.OrgID}
              className={classes.OrganizationName}
              onClick={() => {
                onSelection(org)
              }}
            >
              {org.OrgName}
              <FontAwesomeIcon icon={faGreaterThan} color="blue" />
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default AdminConsoleOrgSelection

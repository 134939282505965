import * as React from 'react'
//import { isNamespaceExport } from 'typescript';
//import moment from 'moment';
import { http } from '../_utils/http'
import { IncidentReportForm } from './IncidentReportForm'
import { DiagramView } from './DiagramView'
import { ContactsView } from './ContactsView'
import { HiddenFormWithValues } from './HiddenFormWithValues'
import { AltAction } from './AltAction'
import { PbSubmenu } from './PbSubmenu'
import { PollStart } from './PollStart'
import { BreadCrumbProps, BreadCrumbs } from '../Routing/BreadCrumbs'
import {
  StretchLayout,
  StretchHeader,
  StretchBody,
} from '../Layouts/StretchLayout'
import classes from './Playbooks.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faAddressBook,
  faExclamationTriangle,
} from '@fortawesome/free-solid-svg-icons'
import { faTimes } from '@fortawesome/pro-light-svg-icons'
import { CircularProgress } from '../Shared/LoadingBackdrop'
import { DiagramButton } from './DiagramButton'
import { UncontrolledTooltip } from 'reactstrap'
import {
  useBookmarkFixCallback,
  useIsMounted,
  useWebLinkFixCallback,
} from '../_utils/hooks'
// import InnerHTML from 'dangerously-set-html-content'

interface Props {
  PubGUID?: string // used for links
  EvSeq?: string // used for links
  EventID?: number
  EventName?: string
  className?: string
  onDetailLoaded?(event: any, isSubmenu: boolean): void
  isChild?: boolean
  onPlaybookLoaded?(event: any): void
}

interface EventParams {
  EvSeq?: string // used for links
  PubGUID?: string
  RefPubGUID?: string
  EventID?: number
  adg?: string
}

export const PbEvDetail = ({
  PubGUID,
  EvSeq,
  EventID,
  EventName,
  className,
  onPlaybookLoaded,
  isChild,
}: Props) => {
  const [PlaybookLoading, setPlaybookLoading] = React.useState(true)
  const [IncidentReportVisible, setIncidentReportVisible] =
    React.useState(false)
  const [DiagramVisible, setDiagramVisible] = React.useState(false)
  const [ContactsVisible, setContactsVisible] = React.useState(false)
  const [playbookList, setPlaybookList] = React.useState<any[]>([])
  const [breadCrumbsCache, setBreadCrumbsCache] = React.useState<
    BreadCrumbProps[]
  >([])
  // Needed because the link events happen in a separate callback
  const currentPlaybook = React.useRef()
  const isMounted = useIsMounted()
  //const [curLinkToPG, setCurLinkToPG] = React.useState<string>();
  //const [curLinkToEV, setCurLinkToEV] = React.useState<string>();

  const bookmarkRef = useBookmarkFixCallback()
  const webLinkRef = useWebLinkFixCallback({
    openPlan: (href) => {
      //console.log('do plan');
      //console.log(href);
      //console.log(currentPlaybook.current);
      const playbook = getCurrentPlaybook()
      let pgFromPlaybook = getPubGUID(playbook)
      if (pgFromPlaybook) {
        // parse out arguments from href
        const url = new URL(href)
        var pg = url.searchParams.get('pg')
        var pw = url.searchParams.get('pw')
        var ev = url.searchParams.get('ev')
        if (pg) {
          if (ev) {
            doGetPlaybook(pg, undefined, ev, pw, false, true)
          } else {
            doGetPlaybook(pg, undefined, undefined, pw, false, true)
          }
        }
      }
    },
    openEvent: (href) => {
      const playbook = getCurrentPlaybook()
      let pgFromPlaybook = getPubGUID(playbook)
      if (pgFromPlaybook) {
        const url = new URL(href)
        var pg = url.searchParams.get('pg')
        var ev = url.searchParams.get('ev')
        var pw = url.searchParams.get('pw')
        if (!pg) {
          pg = pgFromPlaybook
        }
        if (pg && ev) {
          doGetPlaybook(pg, undefined, ev, pw, false, false)
        }
      }
    },
  })

  React.useEffect(() => {
    if (EventID) {
      doGetPlaybook(PubGUID, EventID, EvSeq, undefined, true, false)
    }
  }, [EventID])

  let backbtnDiv = (
    <>
      <style> </style>
      <div className={classes.btnBack}>
        <FontAwesomeIcon
          className={classes.faBack}
          icon={faTimes}
          id="goBack"
          onClick={() => goBackButton()}
        />
        <UncontrolledTooltip placement="right" target="goBack">
          Go Back to Response Protocol
        </UncontrolledTooltip>
      </div>
    </>
  )

  React.useEffect(() => {
    setBreadCrumbsCache(playbookList.map((p) => ({ label: getEventName(p) })))
  }, [playbookList])

  const getPlaybook = async (
    PubGUID: any,
    EventID: any,
    EvSeq: any,
    PubPw: any
  ): Promise<any> => {
    setPlaybookLoading(true)
    setIncidentReportVisible(false)
    setDiagramVisible(false)
    setContactsVisible(false)
    var customHeaders: any = []
    if (EventID) {
      customHeaders.push({ name: 'eventid', value: `${EventID}` })
    }
    if (PubGUID) {
      customHeaders.push({ name: 'oppubguid', value: `${PubGUID}` })
    }
    if (EvSeq) {
      customHeaders.push({ name: 'opevtseq', value: `${EvSeq}` })
    }
    if (PubPw) {
      customHeaders.push({ name: 'oppubpw', value: `${PubPw}` })
    }
    customHeaders.push({
      name: 'useragent',
      value: `${navigator.userAgent}`,
    })
    const result = await http<any>({
      path: '/playbook/',
      headers: customHeaders,
    })
    if (result.ok && result.body) {
      //pbStr = result.body;
      return result.body
    } else {
      return ''
    }
  }

  const doGetPlaybook = async (
    PubGUID: any,
    EventID: any,
    EvSeq: any,
    PubPW: any,
    isParent: boolean = false,
    fromLink: boolean = false
  ) => {
    const Playbook = await getPlaybook(PubGUID, EventID, EvSeq, PubPW)

    if (isMounted()) {
      if (onPlaybookLoaded) onPlaybookLoaded(Playbook)

      addPlaybookToParent(
        {
          menuEvent: {
            EventID,
            EventName,
            PubGUID,
            EvSeq,
            fromLink,
          },
          ...Playbook,
        },
        isParent
      )
      setPlaybookLoading(false)
    }
  }

  function goBackButton() {
    //setBackButton(false);
    setIncidentReportVisible(false)
    setDiagramVisible(false)
    setContactsVisible(false)
  }

  function addPlaybook(playbook: any, clearList: boolean = false) {
    if (clearList) setPlaybookList([playbook])
    else {
      // use the prev value function to set the new value because of the closure was getting the wrong variable
      setPlaybookList((playbookList) => [...playbookList, playbook])
    }

    currentPlaybook.current = playbook
  }

  function addPlaybookToParent(playbook: any, clearList: boolean = false) {
    // Only add if this is the parent PbEvDetail
    if (isChild) return

    addPlaybook(playbook, clearList)
  }

  function popToPlaybook(index: number) {
    goBackButton()

    if (playbookList.length <= 0 || index < 0 || index >= playbookList.length)
      return

    let newList = []
    if (index === undefined || index === null) {
      newList = playbookList.slice(0, 1)
    } else {
      newList = playbookList.slice(0, index + 1)
    }

    setPlaybookList(newList)
    currentPlaybook.current = newList[newList.length - 1]
  }

  function getEventName(playbook: any): string {
    return (
      playbook?.menuEvent?.EventName ||
      playbook?.Event?.EventName ||
      playbook?.Submenu?.EventName ||
      playbook?.TaskPlan?.EventID ||
      playbook?.ChatPlan?.EventID ||
      playbook?.IncRpt?.EventName ||
      'Event'
    )
  }

  function getPubGUID(playbook: any): string {
    return (
      playbook?.PubGUID ||
      playbook?.Event?.PubGUID ||
      (playbook?.Playbook || [])[0]?.PubGUID
    ) /* ||
            PubGUID;*/
  }

  function getCurrentPlaybook() {
    return currentPlaybook.current
  }

  const switchEventType = () => {
    const Playbook = playbookList[playbookList.length - 1]
    if (IncidentReportVisible) {
      return (
        <>
          {backbtnDiv}
          <IncidentReportForm Playbook={Playbook} />
        </>
      )
    }
    if (DiagramVisible) {
      //setBackButton(true);
      return (
        <>
          {backbtnDiv}
          <DiagramView Playbook={Playbook} />
        </>
      )
    }
    if (ContactsVisible) {
      //setBackButton(true);
      return (
        <>
          {backbtnDiv}
          <ContactsView Playbook={Playbook} />
        </>
      )
    }
    if (Playbook.Event) {
      if (Playbook.Event.AltAction && Playbook.Event.AltAction !== 'none') {
        //setBackButton(true);
        return <AltAction altAction={Playbook.Event.AltAction} />
      } else {
        // there may be an ActionImage, get it ready
        let actimg
        if (Playbook.Event.ActionImage) {
          if (Playbook.Event.ActionImage.Format) {
            actimg = (
              <img
                className={classes.ctrDiagramImg}
                alt={Playbook.Event.EventName}
                src={
                  'data:image/' +
                  Playbook.Event.ActionImage.Format +
                  ';base64,' +
                  Playbook.Event.ActionImage.Graphic
                }
              />
            )
          }
        }
        //Need to get these buttons in the StretchHeader...
        let incrpt
        if (
          Playbook.Event.hasIncidentReport &&
          Playbook.Event.hasIncidentReport === 'Y'
        ) {
          //console.log('this has incident report, show button . . .');
          incrpt = (
            <div className={classes.btnIncRpt}>
              <FontAwesomeIcon
                className={classes.faIR}
                icon={faExclamationTriangle}
                onClick={() => setIncidentReportVisible(true)}
              />
            </div>
          )
        }
        let diagram
        if (Playbook.Event.DiagramID) {
          //console.log('this has diagram, show button . . .');
          diagram = (
            <div
              className={classes.btnDiagram}
              onClick={() => setDiagramVisible(true)}
            >
              <DiagramButton DiagramFormat={Playbook.Event.DiagramFormat} />
            </div>
          )
        }
        let HTMLresponders
        if (Playbook.Event.HTMLresponders) {
          //console.log('this has contacts, show button . . .');
          HTMLresponders = (
            <div className={classes.btnContacts}>
              <FontAwesomeIcon
                className={classes.faCB}
                icon={faAddressBook}
                onClick={() => setContactsVisible(true)}
              />
            </div>
          )
        }
        let divBtns
        if (HTMLresponders || diagram || incrpt) {
          divBtns = (
            <div>
              {incrpt}
              {HTMLresponders}
              {diagram}
            </div>
          )
        }
        return (
          <>
            {divBtns}
            {/*<p>EventID {Playbook.Event.EventID}</p>*/}
            {/*}
                        <div className={classes.altArea}>
                        {incrpt}
                        {HTMLresponders}
                        {diagram}
                        </div>
                        */}
            {actimg}
            {/* <div className={classes.breakLines} id="divEventDescription">
              <InnerHTML html={Playbook.Event.EventDescription} />
            </div> */}
            <div
              className={classes.breakLines}
              id="divEventDescription"
              dangerouslySetInnerHTML={{
                __html: Playbook.Event.EventDescription,
              }}
            />

            <div
              id="overlaydiv"
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                zIndex: 5,
              }}
            ></div>
          </>
        )
      }
    } else if (Playbook.Submenu) {
      return (
        <PbSubmenu
          submenu={Playbook.Submenu}
          onSubEventLoaded={addPlaybookToParent}
        />
      )
    } else if (Playbook.IncRpt) {
      //return <IncidentReportForm Playbook={Playbook} />;
      //setBackButton(true);
      return (
        <>
          <IncidentReportForm Playbook={Playbook} />
        </>
      )
    } else if (Playbook.EvDiagram) {
      return (
        <>
          <DiagramView Playbook={Playbook} />
        </>
      )
    } else if (Playbook.TaskPlan) {
      var hfv: EventParams = {}
      hfv['PubGUID'] = Playbook.TaskPlan.PubGUID.toLowerCase()
      hfv['RefPubGUID'] = Playbook.TaskPlan.RefPubGUID.toLowerCase()
      hfv['EventID'] = Playbook.TaskPlan.EventID
      hfv['adg'] = Playbook.AppDevGUID
      //setBackButton(true);
      return (
        <HiddenFormWithValues
          HostURL={Playbook.TaskPlan.hostURL}
          HostPage="TaskPlan"
          HdnVals={hfv}
        />
      )
    } else if (Playbook.ChatPlan) {
      var hfvc: EventParams = {}
      hfvc['PubGUID'] = Playbook.ChatPlan.PubGUID.toLowerCase()
      hfvc['RefPubGUID'] = Playbook.ChatPlan.RefPubGUID.toLowerCase()
      hfvc['EventID'] = Playbook.ChatPlan.EventID
      hfvc['adg'] = Playbook.AppDevGUID
      //setBackButton(true);
      return (
        <HiddenFormWithValues
          HostURL={Playbook.ChatPlan.hostURL}
          HostPage="ChatPlan"
          HdnVals={hfvc}
        />
      )
    } else if (Playbook.Backend) {
      var hfvb: any = {}
      if (Playbook.Backend.PubGUID) {
        hfvb['PUB_GUID'] = Playbook.Backend.PubGUID.toLowerCase()
        hfvb['PS_GUID'] = Playbook.Backend.PubGUID.toLowerCase()
        hfvb['PM_GUID'] = Playbook.Backend.PubGUID.toLowerCase()
        hfvb['PV_GUID'] = Playbook.Backend.PubGUID.toLowerCase()
        // we will be picking up the email address in the controller TODO: need to find out how to do this
        //hfvb['em'] = {EmailAddress}; //'hilld@rockdovesolutions.com';
        hfvb['adg'] = Playbook.AppDevGUID
        //setBackButton(true);
        return (
          <HiddenFormWithValues
            HostURL={Playbook.Backend.hostURL}
            HostPage=""
            HdnVals={hfvb}
          />
        )
      } else {
        var hostURL = Playbook.Backend.hostURL

        hostURL = hostURL.split('?')[0]
        var hostArgList = Playbook.Backend.hostURL.split('?')[1]
        var hostArgs = hostArgList.split('&')
        for (var i = 0; i < hostArgs.length; i++) {
          var hostArg = hostArgs[i]
          var hap = hostArg.split('=')
          hfvb[hap[0]] = hap[1]
        }

        hfvb['adg'] = Playbook.AppDevGUID
        //setBackButton(true);
        return (
          <HiddenFormWithValues HostURL={hostURL} HostPage="" HdnVals={hfvb} />
        )
      }
    } else if (Playbook.PollStart) {
      //setBackButton(true);
      return (
        <PollStart
          pollstart={Playbook.PollStart}
          eventID={Playbook.menuEvent.EventID}
        />
      )
    } else if (Playbook.AuthError) {
      //setBackButton(true);
      return (
        <>
          <h3>Authentication Error</h3>
          <p>{Playbook.AuthError}</p>
        </>
      )
    } else {
      return (
        <div>
          {/* <h2>not yet handled, here is JSON</h2>
          <pre>{JSON.stringify(Playbook, null, 2)}</pre> */}
          <h2>Missing or unauthorized content</h2>
          <p>
            The content referenced is either no longer available or current user
            is not authorized to access.
          </p>
        </div>
      )
    }
  }

  if (playbookList.length === 0) return null

  if (!playbookList[playbookList.length - 1]?.generated)
    return <div>Selected event does not exist</div>

  /*if (curLinkToPG)  {
        if (curLinkToEV)  {
            return (
                <PbEvDetail PubGUID={curLinkToPG} EvSeq={curLinkToEV} />
                );
        } else {
            return (
                <PbEvList pubGUID={curLinkToPG} />
                );
        }
    }*/

  return (
    <StretchLayout itemRef={webLinkRef}>
      {/* Event Detail goes here */}
      <StretchHeader className="p-3">
        {breadCrumbsCache.length > 0 && (
          <BreadCrumbs crumbs={breadCrumbsCache} onCrumbClick={popToPlaybook} />
        )}
      </StretchHeader>
      <StretchBody itemRef={bookmarkRef}>
        {PlaybookLoading ? (
          <CircularProgress />
        ) : (
          <div className={className}>{switchEventType()}</div>
        )}
      </StretchBody>
    </StretchLayout>
  )
}

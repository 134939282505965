import { useCallback, useEffect, useRef, useState } from 'react'
import { icoPost } from '../_utils/fetchUtils'
import { useAppSelector } from '../_utils/reactReduxHooks'
import { Spacer } from '../Shared/Spacer'
import { CircularProgress, Dialog } from '@material-ui/core'
import { cx } from '../_utils/objectUtils'
import { useIsMounted } from '../_utils/hooks'
import Rollbar from 'rollbar'
import { selectSendNotificationsSite } from '../Issue/_selectors'
import { faEdit } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classes from './AdminConsolePage.module.scss'
import { AccountOrganization, Organization } from '../Issue/_reducer'
import { selectUserOrgInfo } from '../Issue/_selectors'
import AdminConsoleOrgSelection from './AdminConsoleOrgSelection'
import {
  StretchBody,
  StretchHeader,
  StretchLayout,
} from '../Layouts/StretchLayout'
import AppHeader from '../AppHeader'
import { useHistory, useLocation, useRouteMatch } from 'react-router'
import { AllRouteParams } from '../Routing/RouteProps'

export const UserSendNotifications = () => {
  const [sendNotifyLoading, setSendNotifyLoading] = useState(false)
  const [sendNotifyGUID, setSendNotifyGUID] = useState('')
  const [userSelectingOrg, setUserSelectingOrg] = useState(false)
  const [selectedOrg, setSelectedOrg] = useState({} as Organization)
  const history = useHistory()
  const location = useLocation()
  const match = useRouteMatch<AllRouteParams>()
  const isMounted = useIsMounted()
  let rollbar = useRef<Rollbar | undefined>(undefined)
  const sendNotificationsSite = useAppSelector(selectSendNotificationsSite)
  const handleOpenSendNotify = useCallback(() => {
    icoPost(`/api/SendNotifications/AdminLogin`, {
      OrgID: selectedOrg.OrgID,
    })
      .then((response) => response.json())
      .then((guid) => {
        if (isMounted()) {
          if (!guid) {
            rollbar.current?.error('SendNotifications requires a guid to open')
          }
          setSendNotifyLoading(true)
          setSendNotifyGUID(guid)
          rollbar.current?.info('Opening Send Notifications')
        }
      })
      .catch((ex) => {
        if (isMounted()) {
          rollbar.current?.error(ex)
          console.log(ex)
        }
      })
  }, [isMounted, selectedOrg])

  const userOrgInfo: AccountOrganization =
    useAppSelector((state) => selectUserOrgInfo(state)) || []
  const adminOrgs = userOrgInfo.Orgs?.filter((org: Organization) => {
    const administratorTeams = org.IssueTeams.filter(
      (team) => team.TeamRole === 'Administrator'
    )
    return administratorTeams?.length > 0
  })
  useEffect(() => {
    if (!selectedOrg.OrgID && adminOrgs.length > 0) {
      setSelectedOrg(adminOrgs[0])
    }
  }, [adminOrgs, selectedOrg.OrgID])
  useEffect(() => {
    if (selectedOrg.OrgID) {
      handleOpenSendNotify()
    }
  }, [handleOpenSendNotify, selectedOrg])

  return (
    <StretchLayout>
      <StretchHeader>
        <AppHeader
          routeProps={{ history: history, location: location, match: match }}
        />
      </StretchHeader>
      <StretchBody>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            padding: '1rem 2rem',
            borderTop: '1px solid lightgrey',
            cursor: 'pointer',
            fontSize: '1.5rem',
          }}
          className={cx(classes.backgroundWhite, classes.orgSelectorGrey)}
          onClick={() => setUserSelectingOrg(true)}
        >
          {!adminOrgs || adminOrgs.length === 0 ? (
            'Must be an Admin on an Issue Team to Access Send Notifications'
          ) : (
            <>
              {!selectedOrg.OrgName && 'Choose '}Organization: &nbsp;
              {selectedOrg.OrgName}&nbsp;&nbsp;
              <FontAwesomeIcon icon={faEdit} size={'1x'} />
            </>
          )}
        </div>

        {sendNotifyGUID && sendNotifyGUID.length > 0 ? (
          <>
            <div className={cx('h-100', 'w-100')}>
              <iframe
                title="Send Notifications"
                name="sendNotifications-frame"
                id="notificationIframe"
                style={{ border: 'none', width: '100%', height: '100%' }}
                src={`${sendNotificationsSite}/Home/Login?guid=${sendNotifyGUID}`}
                onLoad={() => setSendNotifyLoading(false)}
              />
              <Spacer spaceParam={'large'} />
            </div>
            {sendNotifyLoading && (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100%',
                  height: '200px',
                }}
              >
                <CircularProgress />
              </div>
            )}
          </>
        ) : (
          <></>
        )}
      </StretchBody>
      <Dialog
        open={userSelectingOrg}
        onClose={() => setUserSelectingOrg(false)}
      >
        <div>
          <AdminConsoleOrgSelection
            onSelection={(org: Organization) => {
              setSelectedOrg(org)
              setUserSelectingOrg(false)
            }}
            adminOrgs={adminOrgs}
            actionText="Send Notification"
          />
        </div>
      </Dialog>
    </StretchLayout>
  )
}

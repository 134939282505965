import React, { ChangeEvent, Fragment, useCallback, useMemo } from 'react'
import { http } from '../_utils/http'
import { CircularProgress, LoadingBackDrop } from '../Shared/LoadingBackdrop'
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  RadioGroup,
  Radio,
  TextField,
  Slider,
} from '@material-ui/core'
import { Button } from '../Shared/Buttons'
import {
  DFArtifacts,
  DFDataColumn,
  DFDataTable,
  DFSection,
  DFSectionField,
  DFSelection,
  DynamicForm,
} from './IssueDynamicFormInterface'
import {
  faChevronSquareDown,
  faFile,
  faFileUpload,
  faPlusCircle,
} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import classes from './IssueDynamicForm.module.scss'
import { cx } from '../_utils/objectUtils'
import { useAppSelector } from '../_utils/reactReduxHooks'
import { selectIssueById } from './_selectors'
import { selectWorkspaceParticipantIds } from '../Workspace/_selectors'
import {
  selectIssueCurrentIssueLevel,
  selectParticipantById,
} from '../_rootConfigs/rootSelectors'
import colorDefinitions from '../ThemeProvider/ColorDefinitions.module.scss'
import {
  KeyboardDateTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import DateFnsUtils from '@date-io/moment'
import { arrayBufferToBase64 } from '../_utils/fileUtils'
import { icoPost } from '../_utils/fetchUtils'
import { useIsMounted } from '../_utils/hooks'
import {
  dfArtifactFieldName,
  dfCheckBoxName,
  dfDataTableFieldName,
  dfDateString,
  dfLabelName,
  dfMasterDetail,
  dfRadioButtonName,
  dfRadioButtonValue,
  dfRadioGroupName,
  dfSelectBoxName,
  dfSelectLisValsName,
  dfSelectListName,
  dfTextFieldName,
} from './IssueDynamicFormCommon'
import dayjs from 'dayjs'
import { Spacer } from '../Shared/Spacer'
import { appDateTimeFormat } from '../_constants'
import { UnsavedChangesPrompt } from '../Shared/UnsavedChangesPrompt'
import { Label } from 'reactstrap'
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridDeleteIcon,
  GridRowId,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
  renderEditDateCell,
  renderEditInputCell,
} from '@mui/x-data-grid'
import { titleNiceEnding } from '../_utils/stringUtils'
import { SliderThumb } from '@mui/material'
//import { isMobileApp } from '../_selectors'

export interface assessmentToDB {
  IssueID: null
  alertID: string
  dfName: 'Assessment'
  valuesJSON: string
  submit: 'Y' | 'N'
  filesJSON: string
  formScore: string
}

interface UploadFile {
  FieldID: string
  FieldCaption: string
  Name: string
  Type: string
  Buf: any
}

interface Props {
  IssueID?: number
  dfName: string // 'Scribe Notes' | 'Assessment'
  alertID?: number
  setLastSaveSubmit?: (lastSaveSubmit: string) => void
  OrgID?: number
  showDateInHeader?: boolean
  startOpen?: boolean
  onClose?: (event: {}, reason: 'userSaved') => void
}

export const IssueDynamicForm = ({
  IssueID,
  dfName,
  alertID,
  setLastSaveSubmit,
  OrgID,
  showDateInHeader = true,
  startOpen,
  onClose,
}: Props) => {
  const [dynamicForm, setDynamicForm] = React.useState<DynamicForm>()
  const [isFetchingDynamicForm, setIsFetchingDynamicForm] =
    React.useState<boolean>(false)
  const [formVals, setFormVals] = React.useState<{ [key: string]: any }>({})
  const [sectionScore, setSectionScore] = React.useState<number[]>([])
  const [formScore, setFormScore] = React.useState<number>()
  const [saveSubmit, setSaveSubmit] = React.useState<string>() // refreshes after submit
  const [showForm, setShowForm] = React.useState<boolean>(
    alertID || OrgID || startOpen ? true : false
  ) //
  const [isSaving, setIsSaving] = React.useState<boolean>(false)
  const [unSavedChanges, setUnSavedChanges] = React.useState(false)
  const issueTeamWorkspaceId = useAppSelector(
    (state) => selectIssueById(state, IssueID as any)?.wksp[0]
  )
  const participantIds = useAppSelector((state) =>
    selectWorkspaceParticipantIds(state, issueTeamWorkspaceId)
  )
  const participants = useAppSelector((state) => {
    return participantIds?.map((id: number) => selectParticipantById(state, id))
  })
  const [showSaveButton, setShowSaveButton] = React.useState<boolean>(false) //
  const [uploadFileList, setUploadFileList] = React.useState<UploadFile[]>()
  const [curLocation, setCurLocation] = React.useState<any>()
  const [gettingCurLocation, setGettingCurLocation] =
    React.useState<boolean>(false)

  const isMounted = useIsMounted()
  //const isMobile = useAppSelector(isMobileApp)
  // stuff needed to do data binding
  const curIssue = useAppSelector((state: any) =>
    selectIssueById(state, IssueID!)
  )
  const issueLevel = useAppSelector((state) =>
    selectIssueCurrentIssueLevel(state, IssueID!)
  )

  // end of stuff needed to do data binding

  React.useEffect(() => {
    setUnSavedChanges(false)
  }, [IssueID, alertID, OrgID])

  const dynamicFormSections = useMemo(
    () =>
      dynamicForm &&
      dynamicForm.df_Content &&
      dynamicForm.df_Content.Sections &&
      dynamicForm.df_Content.Sections.length > 0
        ? dynamicForm.df_Content.Sections
        : undefined,
    [dynamicForm]
  )

  const updateFormVals = (
    varName: string,
    varValue: any,
    defaultUpdate?: boolean
  ) => {
    if (!varValue) {
      setFormVals((formVals: any) => {
        const newFormVals = Object.assign({}, formVals)
        delete newFormVals[varName]
        return newFormVals
      })
    } else {
      setFormVals((formVals: any) => {
        const newFormVals = Object.assign({}, formVals)
        newFormVals[varName] = varValue
        return newFormVals
      })
    }

    if (!defaultUpdate) setUnSavedChanges(true)
  }

  const updateSectionScoring = (sectIDX: number, newScore: number) => {
    setSectionScore((sectionScore: number[]) => {
      const newScores = Object.assign({}, sectionScore)
      newScores[sectIDX] = newScore
      return newScores
    })
  }

  const appendUploadFileList = (
    fieldID: string,
    fieldCaption: string,
    name: string,
    type: string,
    buf: any
  ) => {
    const newUploadFile: UploadFile = {
      FieldID: fieldID,
      FieldCaption: fieldCaption,
      Name: name,
      Type: type,
      Buf: buf,
    }
    if (!uploadFileList) {
      const newUploadFileList: UploadFile[] = [newUploadFile]
      setUploadFileList(newUploadFileList)
    } else {
      let newUploadFileList: UploadFile[] = [...uploadFileList]
      // need the spread operator instead of the Object.assign
      newUploadFileList.push(newUploadFile)
      setUploadFileList(newUploadFileList)
    }
    setUnSavedChanges(true)
  }

  const clickFileInput = (elementName: string) => {
    const fileInput = document.getElementById(elementName)
    if (fileInput) {
      fileInput.click()
    }
  }

  React.useEffect(() => {
    const getDynamicForm = async (): Promise<any> => {
      setIsFetchingDynamicForm(true)
      let customHeaders: any = []
      if (IssueID) {
        customHeaders.push({ name: 'issueid', value: IssueID.toString() })
      } else if (alertID) {
        customHeaders.push({ name: 'alertid', value: alertID.toString() })
      } else if (OrgID) {
        customHeaders.push({ name: 'orgid', value: OrgID.toString() })
      }
      customHeaders.push({ name: 'dfname', value: dfName })
      const result = await http<any>({
        path: '/Issue/DynamicForms',
        headers: customHeaders,
      })

      if (result.ok && result.body && isMounted()) {
        setDynamicForm(result.body)
        if (isMounted()) {
          setShowForm(!!(alertID || OrgID || startOpen))
        }
        // retrieve the setting from the Dynamic Form
        const LoadPriorValuesYN =
          result.body.df_Content.LoadPriorValuesYN || 'Y'
        const AllowSaveBeforeSubmitYN =
          result.body.df_Content.AllowSaveBeforeSubmitYN || 'N'
        // determine if the values were saved and submitted, or only saved
        const isSubmitted =
          result.body.df_Values && result.body.df_Values.SubmittedGMT
        const shouldLoad = !isSubmitted || LoadPriorValuesYN === 'Y'

        setShowSaveButton(
          AllowSaveBeforeSubmitYN === 'Y' || !!(alertID && !IssueID)
        )
        if (
          result.body.df_Values &&
          result.body.df_Values.FieldValues &&
          shouldLoad
        ) {
          const newFormVals = Object.assign(
            {},
            result.body.df_Values.FieldValues
          )
          if (isMounted()) {
            setFormVals(newFormVals)
          }
        } else {
          if (isMounted()) {
            setFormVals({})
          }
        }
      } else {
        if (isMounted()) {
          setDynamicForm(undefined)
        }
      }
      if (isMounted()) {
        setIsFetchingDynamicForm(false)
      }
    }

    getDynamicForm()
  }, [IssueID, saveSubmit, dfName, isMounted, alertID, OrgID, startOpen])

  const setDateDefaultValues = useCallback(() => {
    // the date widget shows current date but if user does nothing then its onchange event does not fire
    if (dynamicFormSections) {
      dynamicFormSections.forEach(
        (section: { SectionFields: any[] }, sectionIDX: number) => {
          if (!section.SectionFields || section.SectionFields.length === 0) {
            return
          }
          section.SectionFields.forEach(
            (
              sectionField: { TextField: { Format: string } },
              secFldIDX: number
            ) => {
              if (
                sectionField.TextField &&
                sectionField.TextField.Format &&
                sectionField.TextField.Format === 'date'
              ) {
                const textFieldName = dfTextFieldName(sectionIDX, secFldIDX)
                if (!formVals[textFieldName]) {
                  const todaysDateString = dayjs().format('MM/DD/YYYY')
                  updateFormVals(textFieldName, todaysDateString, true)
                }
              }
            }
          )
        }
      )
    }
  }, [formVals, dynamicFormSections])

  React.useEffect(() => {
    if (formVals) {
      setDateDefaultValues()
    }
  }, [formVals, setDateDefaultValues])

  function submitData() {
    saveOrSubmitData(true)
  }
  function saveData() {
    saveOrSubmitData(false)
  }

  function getCurLocationFromBrowser() {
    if (dynamicForm?.df_Content?.GeoLocationYN === 'Y') {
      setGettingCurLocation(true)
      if (curLocation === undefined) {
        navigator.geolocation.getCurrentPosition(function (position) {
          let curLoc = {
            longitude: position.coords.longitude,
            latitude: position.coords.latitude,
            altitude: position.coords.altitude,
            accuracy: position.coords.accuracy,
            altitudeAccuracy: position.coords.altitudeAccuracy,
            timestamp: position.timestamp,
            happenedUTC: new Date(position.timestamp).toUTCString(),
          }
          setCurLocation(curLoc)
          setGettingCurLocation(false)
        })
      }
    }
  }

  function saveOrSubmitData(submit: boolean) {
    //getCurLocationFromBrowser()
    const formValsWithScores = {
      ...formVals,
      sectionScore: sectionScore,
      'formScore:': formScore,
      geolocation: curLocation,
    }

    const valuesJSON = JSON.stringify(formValsWithScores)
    //console.log('DRH DEBUG curLocation', JSON.stringify(curLocation, null, 2))
    //console.log('DRH DEBUG valuesJSON', valuesJSON)
    const saveDynamicForm = async (): Promise<any> => {
      icoPost('/api/Issue/DynamicFormSaveData', {
        IssueID: IssueID ? IssueID.toString() : null,
        alertID: alertID ? alertID.toString() : null,
        dfName: dfName,
        valuesJSON: valuesJSON,
        submit: submit ? 'Y' : 'N',
        filesJSON: uploadFileList ? JSON.stringify(uploadFileList) : '',
        formScore: formScore ? formScore.toString() : null,
        geolocation: curLocation ? JSON.stringify(curLocation) : null,
      })
        .then((res) => {
          if (res.ok && res.body) return res.json()
        })
        .then((data) => {
          if (isMounted()) {
            setUploadFileList(undefined)
            setSaveSubmit(dayjs().toString())
            !!setLastSaveSubmit && setLastSaveSubmit(dayjs().toString())
            setUnSavedChanges(false)
            if (submit) {
              setShowForm(false)
            }
          }
        })
        .catch((error) => console.log(error))
        .finally(() => {
          if (isMounted()) {
            setIsSaving(false)
            if (onClose) {
              onClose({}, 'userSaved')
            }
          }
        })
    }
    if (!OrgID) saveDynamicForm()
  }

  const openCloseForm = !showForm && (
    <>
      {dynamicForm?.df_Status === 'P' && (
        <div
          className={classes.styleDynamicFormSectionLeft}
          style={{ fontWeight: 'bold' }}
        >
          {dfName}
          <div
            className={cx(classes.buttonStyledAsLink)}
            style={{
              cursor: 'pointer',
              fontWeight: 'normal',
              fontSize: '14px',
              width: '30%',
            }}
            onClick={() => {
              setShowForm((prev: boolean) => !prev)
            }}
          >
            {!!(alertID && IssueID) ? 'Show' : 'Add'} Submission
          </div>
        </div>
      )}
    </>
  )

  const renderSelectOneFields = ({
    radioGroupName,
    sectionField,
    secFldIDX,
    sectionIDX,
  }: {
    radioGroupName: string
    sectionField: DFSectionField
    secFldIDX: number
    sectionIDX: number
  }) => (
    <Fragment key={radioGroupName}>
      {sectionField.Title && sectionField.Title.trim().length > 0 && (
        <div>{sectionField.Title}</div>
      )}
      <FormControl key={'secfld' + secFldIDX.toString()}>
        <RadioGroup
          defaultValue=""
          aria-labelledby={'lrg' + sectionIDX.toString()}
          name={radioGroupName}
          id={radioGroupName}
        >
          {sectionField.SelectOne?.map((s: DFSelection, selOneIDX: number) => {
            const radioButtonName = dfRadioButtonName(
              sectionIDX,
              secFldIDX,
              selOneIDX
            )
            const radioButtonValue = dfRadioButtonValue(
              sectionIDX,
              secFldIDX,
              selOneIDX
            )
            return (
              <FormControlLabel
                key={radioButtonValue}
                value={radioButtonValue}
                control={
                  <Radio
                    id={radioButtonName}
                    checked={
                      radioButtonValue === formVals[radioGroupName] || false
                    }
                    style={{
                      color: colorDefinitions.primaryControlDarker,
                      marginBottom: 'none',
                      padding: '4px',
                      marginLeft: '5px',
                    }}
                    onChange={() => {
                      updateFormVals(radioGroupName, radioButtonValue)
                    }}
                  />
                }
                label={s.Title + safeItemScoreLabel(s)}
              />
            )
          })}
        </RadioGroup>
      </FormControl>
    </Fragment>
  )

  const renderSelectManyFields = ({
    sectionField,
    secFldIDX,
    sectionIDX,
  }: {
    sectionField: DFSectionField
    secFldIDX: number
    sectionIDX: number
  }) => (
    <Fragment key={secFldIDX}>
      {sectionField.Title && sectionField.Title.length > 0 && (
        <div>{sectionField.Title}</div>
      )}

      <FormControl key={'secfld' + secFldIDX.toString()}>
        <FormGroup>
          {sectionField.SelectMany?.map(
            (s: DFSelection, selManyIDX: number) => {
              const checkBoxName = dfCheckBoxName(
                sectionIDX,
                secFldIDX,
                selManyIDX
              )
              return (
                <FormControlLabel
                  key={checkBoxName}
                  value={checkBoxName}
                  control={
                    <Checkbox
                      checked={formVals[checkBoxName] || false}
                      style={{
                        color: colorDefinitions.primaryControlDarker,
                        padding: '4px',
                        marginLeft: '5px',
                      }}
                      onChange={(evt: any) => {
                        updateFormVals(checkBoxName, evt.target.checked)
                      }}
                    />
                  }
                  label={s.Title + safeItemScoreLabel(s)}
                />
              )
            }
          )}
        </FormGroup>
      </FormControl>
    </Fragment>
  )

  const renderTextField = ({
    sectionField,
    secFldIDX,
    textFieldName,
  }: {
    sectionField: DFSectionField
    secFldIDX: number
    textFieldName: string
  }) => {
    //console.log('gonna check for binding')
    //console.log(JSON.stringify(sectionField, null, 2))
    var tfvalue = formVals[textFieldName]
    var tfIsDisabled = false
    if (
      sectionField.TextField?.Binding !== '' &&
      sectionField.TextField?.BoundTo !== ''
    ) {
      if (sectionField.TextField?.Binding === 'read') {
        tfIsDisabled = true
      }
      if (sectionField.TextField?.Binding?.startsWith('read')) {
        if (IssueID && IssueID > 0 && curIssue) {
          if (curIssue.IssueName) {
            if (sectionField.TextField?.BoundTo === 'IssueName') {
              if (tfvalue !== curIssue.IssueName) {
                tfvalue = curIssue.IssueName
                updateFormVals(textFieldName, tfvalue, true)
              }
              tfvalue = curIssue.IssueName
            }
          }
          if (sectionField.TextField?.BoundTo === 'Severity') {
            if (issueLevel && issueLevel.LevelName) {
              if (tfvalue !== issueLevel.LevelName) {
                tfvalue = issueLevel.LevelName
                updateFormVals(textFieldName, tfvalue, true)
              }
            }
          }
          if (sectionField.TextField?.BoundTo === 'IssueCreated') {
            if (curIssue.IssueCreated) {
              if (tfvalue !== curIssue.IssueCreated) {
                tfvalue = curIssue.IssueCreated
                updateFormVals(textFieldName, tfvalue, true)
              }
            }
          }
        }
      }
    }
    return (
      <div
        className={cx(classes.styleDynamicField)}
        key={'secfld' + secFldIDX.toString()}
      >
        {sectionField.TextField?.Format &&
          sectionField.TextField?.Format === 'multiline' && (
            <>
              <b>
                {sectionField.TextField?.Title &&
                  sectionField.TextField?.Title.trim().length > 0 &&
                  sectionField.TextField?.Title +
                    titleNiceEnding(sectionField.TextField?.Title)}
              </b>
              <TextField
                value={tfvalue}
                className={classes.textfield}
                multiline
                disabled={tfIsDisabled}
                maxRows={5}
                minRows={2}
                fullWidth={true}
                onChange={(evt: any) => {
                  updateFormVals(textFieldName, evt.target.value)
                }}
              />
            </>
          )}
        {sectionField.TextField?.Format &&
          sectionField.TextField?.Format === 'singleline' && (
            <>
              <b>
                {sectionField.TextField?.Title &&
                  sectionField.TextField?.Title.trim().length > 0 &&
                  sectionField.TextField?.Title +
                    titleNiceEnding(sectionField.TextField?.Title)}
              </b>
              <TextField
                value={tfvalue}
                className={classes.textfield}
                disabled={tfIsDisabled}
                fullWidth={true}
                onChange={(evt: any) => {
                  updateFormVals(textFieldName, evt.target.value)
                }}
              />
            </>
          )}
        {sectionField.TextField?.Format &&
          sectionField.TextField.Format === 'date' && (
            <>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDateTimePicker
                  variant="dialog"
                  name="Scheduled"
                  className={cx(classes.dateField, classes.startDate)}
                  ampm={true}
                  clearable
                  disabled={tfIsDisabled}
                  format="MM/DD/YYYY"
                  label={sectionField.TextField.Title}
                  onChange={(dateUtil, value) => {
                    updateFormVals(textFieldName, value)
                  }}
                  value={tfvalue}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </MuiPickersUtilsProvider>
            </>
          )}
        {sectionField.TextField?.Format &&
          sectionField.TextField.Format === 'datetime' && (
            <>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDateTimePicker
                  variant="dialog"
                  name="Scheduled"
                  className={cx(classes.dateField, classes.startDate)}
                  ampm={true}
                  clearable
                  disabled={tfIsDisabled}
                  format="MM/DD/YYYY hh:mm a"
                  label={sectionField.TextField.Title}
                  onChange={(dateUtil, value) => {
                    updateFormVals(textFieldName, value)
                  }}
                  value={tfvalue}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </MuiPickersUtilsProvider>
            </>
          )}
      </div>
    )
  }

  function calcFormScore() {
    let fs = 0
    let dfContent = dynamicForm!.df_Content

    // if (dfContent.Scoring?.ScoringMethod === 'highest') {
    //   fs = 1
    // }
    // if (dfContent.Scoring?.ScoringMethod === 'total') {
    //   fs = 0
    // }

    if (dfContent.Scoring?.ScoringScope === 'item') {
      let needsScoreUpdate = false
      let calcSectionScoreArray: number[] = []
      dfContent.Sections?.forEach(
        (section: { SectionFields: DFSectionField[] }, sectionIDX: number) => {
          // lets look through this section for items with scores
          let calcSectScore = 0
          //dfContent.Scoring?.ScoringMethod === 'total' ? 0 : 1

          section.SectionFields?.forEach(
            (sf: DFSectionField, fieldIDX: number) => {
              if (sf.SelectOne) {
                sf.SelectOne.forEach(
                  (selection: DFSelection, selectionIDX: number) => {
                    const radioGroupName = dfRadioGroupName(
                      sectionIDX,
                      fieldIDX
                    )
                    const radioButtonValue = dfRadioButtonValue(
                      sectionIDX,
                      fieldIDX,
                      selectionIDX
                    )
                    if (radioButtonValue === formVals[radioGroupName]) {
                      if (selection.score) {
                        if (dfContent.Scoring?.ScoringMethod === 'total') {
                          calcSectScore += selection.score
                          fs += selection.score
                        }
                        if (dfContent.Scoring?.ScoringMethod === 'highest') {
                          if (selection.score > calcSectScore) {
                            calcSectScore = selection.score
                          }
                          if (selection.score > fs) {
                            fs = selection.score
                          }
                        }
                      }
                    }
                  }
                )
              }
              if (sf.SelectMany) {
                sf.SelectMany.forEach(
                  (selection: DFSelection, selectionIDX: number) => {
                    const checkBoxName = dfCheckBoxName(
                      sectionIDX,
                      fieldIDX,
                      selectionIDX
                    )
                    if (formVals[checkBoxName]) {
                      if (selection.score) {
                        if (dfContent.Scoring?.ScoringMethod === 'total') {
                          calcSectScore += selection.score
                          fs += selection.score
                        }
                        if (dfContent.Scoring?.ScoringMethod === 'highest') {
                          if (selection.score > calcSectScore) {
                            calcSectScore = selection.score
                          }
                          if (selection.score > fs) {
                            fs = selection.score
                          }
                        }
                      }
                    }
                  }
                )
              }
            }
          )
          calcSectionScoreArray[sectionIDX] = calcSectScore
          if (sectionScore[sectionIDX] === undefined) {
            needsScoreUpdate = true
          } else {
            if (sectionScore[sectionIDX].valueOf() !== calcSectScore) {
              needsScoreUpdate = true
            }
          }
        }
      )
      if (needsScoreUpdate) {
        setSectionScore(calcSectionScoreArray)
      }
    }
    if (dfContent.Scoring?.ScoringScope === 'section') {
      if (dfContent.Sections) {
        dfContent.Sections.forEach(
          (_section: { SectionFields: any[] }, sectionIDX: number) => {
            if (sectionScore[sectionIDX] > 0) {
              let ss = sectionScore[sectionIDX]
              if (
                dfContent.Scoring &&
                dfContent.Scoring.ScoringMethod === 'highest'
              ) {
                if (ss > fs) fs = ss
              }
              if (
                dfContent.Scoring &&
                dfContent.Scoring.ScoringMethod === 'total'
              ) {
                fs += ss
              }
            }
          }
        )
      }
    }
    if (!formScore || fs.valueOf() !== formScore.valueOf()) {
      if (fs !== formScore) {
        setFormScore(fs)
      }
    }
    return fs
  }

  const renderLabel = ({
    secFldIDX,
    sectionField,
  }: // labelName,
  {
    sectionField: DFSectionField
    secFldIDX: number
    // labelName: string
  }) => {
    let txtLines = sectionField.Label?.Content.split('\n')
    return txtLines?.map((txtLine: string) => {
      return (
        <div
          className={cx(
            sectionField.Label?.IsBold ? classes.dfBold : '',
            sectionField.Label?.IsItalic ? classes.dfItalic : '',
            sectionField.Label?.Size === 'large' ? classes.dfLarge : '',
            sectionField.Label?.Size === 'small' ? classes.dfSmall : ''
          )}
        >
          {txtLine} &nbsp;
        </div>
      )
    })
  }

  const renderSelectList = ({
    secFldIDX,
    selectListValsName,
    sectionIDX,
    selectListName,
    selectListTitle,
  }: {
    secFldIDX: number
    selectListValsName: string
    sectionIDX: number
    selectListName: string
    selectListTitle: string
  }) => (
    <FormControl
      key={'secfld' + sectionIDX.toString() + '_' + secFldIDX.toString()}
    >
      <Label>{selectListTitle}</Label>
      <FormGroup>
        {formVals[selectListValsName] &&
          formVals[selectListValsName].map(
            (participant: any, partIDX: number) => {
              const selectBoxName = dfSelectBoxName(
                sectionIDX,
                secFldIDX,
                partIDX
              )
              return (
                <FormControlLabel
                  key={selectBoxName}
                  value={selectListName}
                  control={
                    <Checkbox
                      checked={formVals[selectBoxName] || false}
                      style={{
                        color: colorDefinitions.primaryControlDarker,
                        padding: '4px',
                        marginLeft: '5px',
                      }}
                      onChange={(evt: any) => {
                        updateFormVals(selectBoxName, evt.target.checked)
                      }}
                    />
                  }
                  label={participant}
                />
              )
            }
          )}
      </FormGroup>
    </FormControl>
  )

  const renderArtifact = ({
    artifactFieldName,
    artifactCaption,
    dynamicForm,
  }: {
    artifactFieldName: string
    artifactCaption: string
    dynamicForm: DynamicForm
  }) => (
    <Fragment key={artifactFieldName}>
      <b>{artifactCaption}:</b>
      <Spacer />
      {dynamicForm.df_Values &&
        dynamicForm.df_Values.SubmittedGMT === undefined &&
        dynamicForm.df_Values.Artifacts &&
        dynamicForm.df_Values.Artifacts.map((dfArt: DFArtifacts) => {
          if (dfArt.FieldID === artifactFieldName) {
            return (
              <div className={cx(classes.styleIndented)}>
                <FontAwesomeIcon icon={faFile} />
                &nbsp;
                {dfArt.file_name}
              </div>
            )
          } else return <></>
        })}
      {uploadFileList &&
        uploadFileList.map((upFile: UploadFile) => {
          if (upFile.FieldID === artifactFieldName) {
            return (
              <div className={cx(classes.styleIndented)}>
                <FontAwesomeIcon icon={faFileUpload} />
                &nbsp;
                {upFile.Name}
              </div>
            )
          } else return <></>
        })}
      <div className={cx(classes.styleIndented)}>
        {/* This next button is to give a better look than the naked html input file control  */}
        <button
          className={cx(classes.uploadButton)}
          onClick={() => {
            clickFileInput(artifactFieldName)
          }}
        >
          Add File
        </button>
        <input
          type="file"
          id={artifactFieldName}
          style={{ display: 'none' }}
          onChange={(evt: ChangeEvent<HTMLInputElement>) => {
            if (evt.target.files) {
              const uploadedFile: File = evt.target.files[0]
              uploadedFile.arrayBuffer().then((buffer) => {
                appendUploadFileList(
                  artifactFieldName,
                  artifactCaption,
                  uploadedFile.name,
                  uploadedFile.type,
                  arrayBufferToBase64(buffer)
                )
              })
            }
          }}
        ></input>{' '}
      </div>
      <Spacer />
    </Fragment>
  )

  const renderDataTable = ({
    secFldIDX,
    sectionIDX,
    dataTable,
  }: {
    secFldIDX: number
    sectionIDX: number
    dataTable: DFDataTable
  }) => {
    const dataTableName = dfDataTableFieldName(sectionIDX, secFldIDX)
    const fvrows = formVals[dataTableName] ? formVals[dataTableName] : []
    const datagridRows = fvrows.map((drow: any, dridx: number) => ({
      ...drow,
      id: dridx,
    }))
    let dtccols: GridColDef<any>[] = [
      {
        field: 'Action',
        type: 'actions',
        headerName: 'Action',
        width: 65,
        cellClassName: 'actions',
        getActions: ({ id }) => {
          const handleDeleteClick = (id: GridRowId) => () => {
            let dgr = formVals[dataTableName] ? formVals[dataTableName] : []
            dgr.splice(id, 1)
            updateFormVals(dataTableName, dgr)
          }
          return [
            <GridActionsCellItem
              key={'grid_' + sectionIDX.toString() + '_' + secFldIDX.toString()}
              icon={<GridDeleteIcon />}
              label="Delete"
              className={cx(classes.styleDelete)}
              onClick={handleDeleteClick(id)}
              color="secondary"
            />,
          ]
        },
      },
    ]
    const dtgcols: GridColDef<any>[] = dataTable.DataColumns
      ? dataTable.DataColumns.map((dcol: DFDataColumn) => {
          if (dcol.Format === 'date') {
            const ggDate: GridColDef = {
              field: dcol.Title,
              headerName: dcol.Title,
              width: 180,
              editable: true,
              renderEditCell: renderEditDateCell,
              valueFormatter: dfDateString,
            }
            return ggDate
          }
          if (dcol.Format === 'multiline') {
            const ggText: GridColDef = {
              field: dcol.Title,
              headerName: dcol.Title,
              width: 180,
              editable: true,
              renderEditCell: renderEditInputCell,
              renderCell: (params) => (
                <div style={{ whiteSpace: 'pre-wrap' }}>{params.value}</div>
              ),
            }
            return ggText
          }
          //         if (dcol.format === 'multiline') {
          //           /*
          //           renderCell: (params) => (
          //   <div style={{ whiteSpace: 'pre-wrap' }}>
          //     {params.value}
          //   </div>
          // )
          //           */
          //         }
          const ggText: GridColDef = {
            field: dcol.Title,
            headerName: dcol.Title,
            width: 180,
            editable: true,
          }
          return ggText
        })
      : []

    const datagridCols = [...dtccols, ...dtgcols]
    function CustomToolbar() {
      return (
        <GridToolbarContainer>
          <Button
            style={{
              background: colorDefinitions.primaryControlDarker,
            }}
            //className={cx(classes.styleOptionButton)}
            onClick={() => {
              var newRow: any = {}
              dtgcols.forEach((elem) => (newRow[elem.headerName!] = ''))
              let dgr = formVals[dataTableName] ? formVals[dataTableName] : []
              dgr.push(newRow)
              updateFormVals(dataTableName, dgr)
            }}
          >
            <FontAwesomeIcon
              //className={classes.rdsGreen}
              icon={faPlusCircle}
              // size="lg"
            />
            Add Row
          </Button>
          <GridToolbarColumnsButton />
          <GridToolbarFilterButton />
          <GridToolbarDensitySelector
            slotProps={{ tooltip: { title: 'Change density' } }}
          />
          <Box sx={{ flexGrow: 1 }} />
          <GridToolbarExport
            slotProps={{
              tooltip: { title: 'Export data' },
              button: { variant: 'outlined' },
            }}
          />
        </GridToolbarContainer>
      )
    }

    return (
      <div key={dataTableName}>
        {dataTable.Title}
        <Box sx={{ height: 400, width: '100%' }}>
          <DataGrid
            columns={datagridCols}
            rows={datagridRows}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 5,
                },
              },
            }}
            pageSizeOptions={[5]}
            disableRowSelectionOnClick
            processRowUpdate={(updatedRow: any) => {
              let dgRowVals = formVals[dataTableName]
                ? formVals[dataTableName]
                : []
              let updatedRowId = parseInt(updatedRow.id)
              let rowWithoutID = Object.assign({}, updatedRow)
              delete rowWithoutID.id
              dgRowVals[updatedRowId] = rowWithoutID
              updateFormVals(dataTableName, dgRowVals)
              return updatedRow
            }}
            onProcessRowUpdateError={(error) =>
              console.log('process row update error', error)
            }
            slots={{
              toolbar: CustomToolbar,
            }}
          />
        </Box>
      </div>
    )
  }

  function safeItemScoreLabel(s: DFSelection): String {
    let safeScoreLabel: String = ''
    if (
      dynamicForm!.df_Content!.Scoring !== undefined &&
      dynamicForm!.df_Content!.Scoring.ScoringScope === 'item' &&
      s.score !== undefined &&
      s.score.valueOf() > 0
    ) {
      safeScoreLabel = ' (item score: ' + s.score.toString() + ')'
    }
    return safeScoreLabel
  }

  function safeSliderValue(sectionIDX: number) {
    let safeVal: number = 0
    // if (
    //   dynamicForm &&
    //   dynamicForm.df_Content.Scoring &&
    //   dynamicForm.df_Content.Scoring.ScoringMethod === 'highest'
    // ) {
    //   safeVal = 1
    // }
    if (sectionScore[sectionIDX] === undefined) {
      return safeVal
    }
    safeVal = sectionScore[sectionIDX]
    return safeVal
  }

  const canEditForm = !(alertID && IssueID) || showSaveButton

  if (isFetchingDynamicForm) {
    return (
      <>
        <CircularProgress />
      </>
    )
  }
  if (!dynamicForm) return <></>
  if (dynamicForm.canSubmitYN !== 'Y')
    return (
      <>
        Form or permissions do not permit you to submit{' '}
        {dynamicForm.df_Content &&
          dynamicForm.df_Content.Heading &&
          dynamicForm.df_Content.Heading}
      </>
    )
  //getCurLocationFromBrowser()
  //getCurLocationFromBrowser()
  return (
    <>
      <LoadingBackDrop zIndex={99} text="Saving" open={isSaving} />
      {(showForm || startOpen) && (
        <div style={{ padding: '1rem' }}>
          <div className={cx(classes.styleDynamicForm)}>
            <div
              className={classes.styleDynamicFormHeadingNotSubmitted}
              style={{ fontWeight: 'bold' }}
            >
              {dynamicForm.df_Content.Heading}
              <div
                className={cx(classes.buttonStyledAsLink)}
                style={{
                  cursor: 'pointer',
                  fontWeight: 'normal',
                  fontSize: '14px',
                }}
                onClick={() => {
                  setShowForm((prev: boolean) => !prev)
                }}
              >
                {!!(alertID && IssueID) ? 'Hide' : 'Cancel'} Submission
              </div>
              {dynamicForm.df_Content.Scoring &&
                dynamicForm.df_Content.Scoring.ScoringMethod &&
                dynamicForm.df_Content.Scoring.ScoringMethod !== 'none' &&
                dynamicForm.df_Content.Scoring.ScoringMaximum &&
                dynamicForm.df_Content.Scoring.ScoringMaximum > 1 && (
                  <span
                    style={{
                      //width: '30%',
                      textAlign: 'right',
                    }}
                  >
                    Score: {calcFormScore()}
                  </span>
                )}
            </div>

            <div
              style={{
                padding: '0.5rem',
              }}
            >
              {dynamicFormSections?.map(
                (section: DFSection, sectionIDX: number) => {
                  const sectionID = 'dfSection' + sectionIDX.toString()

                  let dfSummary = (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '100%',
                      }}
                    >
                      <span style={{}}>&nbsp;{section.Name}</span>
                      <span>
                        {dynamicForm.df_Content.Scoring &&
                          dynamicForm.df_Content.Scoring.ScoringMethod &&
                          dynamicForm.df_Content.Scoring.ScoringMethod !==
                            'none' &&
                          dynamicForm.df_Content.Scoring.ScoringScope ===
                            'item' &&
                          sectionScore !== undefined &&
                          sectionScore[sectionIDX] !== undefined &&
                          sectionScore[sectionIDX].valueOf() > 0 && (
                            <>
                              &nbsp; &nbsp; &nbsp; &nbsp; Section Score:
                              {sectionScore[sectionIDX]}
                            </>
                          )}
                      </span>
                    </div>
                  )
                  let dfDetails = (
                    <div
                      style={{
                        pointerEvents: canEditForm ? undefined : 'none',
                      }}
                      className={cx(classes.styleDynamicSection)}
                    >
                      {dynamicForm.df_Content.Scoring &&
                        dynamicForm.df_Content.Scoring.ScoringMethod &&
                        dynamicForm.df_Content.Scoring.ScoringMethod !==
                          'none' &&
                        dynamicForm.df_Content.Scoring.ScoringScope ===
                          'section' &&
                        dynamicForm.df_Content.Scoring.ScoringMaximum &&
                        dynamicForm.df_Content.Scoring.ScoringMaximum > 1 && (
                          <div
                            style={{
                              width: '60%',
                              background: '#efefef',
                              display: 'flex',
                              justifyContent: 'space-between',
                              float: 'right',
                            }}
                          >
                            <span> Section Score: </span>
                            <span style={{ width: '50%' }}>
                              <Slider
                                style={{ width: '95%', marginTop: '4px' }}
                                marks
                                step={1}
                                min={0}
                                max={
                                  dynamicForm.df_Content.Scoring.ScoringMaximum
                                }
                                valueLabelDisplay="on"
                                value={safeSliderValue(sectionIDX)}
                                onChange={(_evt: any, value: any) => {
                                  updateSectionScoring(sectionIDX, value)
                                }}
                              />
                            </span>
                          </div>
                        )}
                      {section.SectionFields &&
                        section.SectionFields.map(
                          (sectionField: DFSectionField, secFldIDX: number) => {
                            if (sectionField.SelectOne) {
                              const radioGroupName = dfRadioGroupName(
                                sectionIDX,
                                secFldIDX
                              )
                              return renderSelectOneFields({
                                radioGroupName,
                                secFldIDX,
                                sectionField,
                                sectionIDX,
                              })
                            } else if (sectionField.SelectMany)
                              return renderSelectManyFields({
                                secFldIDX,
                                sectionField,
                                sectionIDX,
                              })
                            else if (sectionField.TextField) {
                              const textFieldName = dfTextFieldName(
                                sectionIDX,
                                secFldIDX
                              )
                              return renderTextField({
                                secFldIDX,
                                sectionField,
                                textFieldName,
                              })
                            } else if (sectionField.Label) {
                              // const labelName = dfLabelName(
                              //   sectionIDX,
                              //   secFldIDX
                              // )
                              return renderLabel({
                                secFldIDX,
                                sectionField,
                                //labelName,
                              })
                            } else if (sectionField.SelectList) {
                              const selectListName = dfSelectListName(
                                sectionIDX,
                                secFldIDX
                              )

                              const selectListValsName = dfSelectLisValsName(
                                sectionIDX,
                                secFldIDX
                              )

                              const selList = sectionField.SelectList
                              const selectListTitle: string = selList.Title
                              if (!selList.ValueArray) {
                                if (
                                  selList.Source &&
                                  selList.Source === 'Participants' &&
                                  participants // added DRH 14-May-2024
                                ) {
                                  if (!formVals[selectListValsName]) {
                                    const partNameArray = participants
                                      ?.filter(
                                        (participant: any) =>
                                          participant.IsActiveYN === 'Y'
                                      )
                                      .map((participant: any) => {
                                        return (
                                          participant.email_address +
                                          (participant.FullName
                                            ? ' - ' + participant.FullName
                                            : '')
                                        )
                                      })
                                    updateFormVals(
                                      selectListValsName,
                                      partNameArray,
                                      true
                                    )
                                  }
                                }
                              }

                              return renderSelectList({
                                secFldIDX,
                                selectListValsName,
                                selectListName,
                                sectionIDX,
                                selectListTitle,
                              })
                            } else if (sectionField.Artifact) {
                              const artifactFieldName = dfArtifactFieldName(
                                sectionIDX,
                                secFldIDX
                              )
                              const artifactCaption =
                                sectionField.Artifact.Title

                              return renderArtifact({
                                artifactCaption,
                                artifactFieldName,
                                dynamicForm,
                              })
                            } else if (sectionField.DataTable) {
                              // const dataTableFieldName =
                              //   dfDataTableFieldName(sectionIDX, secFldIDX)
                              const dataTable = sectionField.DataTable
                              //const dataTableTitle = dataTable.Title

                              return renderDataTable({
                                secFldIDX,
                                sectionIDX,
                                dataTable,
                              })
                            } else
                              return (
                                <div
                                  style={{
                                    background: '#ffff00',
                                  }}
                                  key={'secfld' + secFldIDX.toString()}
                                >
                                  We have not yet implemented{' '}
                                  <pre>
                                    {JSON.stringify(sectionField, null, 2)}
                                  </pre>{' '}
                                </div>
                              )
                          }
                        )}
                    </div>
                  )
                  return (
                    <div key={sectionID}>
                      <>
                        {dfMasterDetail(
                          dfSummary,
                          dfDetails,
                          section.Collapsible,
                          !section.Collapsed
                        )}
                        {sectionIDX !== dynamicFormSections.length - 1 && (
                          <div style={{ padding: '12px 0px 8px' }}>
                            <div style={{ border: 'solid 1px #003e6b' }}></div>
                          </div>
                        )}
                      </>
                    </div>
                  )
                }
              )}
            </div>
          </div>
        </div>
      )}
      <Spacer />
      <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
        {!startOpen && openCloseForm}
        {(startOpen || showForm) && (
          <>
            {showSaveButton && (
              <>
                <Button
                  style={{
                    background: colorDefinitions.primaryControlDarker,
                  }}
                  onClick={() => {
                    setIsSaving(true)
                    saveData()
                  }}
                  disabled={isSaving || !!(alertID && IssueID)}
                >
                  Save
                </Button>
                <Spacer />
              </>
            )}
            {!alertID &&
              dynamicForm.df_Content.GeoLocationYN === 'Y' &&
              curLocation === undefined && (
                <>
                  <Button
                    style={{
                      background: colorDefinitions.primaryControlDarker,
                    }}
                    onClick={() => {
                      getCurLocationFromBrowser()
                    }}
                    disabled={gettingCurLocation}
                  >
                    Get Location
                  </Button>
                  <Spacer />
                </>
              )}
            {!alertID && (
              <Button
                style={{
                  background: colorDefinitions.primaryControlDarker,
                }}
                onClick={() => {
                  //getCurLocationFromBrowser()
                  setIsSaving(true)
                  submitData()
                }}
                disabled={isSaving}
              >
                Submit
              </Button>
            )}
          </>
        )}
      </div>
      <UnsavedChangesPrompt hasUnsavedChanges={unSavedChanges} />
    </>
  )
}

// @ts-nocheck
// TODO: Typescript
import React, { Component } from 'react'
import { ScrollList } from '../Shared/ScrollList'
import PropTypes from 'prop-types'
import { IssueProvideMoreInfoFormsViewer } from '../Issue/IssueProvideMoreInfoFormsViewer'

/**
 * @extends {Component<Props, {}>}}
 * Renders a list of form items
 */
export class FormList extends Component {
  render() {
    const { formItems } = this.props
    if (!formItems || formItems.length === 0) return null

    return (
      <ScrollList>
        {formItems.map(
          (item, itemIdx) =>
            item.df_id && (
              <div
                key={
                  'itmKey' + itemIdx.toString() + item.df_id
                    ? item.df_id.toString()
                    : 42
                }
              >
                <IssueProvideMoreInfoFormsViewer
                  IssueID={item.IssueID}
                  dfName={item.ReportFormName}
                />
              </div>
            )
        )}
      </ScrollList>
    )
  }
}

FormList.propTypes = {
  formItems: PropTypes.array,
  onClick: PropTypes.func,
  showOrgName: PropTypes.bool,
  disabled: PropTypes.bool,
}

FormList.defaultProps = {
  showOrgName: true,
}

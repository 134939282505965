import React, { Fragment, useCallback } from 'react'
import { http } from '../_utils/http'
import { CircularProgress } from '../Shared/LoadingBackdrop'
import classes from './IssueDynamicForm.module.scss'
import { cx } from '../_utils/objectUtils'
import {
  faCircleDot,
  faDoNotEnter,
  faFile,
  faMapLocationDot,
} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Moment from 'react-moment'
import {
  DynamicForm,
  DFSection,
  DFSectionField,
  DFSelection,
  DFArtifacts,
  DFDataColumn,
} from './IssueDynamicFormInterface'
import {
  dfArtifactFieldName,
  dfCheckBoxName,
  dfDataTableFieldName,
  dfDateString,
  dfRadioButtonValue,
  dfRadioGroupName,
  dfSelectBoxName,
  dfSelectLisValsName,
  dfSelectListName,
  dfTextFieldName,
} from './IssueDynamicFormCommon'
import { appDateTimeFormat } from '../_constants'
import colorDefinitions from '../ThemeProvider/ColorDefinitions.module.scss'
import { CheckBox } from '@material-ui/icons'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core'
import { titleNiceEnding } from '../_utils/stringUtils'
import {
  Circle,
  GoogleMap,
  Libraries,
  Marker,
  useLoadScript,
} from '@react-google-maps/api'
import { googleApiKey } from '../_utils/AppSettings'
import { useAppSelector } from '../_utils/reactReduxHooks'
import { isMobileApp } from '../_selectors'

interface Props {
  IssueID: number
  submissionNumber: number
}

const libraries = ['places']
/**
 * @extends {Component<Props, {}>}}
 */

export const IssueDynamicFormViewer = ({
  IssueID,
  submissionNumber,
}: Props) => {
  const [dynamicForm, setDynamicForm] = React.useState<DynamicForm>()
  const [isFetchingSubmittedForm, setIsFetchingSubmittedForm] =
    React.useState<boolean>(false)
  const [formVals, setFormVals] = React.useState<{ [key: string]: any }>({})
  //const [map, setMap] = React.useState<google.maps.Map | null>(null)
  const isMobile = useAppSelector((state) => isMobileApp(state))
  const [map, setMap] = React.useState<google.maps.Map | null>(null)
  const [showMap, setShowMap] = React.useState<boolean>(false)
  // console.log('DRH DEBUG', 'starting render')
  React.useEffect(() => {
    const getSubmittedForm = async (): Promise<any> => {
      setIsFetchingSubmittedForm(true)
      let customHeaders: any = []
      customHeaders.push({ name: 'issueid', value: IssueID.toString() })
      customHeaders.push({
        name: 'submissionnumber',
        value: submissionNumber.toString(),
      })
      const result = await http<any>({
        path: '/Issue/DynamicFormView',
        headers: customHeaders,
      })
      if (result.ok && result.body) {
        setDynamicForm(result.body)
        if (result.body.df_Values && result.body.df_Values.FieldValues) {
          const newFormVals = Object.assign(
            {},
            result.body.df_Values.FieldValues
          )
          setFormVals(newFormVals)
        }
      } else {
        setDynamicForm(undefined)
      }
      setIsFetchingSubmittedForm(false)
    }
    getSubmittedForm()
  }, [IssueID, submissionNumber])

  // ----- START OF MAP SECTION
  const onLoad = useCallback(
    (loadedMap: google.maps.Map) => {
      if (
        dynamicForm?.df_Values?.FieldValues?.geolocation?.latitude !== 0 &&
        dynamicForm?.df_Values?.FieldValues?.geolocation?.longitude !== 0
      ) {
        setMap(loadedMap)
        const bounds = new google.maps.LatLngBounds()

        if (
          isValidLatLng(
            dynamicForm?.df_Values?.FieldValues.geolocation.latitude,
            dynamicForm?.df_Values?.FieldValues.geolocation.longitude
          )
        ) {
          const point = new google.maps.LatLng(
            dynamicForm?.df_Values?.FieldValues.geolocation.latitude,
            dynamicForm?.df_Values?.FieldValues.geolocation.longitude
          )

          bounds.extend(point)
        }

        loadedMap.fitBounds(bounds)
        loadedMap.setZoom(7)
      }
    },
    [
      dynamicForm?.df_Values?.FieldValues?.geolocation?.latitude,
      dynamicForm?.df_Values?.FieldValues?.geolocation?.longitude,
    ]
  )
  const onUnmount = useCallback(function callback(map) {
    setMap(null)
  }, [])

  // const onUnmount = useCallback(function callback(map) {
  //   setMap(null)
  // }, [])
  // if (typeof google === 'object' && typeof google.maps === 'object')

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: googleApiKey,
    libraries: libraries as Libraries,
    preventGoogleFontsLoading: false,
  })

  const GoogleMapSection =
    isLoaded &&
    dynamicForm?.df_Values?.FieldValues?.geolocation?.latitude !== 0 &&
    dynamicForm?.df_Values?.FieldValues?.geolocation?.longitude !== 0 ? (
      <GoogleMap
        mapContainerStyle={{
          width: '100%',
          height: '100%',
        }}
        onLoad={onLoad}
        onUnmount={onUnmount}
        options={{
          fullscreenControl: false,
          streetViewControl: false,
          mapTypeControl: true,
          gestureHandling: 'cooperative',
          zoom: 12,
        }}
        onBoundsChanged={() => {
          let zoom = map?.getZoom()
          if (zoom) {
            if (zoom > 21) {
              map?.setZoom(12)
            } else if (zoom > 16) {
              map?.setZoom(16)
            } else if (zoom < 2) {
              map?.setZoom(2)
            }
          }
        }}
      >
        <Marker
          key={0}
          position={{
            lat: dynamicForm?.df_Values?.FieldValues?.geolocation?.latitude,
            lng: dynamicForm?.df_Values?.FieldValues?.geolocation?.longitude,
          }}
        />
        {
          // draw a circle to show the geolocation acccuracy if available
          dynamicForm?.df_Values?.FieldValues?.geolocation?.accuracy && (
            <Circle
              center={{
                lat: dynamicForm?.df_Values?.FieldValues?.geolocation?.latitude,
                lng: dynamicForm?.df_Values?.FieldValues?.geolocation
                  ?.longitude,
              }}
              radius={
                dynamicForm?.df_Values?.FieldValues?.geolocation?.accuracy
              }
              options={{
                fillColor: '#777',
                fillOpacity: 0.25,
                strokeColor: '#ff0000',
                strokeOpacity: 0.25,
              }}
            />
          )
        }
      </GoogleMap>
    ) : (
      <></>
    )

  const isValidLatLng = (lat?: number, lng?: number) => {
    if (!lat || !lng) return false

    return !isNaN(lat) && !isNaN(lng) && lat !== undefined && lng !== undefined
  }

  // ----- END OF MAP SECTION

  if (isFetchingSubmittedForm)
    return (
      <>
        <CircularProgress />
      </>
    )
  if (!dynamicForm)
    return (
      <>
        <i>dynamic form not found</i>
      </>
    )
  return (
    <div
      style={{
        border: 'solid 1px lightgrey',
        width: '100%',
      }}
    >
      <div className={classes.styleDynamicFormHeading}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            width: '100%',
          }}
        >
          <div>{dynamicForm.df_Content.Heading}</div>
          <div
            style={{
              fontSize: '16px',
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <span>
              <Moment format={appDateTimeFormat} utc local>
                {dynamicForm?.df_Values?.SubmittedGMT}
              </Moment>
              &nbsp;&nbsp;&nbsp;&nbsp;
              {dynamicForm?.df_Values?.Submitter}
            </span>
            <span>
              {dynamicForm?.df_Values?.formScore &&
                dynamicForm.df_Values.formScore > 0 &&
                ' Score: ' + dynamicForm.df_Values.formScore.toString()}
            </span>
          </div>
        </div>
      </div>
      <div style={{ padding: '0.5rem' }}>
        {dynamicForm.df_Content &&
          dynamicForm.df_Content.Sections.map(
            (section: DFSection, sectionIDX: number) => {
              const dffv_section_key =
                'smv_' +
                submissionNumber.toString() +
                '_sec_' +
                sectionIDX.toString()
              // console.log('DRH DEBUG key', dffv_section_key)
              let sectionHasValues = false
              const sectionValues = (
                <Fragment key={dffv_section_key}>
                  {section.SectionFields &&
                    section.SectionFields.map(
                      (sectionField: DFSectionField, secFldIDX: number) => {
                        const secFldKey =
                          dffv_section_key + '_frag_' + secFldIDX.toString()
                        // console.log('DRH DEBUG key', secFldKey)
                        if (sectionField.SelectOne) {
                          const radioGroupName = dfRadioGroupName(
                            sectionIDX,
                            secFldIDX
                          )
                          const selOneKey = secFldKey + '_selOne'
                          // console.log('DRH DEBUG key', selOneKey)
                          return (
                            <Fragment key={selOneKey}>
                              {sectionField.Title &&
                                sectionField.Title.length > 0 && (
                                  <div>{sectionField.Title}</div>
                                )}
                              {sectionField.SelectOne.map(
                                (s: DFSelection, selOneIDX: number) => {
                                  const radioButtonValue = dfRadioButtonValue(
                                    sectionIDX,
                                    secFldIDX,
                                    selOneIDX
                                  )
                                  if (
                                    radioButtonValue ===
                                    formVals[radioGroupName]
                                  ) {
                                    const rbvKey =
                                      selOneKey + '_rbv_' + selOneIDX.toString()
                                    //radioButtonValue

                                    // console.log('DRH DEBUG key', rbvKey)
                                    sectionHasValues = true

                                    return (
                                      <div
                                        key={rbvKey}
                                        style={{
                                          display: 'flex',
                                          flexDirection: 'row',
                                          alignItems: 'center',
                                          justifyContent: 'flex-start',
                                        }}
                                      >
                                        <FontAwesomeIcon
                                          icon={faCircleDot}
                                          style={{
                                            color:
                                              colorDefinitions.primaryControlDarker,
                                            height: '18px',
                                            width: '18px',
                                            padding: '3px',
                                          }}
                                        />
                                        &nbsp;
                                        {s.Title} &nbsp;{' '}
                                        {s.score !== undefined &&
                                          s.score.valueOf() > 0 &&
                                          ' (item score ' +
                                            s.score.toString() +
                                            ')'}
                                        <br />
                                      </div>
                                    )
                                  } else {
                                    return <></>
                                  }
                                }
                              )}
                            </Fragment>
                          )
                        } else if (sectionField.SelectMany) {
                          const selManyKey = secFldKey + '_selMany'
                          // console.log('DRH DEBUG key', selManyKey)
                          return (
                            <Fragment key={selManyKey}>
                              {sectionField.Title &&
                                sectionField.Title.length > 0 && (
                                  <div>{sectionField.Title}</div>
                                )}
                              {sectionField.SelectMany.map(
                                (s: DFSelection, selManyIDX: number) => {
                                  const checkBoxName = dfCheckBoxName(
                                    sectionIDX,
                                    secFldIDX,
                                    selManyIDX
                                  )
                                  if (formVals[checkBoxName]) {
                                    const cbKey =
                                      selManyKey +
                                      '_smidx_' +
                                      selManyIDX.toString()
                                    // console.log('DRH DEBUG key', cbKey)
                                    sectionHasValues = true
                                    return (
                                      <div
                                        key={cbKey}
                                        style={{
                                          display: 'flex',
                                          flexDirection: 'row',
                                          alignItems: 'center',
                                          justifyContent: 'flex-start',
                                        }}
                                      >
                                        <CheckBox
                                          style={{
                                            color:
                                              colorDefinitions.primaryControlDarker,
                                          }}
                                        />
                                        &nbsp;
                                        {s.Title} &nbsp;
                                        {s.score !== undefined &&
                                          s.score.valueOf() > 0 &&
                                          ' (item score ' +
                                            s.score.toString() +
                                            ')'}
                                        <br />
                                      </div>
                                    )
                                  } else {
                                    return <></>
                                  }
                                }
                              )}
                            </Fragment>
                          )
                        } else if (sectionField.TextField) {
                          const textFieldName = dfTextFieldName(
                            sectionIDX,
                            secFldIDX
                          )
                          const selTxtFldKey = secFldKey + '_selTxtFld'
                          // console.log('DRH DEBUG key', selTxtFldKey)

                          if (
                            formVals[textFieldName] &&
                            formVals[textFieldName].length > 0
                          ) {
                            sectionHasValues = true
                            return (
                              <div
                                key={selTxtFldKey}
                                style={{
                                  wordBreak: 'break-word',
                                  whiteSpace: 'normal',
                                }}
                              >
                                {sectionField.TextField.Title}
                                {titleNiceEnding(sectionField.TextField.Title)}
                                <br />
                                <div
                                  style={{
                                    width: '95%',
                                    backgroundColor: '#e7e7e7',
                                  }}
                                >
                                  {formVals[textFieldName]}
                                </div>
                              </div>
                            )
                          } else {
                            return <></>
                          }
                        } else if (sectionField.SelectList) {
                          sectionHasValues = true
                          // const selectListName = dfSelectListName(
                          //   sectionIDX,
                          //   secFldIDX
                          // )
                          const selectListValsName = dfSelectLisValsName(
                            sectionIDX,
                            secFldIDX
                          )
                          const selListKey = secFldKey + '_selListFld'
                          // console.log('DRH DEBUG key', selListKey)

                          return (
                            <div key={selListKey}>
                              {formVals[selectListValsName] &&
                                formVals[selectListValsName].map(
                                  (participant: any, partIDX: number) => {
                                    const selectBoxName = dfSelectBoxName(
                                      sectionIDX,
                                      secFldIDX,
                                      partIDX
                                    )
                                    const selListYesValKey =
                                      selListKey +
                                      '_yesval_' +
                                      partIDX.toString()
                                    // console.log( 'DRH DEBUG key', selListYesValKey )

                                    if (formVals[selectBoxName]) {
                                      return (
                                        <div
                                          key={selListYesValKey}
                                          style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            justifyContent: 'flex-start',
                                          }}
                                        >
                                          <CheckBox
                                            // title="Attended meeting"
                                            style={{
                                              color:
                                                colorDefinitions.primaryControlDarker,
                                            }}
                                          />
                                          &nbsp;
                                          <b>{participant}</b>
                                        </div>
                                      )
                                    } else {
                                      return <></>
                                    }
                                  }
                                )}
                              {formVals[selectListValsName] &&
                                formVals[selectListValsName].map(
                                  (participant: any, partIDX: number) => {
                                    const selectBoxName = dfSelectBoxName(
                                      sectionIDX,
                                      secFldIDX,
                                      partIDX
                                    )
                                    const selListNoValKey =
                                      selListKey +
                                      '_noval_' +
                                      partIDX.toString()
                                    // console.log( 'DRH DEBUG key', selListNoValKey )

                                    if (!formVals[selectBoxName]) {
                                      return (
                                        <div
                                          key={selListNoValKey}
                                          style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            justifyContent: 'flex-start',
                                          }}
                                        >
                                          <FontAwesomeIcon
                                            className={cx(classes.absent)}
                                            icon={faDoNotEnter}
                                            title="Did not attend meeting"
                                            style={{
                                              color:
                                                colorDefinitions.primaryControlDarker,
                                              height: '18px',
                                              width: '18px',
                                              padding: '3px',
                                            }}
                                          />
                                          &nbsp;
                                          <i>{participant}</i>
                                        </div>
                                      )
                                    } else {
                                      return <></>
                                    }
                                  }
                                )}
                            </div>
                          )
                        } else if (sectionField.Artifact) {
                          const artifactFieldName = dfArtifactFieldName(
                            sectionIDX,
                            secFldIDX
                          )
                          const artifactKey = secFldKey + '_artifact'
                          // console.log('DRH DEBUG key', artifactKey)
                          return (
                            dynamicForm?.df_Values?.Artifacts &&
                            dynamicForm?.df_Values?.Artifacts.map(
                              (artifact: DFArtifacts, artiIDX: number) => {
                                if (artifact.FieldID === artifactFieldName) {
                                  sectionHasValues = true
                                  return (
                                    <div
                                      key={
                                        artifactKey + '_' + artiIDX.toString()
                                      }
                                    >
                                      <b>{artifact.FieldCaption}:</b>
                                      <div>
                                        {' '}
                                        <FontAwesomeIcon
                                          icon={faFile}
                                          style={{
                                            color:
                                              colorDefinitions.primaryControlDarker,
                                          }}
                                        />
                                        &nbsp;
                                        {artifact.file_name}
                                      </div>
                                    </div>
                                  )
                                } else
                                  return (
                                    <Fragment
                                      key={
                                        artifactFieldName +
                                        '_' +
                                        artiIDX.toString()
                                      }
                                    />
                                  )
                              }
                            )
                          )
                        } else if (sectionField.DataTable) {
                          const dataTableName = dfDataTableFieldName(
                            sectionIDX,
                            secFldIDX
                          )
                          const dataTableKey = secFldKey + '_datatable'
                          // console.log('DRH DEBUG key', dataTableKey)

                          if (
                            formVals[dataTableName] &&
                            formVals[dataTableName].length > 0
                          ) {
                            sectionHasValues = true
                            return (
                              <div key={dataTableKey}>
                                <b>{sectionField.DataTable.Title}</b>

                                <Table
                                  // sx={{ minWidth: 300, width: '100%' }}
                                  aria-label="custom table"
                                >
                                  <TableHead>
                                    <TableRow style={{ width: '100%' }}>
                                      {sectionField.DataTable.DataColumns &&
                                        sectionField.DataTable.DataColumns.map(
                                          (dc, didx) => (
                                            <TableCell
                                              key={
                                                'kh' +
                                                dataTableName +
                                                didx.toString()
                                              }
                                              align="left"
                                            >
                                              {dc.Title}
                                            </TableCell>
                                          )
                                        )}
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {formVals[dataTableName] &&
                                      formVals[dataTableName].map(
                                        (dd: any, ddidx: any) => (
                                          <TableRow
                                            key={
                                              'tr' +
                                              dataTableName +
                                              ddidx.toString()
                                            }
                                          >
                                            {sectionField?.DataTable?.DataColumns?.map(
                                              (dc: DFDataColumn, didx) => {
                                                if (dc.Format === 'date')
                                                  return (
                                                    <TableCell
                                                      align="left"
                                                      key={
                                                        'dcm' +
                                                        dataTableName +
                                                        '_' +
                                                        ddidx.toString() +
                                                        '_' +
                                                        didx.toString()
                                                      }
                                                    >
                                                      {dfDateString(
                                                        dd[dc.Title]
                                                      )}
                                                    </TableCell>
                                                  )
                                                return (
                                                  <TableCell
                                                    align="left"
                                                    key={
                                                      dataTableName +
                                                      '_' +
                                                      ddidx.toString() +
                                                      '_' +
                                                      didx.toString()
                                                    }
                                                  >
                                                    {dd[dc.Title]}
                                                  </TableCell>
                                                )
                                              }
                                            )}
                                          </TableRow>
                                        )
                                      )}
                                  </TableBody>
                                </Table>
                              </div>
                            )
                          }
                        }
                        return <></>
                      }
                    )}
                </Fragment>
              )
              if (
                dynamicForm?.df_Values?.FieldValues?.sectionScore &&
                dynamicForm.df_Values.FieldValues.sectionScore[sectionIDX] &&
                dynamicForm.df_Values.FieldValues.sectionScore[
                  sectionIDX
                ].valueOf() > 0
              ) {
                sectionHasValues = true
              }
              if (sectionValues && sectionHasValues) {
                const dffvo_section_key =
                  'svopen_' +
                  submissionNumber.toString() +
                  '_' +
                  sectionIDX.toString()
                // console.log('DRH DEBUG key', dffvo_section_key)
                return (
                  <div key={dffvo_section_key}>
                    <details open>
                      <summary style={{ color: '#003e6b' }}>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            width: '100%',
                          }}
                        >
                          <span>&nbsp;{section.Name}</span>
                          {dynamicForm?.df_Values?.FieldValues?.sectionScore !==
                            undefined &&
                            dynamicForm.df_Values.FieldValues.sectionScore[
                              sectionIDX
                            ] !== undefined &&
                            dynamicForm.df_Values.FieldValues.sectionScore[
                              sectionIDX
                            ].valueOf() > 0 && (
                              <span>
                                {'  Section Score: ' +
                                  dynamicForm.df_Values.FieldValues.sectionScore[
                                    sectionIDX
                                  ].toString()}
                              </span>
                            )}
                        </div>
                      </summary>
                      <div className={cx(classes.styleDynamicSection)}>
                        {sectionValues}
                      </div>
                    </details>
                    {sectionIDX !==
                      dynamicForm.df_Content.Sections.length - 1 && (
                      <div style={{ padding: '12px 0px 8px' }}>
                        <div style={{ border: 'solid 1px #003e6b' }}></div>
                      </div>
                    )}
                  </div>
                )
              } else return null
            }
          )}
        {dynamicForm?.df_Values?.FieldValues?.geolocation && (
          <div>
            <div style={{ padding: '12px 0px 8px' }}>
              <div style={{ border: 'solid 1px #003e6b' }}></div>
            </div>
            <div
              className={cx(classes.buttonStyledAsLink)}
              style={{
                cursor: 'pointer',
                fontWeight: 'normal',
                fontSize: '14px',
                width: '30%',
              }}
              onClick={() => {
                setShowMap((prev: boolean) => !prev)
              }}
            >
              {showMap ? 'Hide Map' : 'Show Map'}
              &nbsp;
              <FontAwesomeIcon icon={faMapLocationDot} />
            </div>
            {showMap && (
              <div
                style={
                  isMobile
                    ? { height: '250px', width: '100%' }
                    : { height: '450px', width: '100%' }
                }
              >
                {GoogleMapSection}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  )
}
